import axios from "axios";
import { formatMentions } from "../helpers/message_helpers";

export const CommandBarNotificationApi = (
  activeUserDisplayName,
  channelDisplayName,
  thryvStaffId,
  messageInputValue
) => {
  const authPayload = {
    activeUserDisplayName: activeUserDisplayName,
    channelDisplayName: channelDisplayName,
    thryv_staff_id: thryvStaffId,
    messageInputValue: formatMentions(messageInputValue),
    avtar_url: localStorage.getItem('ActiveUserAvtar'),
  };

  axios
    .post(`https://dev-command-bar-api.sandbox.thryv.com/teamChatNotification`, authPayload)
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};
