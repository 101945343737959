/* global pendo */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Main from '..//Main';
import { connectClient, updateAmityUser } from '../../ascClient';
import Loader from 'react-loader-spinner';
import '../Home/index.css';
import { setStorageItem, isLocalStorageAvailable } from '../../helpers/environment_helpers';
import ThryvCookies from '../../components/ThryvCookies.png';
import { useDispatch } from 'react-redux';
import { updateUserRoleAmity } from '../../helpers/db_amity_sync_helpers';
import { Container } from '../../index.style';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import initializeTealium from '../../helpers/initialize-tealium';
import { httpGet } from '../../helpers/http_helpers';
import axios from 'axios';
import { API_URL } from '../../app.config';

export function App(props) {
  const { user } = useAuth0();
  const history = useHistory();
  const { thryv_id, thryv_staff_id, access_token, v_user } = useSelector(
    (state) => state.ActiveUser
  );
  const { role: OAuthRole, display_name } = v_user;

  initializeTealium(v_user, user);

  if (!(thryv_id && thryv_staff_id && access_token)) {
    history.push('/');
  }

  const [connected, setConnected] = useState(false);
  const localStorageAvailable = isLocalStorageAvailable();
  const dispatch = useDispatch();

  setStorageItem('Thryv_Id', thryv_id);
  setStorageItem('business_id', thryv_id);
  setStorageItem('Thryv_Staff_Id', thryv_staff_id);
  if (localStorage.getItem('OAuthRole') !== 'owner') setStorageItem('OAuthRole', OAuthRole);
  setStorageItem('access_token', access_token);
  setStorageItem('ActiveUserId', thryv_staff_id);

  const onConnect = async (thryv_staff_id, persistent) => {
    
    if (OAuthRole === 'owner') await updateUserRoleAmity(thryv_staff_id);
    
    await httpGet(`getuser/${thryv_staff_id}`)
    .then(res => {
        const browserNotifications = res?.data?.getUser?.desktop_notifications;
        setStorageItem('desktop_notifications', browserNotifications);
    })

    await connectClient(thryv_staff_id, display_name, dispatch);
    
    const newUpdatedUser = {
      thryv_id: v_user.business_uid,
      thryv_staff_id: v_user.id,
      role: v_user.role,
      notifications: 0,
      guest: 0,
      email: v_user.email,
      phone: v_user.mobile_number,
      timezone: null,
      status: 1,
      avatar_url: v_user.photo,
      display_name: v_user.display_name,
    };

    axios.post(`${API_URL}api/teamchat/newuser`, newUpdatedUser).then((res) => {
      updateAmityUser(newUpdatedUser);
    }).catch((err) => {
      console.log(err);
    });

    setConnected(true);
  };

  useEffect(() => {
    onConnect(thryv_staff_id);
  }, []);

  return (
    <div className='app'>
      {/* {isAuthenticated && (
        <button onClick={() => logout({ returnTo: window.location.origin })}>Logout</button>
      )} */}
      {!localStorageAvailable && (
        <Container>
          <img src={ThryvCookies} alt='thryv-cookies'></img>
        </Container>
      )}
      {!connected && thryv_staff_id && localStorageAvailable && (
        <Loader className='loading-dots' type='ThreeDots' color='#FF5000' height={80} width={80} />
      )}
      {connected && thryv_staff_id && localStorageAvailable && (
        <Main someProps={{ props }} currentUserInfo={v_user} />
      )}
    </div>
  );
}

export default App;
