import { HIDE_TOAST, SHOW_TOAST } from "./types";

export const showToast = (toastProps) => ({
  type: SHOW_TOAST,
  payload: { ...toastProps }
});

export const hideToast = () => ({
  type: HIDE_TOAST,
  payload: {}
});
