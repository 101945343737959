import { useDispatch } from "react-redux";
import { showToast, hideToast } from "../../redux/actions/Toasts";

export default function useToast() {
  const dispatch = useDispatch();

  const triggerToast = (toastMessage, toastType) => {
    const toastProps = { toastMessage, toastType };
    dispatch(showToast(toastProps));
    setTimeout(() => {
      dispatch(hideToast)
    }, 12500)
  };

  return { triggerToast };
}
