import { Button } from "@thryvlabs/maverick";
import { Col } from "react-bootstrap";

export const ActivationFinalScreen = ({ RedirectToApp }) => {
  return (
    <>
      <Col xs={12}>
        <div className="activate-container-wrapper">
          <div className="activate-teamchat">
            <h2 className="activate-heading">Welcome to TeamChat!</h2>
            <div
              className="activate-container-subheading"
              style={{ textAlign: "justify" }}
            >
              <p>Your TeamChat account is activated!</p>
              Enjoy collaborative conversations with your team for free!
            </div>
            <Button
              className="start-chatting-flow-button"
              variant="primary"
              onClick={RedirectToApp}
            >
              Start Chatting
            </Button>
          </div>
        </div>
      </Col>
    </>
  );
};
