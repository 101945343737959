import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, ParagraphText } from '@thryvlabs/maverick';

const LoginPopup = ({ error }) => {
  const { loginWithPopup } = useAuth0();

  return (
    <div
      className='flex flex-column justify-start items-center gap-2 pt-4'
      style={{ backgroundColor: '#F8F9FB', height: 'calc(100vh - 66.18px)' }}
    >
      <ParagraphText variant='reg' color='utility-error-danger'>
        {error?.message ? `There was an error: ${error.message}. Please log in to access the application.` : 'Please sign in to access the application.'}
      </ParagraphText>
      <Button variant='primary' type='button' onClick={() => loginWithPopup()}>
        Login
      </Button>
    </div>
  );
};

export default LoginPopup;
