import { Section, Badge, TableFormAlert, ParagraphText as Text, Button } from "@thryvlabs/maverick";
import humanize from "tiny-human-time";
import '../index.css'
import { useMediaQuery } from "react-responsive";

export default function SubscriptionSettings({ subscriptionStatus }) {
  const MobileView = useMediaQuery({ query: "(max-width: 700px)" });
  const deactivateButton = false;
  let mobileViewStyle = MobileView ? "text-align: center" : "d-inline-flex w-100 justify-content-between";

  const {
    status,
    trialEndDate,
    trialCallToAction,
    subscriptionError,
    loadingSubscriptionStatus,
    changeSubscriptionStatus
  } = subscriptionStatus;

  const badgeStyle = {
    marginTop: "2px",
    paddingLeft: "10px"
  };

  const buttonText = getButtonText(status, trialCallToAction);
  const subscriptionStatusText = getSubscriptionText(status, trialCallToAction, trialEndDate);

  return (
    <div className="row my-3">
      <div className="col">
        {subscriptionError && <TableFormAlert variant="danger" alertText={subscriptionError} />}
        <Section
          subSize="md"
          title={
            <div className="flex">
              {status === "trial_expired" || status === "trial_active" ? "Trial" : "Subscription"}
              <div style={badgeStyle}>
                {status === "subscribed" || status === "trial_active" ? (
                  <Badge variant="positive" badgeText="Active" />
                ) : (
                  <Badge variant="positive" badgeText="Active" />
                )}
              </div>
            </div>
          }
          titleVariant={"primary"}
        >
          <div className={mobileViewStyle}>
            <Text className="text-wrap" variant="reg">Your Teamchat subscription is active.</Text>
            {deactivateButton && (
              <Button
                className={MobileView ? "subButtonStyle" : ""}
                disabled={loadingSubscriptionStatus}
                variant="secondary"
                onClick={changeSubscriptionStatus}
              >
                {buttonText}
              </Button>
            )}
          </div>
        </Section>
      </div>
    </div>
  );
}

const getButtonText = (status, trialCallToAction) => {
  if (status === "subscribed" || status === "trial_active") return "Deactivate";
  if (status === "trial_expired") {
    if (trialCallToAction === "buy" || trialCallToAction === "purchase") {
      return "Buy Now";
    } else {
      return "Extend";
    }
  } else {
    return "Activate";
  }
};

const getSubscriptionText = (status, trialCallToAction, trialEndDate) => {
  switch (status) {
    case "subscribed":
      return "Your Teamchat subscription is active.";
    case "inactive":
      return "Your subscription is inactive. Activate to use Teamchat.";
    case "trial_active":
      return trialEndDate
        ? `Your free trial ends in ${humanize(Date.now(), new Date(trialEndDate))}.`
        : "Your free trial is ongoing.";
    case "trial_expired":
      return `Your free trial has expired. ${
        trialCallToAction === "buy" || trialCallToAction === "purchase"
          ? "Purchase to continue using Teamchat."
          : "Click here to extend it."
      }`;
    default:
      return;
  }
};
