import axios from "axios";
import { API_URL } from "../../app.config";

/**
 * Update the currentUser status
 * @param {*} activeUserId
 * @param {*} newStatusObj
 * @param {*} selectedOption
 */
export function updateUserStatus(
  businessId,
  activeUserId,
  statusIcon,
  statusValue,
  selectedOption
) {
  const statusPayload = {
    thryv_id: businessId,
    thryv_staff_id: activeUserId,
    status_icon: statusIcon,
    status_value: statusValue,
    selected_option: selectedOption,
  };

  return axios
    .post(`${API_URL}api/teamchat/newstatus`, statusPayload)
    .then(() => {})
    .catch((err) => console.log("Error from setting new the status", err));
}
