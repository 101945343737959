import {
  UPDATE_CHANNEL_UNREAD_MAP,
  UPDATE_CHAT_UNREAD_MAP
} from "./types";

export const updateChannelUnreadMap = (data) => ({
  type: UPDATE_CHANNEL_UNREAD_MAP,
  payload: data
});

export const updateChatUnreadMap = (data) => ({
  type: UPDATE_CHAT_UNREAD_MAP,
  payload: data
});
