import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function AuthRedirectHandler() {
  const { isAuthenticated, isLoading, user, handleRedirectCallback } = useAuth0();

  return (
    <>
      <p>Validating authorization token...</p>
    </>
  );
}
