export default function useIsInFrame() {

  const inFrame = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };

  const isInFrame = inFrame();

  return { isInFrame };
}
