export const sortByRecent = (newMessages) => {
    if (newMessages.legth <= 0) {
        return;
    }
    const ordered = newMessages.sort((a, b) => b.createdAt - a.createdAt);
    return ordered[0];
};

export const formatMentions = (message) => {
    try {
        const mentionRegex = /(\@\[[^)]+\]\([^)]+\:\:[^)]+\:\:[^)]+\))/gm
        const displayNameRegex = /\@\[([^)]+)\]/gm
        return message.replace(mentionRegex, (mention) => {
            const displayNameString = mention.match(displayNameRegex)[0]

            return `@${displayNameString.substring(2, displayNameString.length - 1)}`
        })
    } catch (e) {
        console.log('Exception at reformatMentionedMessage()', e)
    }
}

export const getMessageMentions = (message) => {
    if( message.mentionees && message.mentionees.length > 0) {
        const { users } = message.mentionees[0];
        return users.map(user => user.userId);
    }

    return [];
}