import React, {useEffect, useState} from "react";
import './index.css';
import {Navigation, Icon, Avatar, CardTitle, ParagraphText, Select} from "@thryvlabs/maverick";
import {AddButton, getLinkContent, REFERENCE_ELEMENT_TYPE, REFERENCE_ELEMENTS} from "../index";
import axios from "axios";
import {API_URL} from "../../../app.config";
import {dateTzFormatter, getAvatarColorByName} from "../../utils/utils";
import {AppointmentIcon, DocumentIcon, EstimateIcon, InvoiceIcon, MailIcon} from "../../Icons";
import { useSelector } from 'react-redux';

export const ClientProfile = ({ setActiveComponent, selectedClient, closeModal }) => {
    const {id: client_id, business_uid, first_name, last_name, email } = selectedClient;

    // todo: utilize this state to render image after the image_url is available in client information obj
    const [selectedClientExtraDetails, setSelectedClientExtraDetails] = useState({})

    const {thryv_id, access_token, directory_code} = useSelector((state) => state.ActiveUser);

    const redirectedComponent = !setActiveComponent
    const [activeClientTab, setActiveClientTab] = useState(0)
    const [documentList, setDocumentList] = useState([]);
    const [paymentList, setPaymentList] = useState([]);
    const [bookingList, setBookingList] = useState([]);
    const [invoiceList, setInvoiceList] = useState([]);
    const [estimateList, setEstimateList] = useState([]);
    // const [conversationList, setConversationList] = useState([]);

    /** Generic fetch function **/
    const defaultResolveCallback = (res, candidate, setCandidate) => {
        setCandidate(res?.data?.data ? res.data.data[candidate] ?? [] : [])
    }
    const fetchDataList = ({
                               requestPayload,
                               candidate,
                               dataCandidate = candidate,
                               setCandidate,
                               resolveCallback = res => defaultResolveCallback(res, dataCandidate, setCandidate)
    }) => {
        requestPayload.thryv_id = business_uid
        axios.post(`${API_URL}api/teamchat/${candidate}`, requestPayload).then(resolveCallback)
    }

    useEffect( () => {
        console.log("Client profile component")
        const requestPayload = {
            client_id,
            thryv_id,
            access_token
        }

        if (directory_code) requestPayload['dirCode'] = directory_code

        fetchDataList({
            requestPayload,
            candidate: 'payments',
            setCandidate: setPaymentList,
            resolveCallback: res => {
                let resPaymentList = res?.data?.data?.payments ?? [];
                setPaymentList(resPaymentList.map(v => { v.selected = false; return v }));
            }
        })
        fetchDataList({requestPayload, candidate: 'documents', setCandidate: setDocumentList})
        fetchDataList({requestPayload, candidate: 'bookings', dataCandidate: 'appointments', setCandidate: setBookingList})
        fetchDataList({requestPayload, candidate: 'invoices', setCandidate: setInvoiceList})
        fetchDataList({requestPayload, candidate: 'estimates', setCandidate: setEstimateList})
        // fetchDataList({requestPayload, candidate: 'conversations', setCandidate: setConversationList})

        if (redirectedComponent) {
            console.log("Redirected component")
            axios.post(`${API_URL}api/teamchat/singleclient`, requestPayload).then(res => {
                const client = res?.data?.client;
                setSelectedClientExtraDetails(client)
            })
        }
    }, [])

    const handleBackClick = () => setActiveComponent(REFERENCE_ELEMENTS.CLIENT_LIST)

    const handleTabChange = activeTab => setActiveClientTab(activeTab)

    const handleElementClick = (v, type) => window.open(getLinkContent(v, type), '_blank')

    const buildProps = ([list, setList]) => {
        return {
            list,
            setList,
            redirectedComponent,
            closeModal,
            handleElementClick,
            clientDetails: {client_id, business_uid, first_name, last_name, email}
        }
    }

    const clientGeneralInfoComponentProps = { redirectedComponent, handleBackClick, client_id, first_name, last_name, email }

    return (
        <>
            { !redirectedComponent && <ClientGeneralInfoComponent {...clientGeneralInfoComponentProps} /> }

            <div className={`reference-panel-container ${redirectedComponent ? 'w-100' : 'pt-0'}`}>
                { redirectedComponent && <ClientGeneralInfoComponent {...clientGeneralInfoComponentProps} /> }

                <div className={'ref-nav d-flex justify-content-center'}>
                    <Navigation variant={'tabs'} linksArray={[
                    //     {
                    //     name: <div className={'nav-icon'}><Icon type="regular" variant="altComments"/></div>,
                    //     pillVariant: 'secondary',
                    //     alerts: 0
                    // },
                     {
                        name: <div className={'nav-icon'}><Icon type="regular" variant="file"/></div>,
                        pillVariant: 'secondary',
                        alerts: 0
                    },{
                        name: <div className={'nav-icon'}><Icon type="regular" variant="calendar"/></div>,
                        pillVariant: 'secondary',
                        alerts: 0
                    },{
                        name: <div className={'nav-icon'}><Icon type="regular" variant="fileSignature"/></div>,
                        pillVariant: 'secondary',
                        alerts: 0
                    },{
                        name: <div className={'nav-icon'}><Icon type="regular" variant="fileInvoiceDollar"/></div>,
                        pillVariant: 'secondary',
                        alerts: 0
                    }]} setSelectedOption={handleTabChange} selected={activeClientTab} collapsible={false}/>
                </div>
                {
                    [
                        // <Conversations {...buildProps([conversationList, setConversationList])} />,
                        <Documents {...buildProps([documentList, setDocumentList])} />,
                        <Bookings {...buildProps([bookingList, setBookingList])} />,
                        <Estimates {...buildProps([estimateList, setEstimateList])} />,
                        <Invoices {...buildProps([invoiceList, setInvoiceList])} />
                    ][activeClientTab]
                }
            </div>
        </>
    )
}

const ClientGeneralInfoComponent = ({redirectedComponent, handleBackClick, client_id, first_name, last_name, email}) => {
    const handleViewClientClick = () => window.open(getLinkContent(client_id, REFERENCE_ELEMENT_TYPE.CLIENT), '_blank')

    return (
        !redirectedComponent ? (
            <div className={'RefPanelTitleBar d-flex justify-content-between'}>
                <div className="mx-3 my-auto innerZoomElementWrapper" onClick={handleBackClick}>
                    <button onClick={handleBackClick}>
                        <Icon type="solid" variant="chevronLeft" height={13.95} color={'white'}/>
                    </button>
                </div>
                <div className={'d-flex justify-content-center m-auto'}>
                    <div className={'ref-panel-title-bar-avatar'}>
                        <Avatar variant="name" size="small" style={{backgroundColor: getAvatarColorByName(first_name)}} name={{firstName: first_name, lastName: last_name}}/>
                    </div>
                    <span className="m-auto title-text">{first_name ?? ''} {last_name ?? ''}</span>
                </div>
            </div>
            ) :
            <>
                <div className="row justify-content-end">
                    <div className="text-center col-2">
                        <Icon variant={'eye'} type={'regular'} color={'#808080'} height={12} width={18}
                              className={'cursor-pointer'}
                              onClick={handleViewClientClick}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className={'text-center mb-2'}>
                            <div className={'d-flex flex-column justify-content-center mt-3'}>
                                <div><Avatar variant="name" size="large" style={{backgroundColor: getAvatarColorByName(first_name)}} name={{firstName: first_name, lastName: last_name}}/></div>
                                <CardTitle size="md" variant="subtitle" className={'mt-2'}>{first_name ?? ''} {last_name ?? ''}</CardTitle>
                                <div className={'client-email'}>{email && email !== 'null' ? email : "No email available"}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
}

const Invoices = ({list, setList, redirectedComponent, closeModal, clientDetails, handleElementClick}) => {

    const InvoiceTypeEnum = {
        ALL: 'All Invoices',
    }
    const invoiceTypeOptions = Object.entries(InvoiceTypeEnum).map( v => ({name: v[1], value:v[1]}))
    const [selectedInvoiceType, setSelectedInvoiceType] = useState({ name: InvoiceTypeEnum.ALL, value: InvoiceTypeEnum.ALL})
    const [filteredInvoiceList, setFilteredInvoiceList] = useState([])

    useEffect(() => {
        setFilteredInvoiceList(list.filter(v => true))
    }, [selectedInvoiceType, list])

    return (
        <div className={'d-flex flex-column flex-grow-1 h-100'}>
            <div className={'d-flex flex-wrap justify-content-between p-3 mt-2'}>
                <CardTitle variant="primary" className={'ref-title'}>Invoices ({list.length})</CardTitle>
                <div>
                    <Select options={invoiceTypeOptions} width="md" selectLabel="Select Invoice option" selectedOption={selectedInvoiceType} setSelectedOption={v => setSelectedInvoiceType(v)} />
                </div>
            </div>
            <div className="overflow-auto flex-grow-1">
                {filteredInvoiceList.length > 0 ? filteredInvoiceList.map( v => {
                    return (
                        <div className={`d-flex justify-content-between p-3 border-bottom`} key={v?.id}>
                            <div className={'d-flex justify-content-between'} onClick={() => handleElementClick(v, REFERENCE_ELEMENT_TYPE.INVOICE)}>
                                <div className={`document-item`}>
                                    <div className="pr-2 avatar"><InvoiceIcon/></div>
                                    <CardTitle size="md" variant="subtitle" className={'display-name'}>{v?.title ?? 'Untitled'}</CardTitle>
                                    <div className="client-email status">Sent on {dateTzFormatter(v?.created_at)?.toDateString()}</div>
                                </div>
                            </div>
                            <div className={'text-center'}>
                                <CardTitle size="md" variant="subtitle" className={'display-name'}>${v?.amount?.toLocaleString()}</CardTitle>
                                <CardTitle size="md" variant="subtitle" className={`display-name font-bold ${(v?.payment_state === 'paid') ? 'text-success' : 'text-warning'}`}>{v?.payment_state?.toUpperCase()}</CardTitle>
                            </div>
                            { !redirectedComponent ?
                                <div className={'vertical-center'}>
                                    <AddButton selectedValue={v} closeModal={closeModal} type={REFERENCE_ELEMENT_TYPE.INVOICE} clientDetails={clientDetails}/>
                                </div> : null }
                        </div>
                    )
                }) : (
                    <div className="vertical-center h-100">
                        No Invoices.
                    </div>
                )}
            </div>
        </div>
    )
}

const Conversations = ({list, setList, redirectedComponent, closeModal, clientDetails, handleElementClick}) => {
    const conversationTypeEnum = {
        ALL: 'All Conversations',
    }
    const conversationTypeOptions = Object.entries(conversationTypeEnum).map( v => ({name: v[1], value:v[1]}))
    const [selectedConversationType, setSelectedConversationType] = useState({ name: conversationTypeEnum.ALL, value: conversationTypeEnum.ALL})
    const [filteredConversationList, setFilteredConversationList] = useState([])

    useEffect(() => {
        // todo: add more conversation types like read and unread--
        setFilteredConversationList(list.filter(v => true))
    }, [selectedConversationType, list])

    return (
        <div className={'flex-column flex-grow-1 h-100'}>
            <div className={'d-flex flex-wrap justify-content-between p-3 mt-2'}>
                <CardTitle variant="primary" className={'ref-title'}>Conversations ({filteredConversationList.length})</CardTitle>
                <div>
                    <Select options={conversationTypeOptions} width="md" selectLabel="Select Booking option" selectedOption={selectedConversationType} setSelectedOption={v => setSelectedConversationType(v)} />
                </div>
            </div>
            <div className="overflow-auto flex-grow-1 h-100">
                {filteredConversationList.length > 0 ? filteredConversationList.map( v => {
                    return (
                        <div className={`d-flex justify-content-between p-3 border-bottom`} key={v?.id}>
                            <div className={'d-flex justify-content-between'} onClick={() => handleElementClick(v, REFERENCE_ELEMENT_TYPE.CONVERSATION)}>
                                <div className={`document-item`}>
                                    <div className="pr-2 avatar"><MailIcon/></div>
                                    <CardTitle size="md" variant="subtitle" className={'display-name'}>{v?.title ?? 'Untitled'}</CardTitle>
                                    <div className="client-email status">{`Sent on  ${dateTzFormatter(v?.created_at).toDateString()}`}</div>
                                </div>
                            </div>
                            { !redirectedComponent ?
                                <div className={'vertical-center'}>
                                    <AddButton selectedValue={v} closeModal={closeModal} type={REFERENCE_ELEMENT_TYPE.CONVERSATION} clientDetails={clientDetails}/>
                                </div> : null }
                        </div>
                    )
                }) : (
                    <div className="vertical-center h-100">
                        No Conversations.
                    </div>
                )}
            </div>
        </div>
    )
}

const Estimates = ({list, setList, redirectedComponent, closeModal, clientDetails, handleElementClick}) => {
    const estimateTypeEnum = {
        ALL: 'All Estimates',
    }
    const estimateTypeOptions = Object.entries(estimateTypeEnum).map( v => ({name: v[1], value:v[1]}))
    const [selectedEstimateType, setSelectedEstimateType] = useState({ name: estimateTypeEnum.ALL, value: estimateTypeEnum.ALL})
    const [filteredEstimateList, setFilteredEstimateList] = useState([])

    useEffect(() => {
        // todo: add more estimate types like read and unread--
        setFilteredEstimateList(list.filter(v => true))
    }, [selectedEstimateType, list])

    return (
        <div className={'d-flex flex-column flex-grow-1 h-100'}>
            <div className={'d-flex flex-wrap justify-content-between p-3 mt-2'}>
                <CardTitle variant="primary" className={'ref-title'}>Estimates ({filteredEstimateList.length})</CardTitle>
                <div>
                    <Select options={estimateTypeOptions} width="md" selectLabel="Select Booking option" selectedOption={selectedEstimateType} setSelectedOption={v => setSelectedEstimateType(v)} />
                </div>
            </div>
            <div className="overflow-auto flex-grow-1">
                {filteredEstimateList.length > 0 ? filteredEstimateList.map( v => {
                    const isScheduled = new Date().getTime() < new Date(v?.estimate_date)?.getTime();
                    return (
                        <div className={`d-flex justify-content-between p-3 border-bottom`} key={v?.id}>
                            <div className={'d-flex justify-content-between'} onClick={() => handleElementClick(v, REFERENCE_ELEMENT_TYPE.ESTIMATE)}>
                                <div className={`document-item`}>
                                    <div className="pr-2 avatar"><EstimateIcon/></div>
                                    <CardTitle size="md" variant="subtitle" className={'display-name'}>{v?.title ?? 'Untitled'}</CardTitle>
                                    <div className="client-email status">Sent on {dateTzFormatter(v?.created_at)?.toDateString()}</div>
                                </div>
                            </div>
                            <div className={'text-center'}>
                                <ParagraphText variant="sm">{dateTzFormatter(v?.estimate_date)?.toDateString()}</ParagraphText>
                                <div className={'d-inline-flex'}>
                                    <div className={'vertical-center'}>
                                        <Icon type="regular" variant={isScheduled ? "calendar" : "circleCheck"} color={isScheduled ? "#16A085" : "#666666"} height={'14'} width={'14'}/>
                                    </div>
                                    <span className="px-2">
                                            <ParagraphText variant="sm"
                                                           color={isScheduled ?
                                                               'utility-success-correct' : 'thryv-neutral'}
                                                           style={{marginBottom: 0}}>
                                                {isScheduled ? 'SCHEDULED' : 'COMPLETED'}
                                            </ParagraphText>
                                        </span>
                                </div>
                            </div>
                            { !redirectedComponent ?
                                <div className={'vertical-center'}>
                                    <AddButton selectedValue={v} closeModal={closeModal} type={REFERENCE_ELEMENT_TYPE.ESTIMATE} clientDetails={clientDetails}/>
                                </div> : null }
                        </div>
                    )
                }) : (
                    <div className="vertical-center h-100">
                        No Estimates.
                    </div>
                )}
            </div>
        </div>
    )
}

const Documents = ({list, setList, redirectedComponent, closeModal, clientDetails, handleElementClick}) => {
    const documentTypeEnum = {
        ALL: 'All Documents',
    }
    const documentTypeOptions = Object.entries(documentTypeEnum).map( v => ({name: v[1], value:v[1]}))
    const [selectedDocumentType, setSelectedDocumentType] = useState({ name: documentTypeEnum.ALL, value: documentTypeEnum.ALL})
    const [filteredDocumentList, setFilteredDocumentList] = useState([])

    useEffect(() => {
        setFilteredDocumentList(list.filter(v => true))
    }, [selectedDocumentType, list])

    return (
        <div className={'d-flex flex-column flex-grow-1 h-100'}>
            <div className={'d-flex flex-wrap justify-content-between p-3 mt-2'}>
                <CardTitle variant="primary" className={'ref-title'}>Documents ({list.length})</CardTitle>
                <div>
                    <Select options={documentTypeOptions} width="md" selectLabel="Select Document option" selectedOption={selectedDocumentType} setSelectedOption={v => setSelectedDocumentType(v)} />
                </div>
            </div>
            <div className={'overflow-auto flex-grow-1'}>
                {filteredDocumentList.length > 0 ? filteredDocumentList.map( v => {
                    return (
                        <div className={`d-flex justify-content-between p-3 border-bottom`} key={v?.id}>
                            <div className={'d-flex justify-content-between'} onClick={() => handleElementClick(v, REFERENCE_ELEMENT_TYPE.DOCUMENT)}>
                                <div className={`document-item`}>
                                    <div className="pr-2 avatar"><DocumentIcon/></div>
                                    <CardTitle size="md" variant="subtitle" className={'display-name'}>{v?.title}</CardTitle>
                                    <div className="client-email status">{v?.source_data?.name ? `Created by ${v?.source_data?.name}` : 'Creator unknown'}</div>
                                </div>
                            </div>
                            <div className={'vertical-center'}>
                                <CardTitle size="md" variant="subtitle" className={'display-name'}>{dateTzFormatter(v?.created_at)?.toDateString()}</CardTitle>
                            </div>
                            { !redirectedComponent ?
                                <div className={'vertical-center'}>
                                    <AddButton selectedValue={v} closeModal={closeModal} type={REFERENCE_ELEMENT_TYPE.DOCUMENT} clientDetails={clientDetails}/>
                                </div> : null }
                        </div>
                    )
                }) : (
                    <div className="vertical-center h-100">
                        No Documents.
                    </div>
                )}
            </div>
        </div>
    )
}

const Bookings = ({list, setList, redirectedComponent, closeModal, clientDetails, handleElementClick}) => {
    const bookingTypeEnum = {
        ALL: 'All Bookings',
        UPCOMING: 'Upcoming',
        PAST: 'Past'
    }
    const bookingTypeOptions = Object.entries(bookingTypeEnum).map( v => ({name: v[1], value:v[1]}))
    const [selectedBookingType, setSelectedBookingType] = useState({ name: bookingTypeEnum.ALL, value: bookingTypeEnum.ALL})
    const [filteredBookingList, setFilteredBookingList] = useState([])

    useEffect(() => {
        setFilteredBookingList(list.filter(v => {
            if (selectedBookingType.value === bookingTypeEnum.UPCOMING)
                return new Date().getTime() < new Date(v?.start_time)?.getTime()
            else if (selectedBookingType.value === bookingTypeEnum.PAST)
                return new Date().getTime() >= new Date(v?.start_time)?.getTime()
            else return true
        }))
    }, [selectedBookingType])

    return (
        <div className={'d-flex flex-column flex-grow-1 h-100'}>
            <div className={'d-flex flex-wrap justify-content-between p-3 mt-2'}>
                <CardTitle variant="primary" className={'ref-title'}>Bookings ({list.length})</CardTitle>
                <div>
                    <Select options={bookingTypeOptions} width="md" selectLabel="Select Booking option" selectedOption={selectedBookingType} setSelectedOption={v => setSelectedBookingType(v)} />
                </div>
            </div>
            <div className="overflow-auto flex-grow-1">
                {filteredBookingList.length > 0 ? filteredBookingList.map( v => {
                    const isScheduled = new Date().getTime() < new Date(v?.start_time)?.getTime();
                    return (
                        <div className={`d-flex justify-content-between p-3 border-bottom`} key={v?.id}>
                            <div className={'d-flex justify-content-between'} onClick={() => handleElementClick(v, REFERENCE_ELEMENT_TYPE.BOOKING)}>
                                <div className={`document-item`}>
                                    <div className="pr-2 avatar"><AppointmentIcon/></div>
                                    <CardTitle size="md" variant="subtitle" className={'display-name'}>{v?.title}</CardTitle>
                                    <div className="client-email status">{v?.staff_display_name ? `With ${v?.staff_display_name}` : 'With unknown'}</div>
                                </div>
                            </div>
                            <div className={'text-center'}>
                                <ParagraphText variant="sm">{dateTzFormatter(v?.start_time)?.toDateString()}</ParagraphText>
                                <div className={'d-inline-flex'}>
                                    <div className={'vertical-center'}>
                                        <Icon type="regular" variant={isScheduled ? "calendar" : "circleCheck"} color={isScheduled ? "#16A085" : "#666666"} height={'14'} width={'14'}/>
                                    </div>
                                    <span className="px-2">
                                            <ParagraphText variant="sm"
                                                           color={isScheduled ?
                                                               'utility-success-correct' : 'thryv-neutral'}
                                                           style={{marginBottom: 0}}>
                                                {isScheduled ? 'SCHEDULED' : 'COMPLETED'}
                                            </ParagraphText>
                                        </span>
                                </div>
                            </div>
                            { !redirectedComponent ?
                                <div className={'vertical-center'}>
                                    <AddButton selectedValue={v} closeModal={closeModal} type={REFERENCE_ELEMENT_TYPE.BOOKING}  clientDetails={clientDetails}/>
                                </div> : null }
                        </div>
                    )
                }) : (
                    <div className="vertical-center h-100">
                        No Bookings.
                    </div>
                )}
            </div>
        </div>
    )
}