import {
  STARTFETCHING,
  FETCHEDOAUTH,
  FAILDFETCH,
} from "../actions/types";

const initState = {
  oauth: [],
  isFetching: false, 
  error: "",
}

export const OAuth = (state = initState, { type, payload, error }) =>  {   
  switch (type) {
    case STARTFETCHING:
      return {
        ...state, 
        isFetching: true
      }
    case FETCHEDOAUTH:
      return {
        ...state,
        isFetching: false,
        oauth: payload
      }
    case FAILDFETCH: 
      return {
        ...state,
        isFetching: false, 
        error: error
      }
    default:
      return state
  }
} 