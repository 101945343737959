import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";

function SearchTabsMobile({ handleTabChange, options }) {
  const [buttonName, setButtonName] = useState(() => options[0].name)

  const dropdownStyle = {
    backgroundColor: "transparent",
    border: "none",
    color: "black",
    boxShadow: "none",
    fontWeight: '500',
    fontFamily: 'sans-serif'
  }

  const onHandleChangeMobile = (index, name) => {
    handleTabChange(index);
    setButtonName(name);
  }

  return (
    <div className="" >
      <Dropdown>
        <Dropdown.Toggle variant="success" className="float-end" id="dropdown-basic" style={dropdownStyle} >
          {buttonName}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {options.map((option, index) => {
            // not showing the option user's watching
            if (option.name !== buttonName) {
              return <Dropdown.Item key={index} onClick={() => onHandleChangeMobile(index, option.name)}>{option.name}</Dropdown.Item>
            }
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default SearchTabsMobile;