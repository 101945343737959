/* global pendo */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AuthenticatedApp from './components/Authentication/AuthenticatedApp';
import UnauthenticatedApp from './components/Authentication/UnauthenticatedApp';
import AuthLoader from './components/Authentication/AuthLoader';
import { useAuth0 } from '@auth0/auth0-react';
import { setInCommandCenter, setOAuthCredentials } from './redux/actions/ActiveUser';
import LoginPopup from './components/Authentication/LoginPopup';
import { setStorageItem } from './helpers/environment_helpers';
import useInCommandCenter from './hooks/Environment/useInCommandCenter';

const App = () => {
  const url = window.location.href;
  const history = useHistory();
  const dispatch = useDispatch();
  const { isAuthenticated, isLoading, error, user } = useAuth0();
  const { inCommandCenter } = useInCommandCenter();

  useEffect(() => {
    if (url.includes('env=commandcenter')) {
      dispatch(setInCommandCenter(true));
      setStorageItem('inCommandCenter', true);
    }
  }, []);

  useEffect(() => {
    let trackWindowEvent = window.performance.getEntriesByType('navigation')[0];
    if (trackWindowEvent && trackWindowEvent?.type === 'reload') {
      if (inCommandCenter) {
        window.location.replace(`${process.env.REACT_APP_TEAM_CHAT_HOST}?env=commandcenter`);
      } else {
        window.location.replace(`${process.env.REACT_APP_TEAM_CHAT_HOST}`);
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      const { businessId: thryv_id, staffUID: thryv_staff_id, dirCode: directory_code } = user;
      dispatch(setOAuthCredentials({ thryv_id, thryv_staff_id, access_token: 'authorized', directory_code }));
      history.push('/uservalidation');
    }
  }, [history, isAuthenticated]);

  if (isLoading) return <AuthLoader />;

  if (error && inCommandCenter) return <LoginPopup error={error} />;

  if (isAuthenticated) return <AuthenticatedApp />;

  return <UnauthenticatedApp />;
};

export default App;
