import { ChannelRepository, ChannelType } from '@amityco/js-sdk';
import axios from 'axios';
import { API_URL } from '../app.config';
import { getStorageItem } from './environment_helpers';

export const createAccountStaffChannel = async (thryvId, thryvStaffId, dirCode = null) => {
  const staffChannelId = `staff_${thryvId}`;

  // Get all account staffmembers to add to channel
  const access_token = getStorageItem('access_token');

  const staffMemberInfo = {
    access_token,
    thryv_id: thryvId
  };

  if (dirCode) staffMemberInfo['dirCode'] = dirCode;

  const staffMembers = await axios
    .post(`${API_URL}api/teamchat/staff`, staffMemberInfo)
    .then((res) => res.data)
    .catch((err) => console.log(err));

  const staffMemberIds = staffMembers?.data?.staff.map((member) => member?.id);

  // Create staff channel in Amity and add all staff
  try {
    ChannelRepository.createChannel({
      channelId: staffChannelId,
      displayName: `Staff`,
      type: ChannelType.Community,
      userIds: [thryvStaffId, ...staffMemberIds]
    });
  } catch (err) {
    console.log('Failed to create new staff channel - one may already exist');
    return;
  }

  // Add new staff channel to DB
  const newStaffChannel = {
    channel_id: staffChannelId,
    thryv_id: thryvId,
    thryv_staff_id: thryvStaffId,
    notifications: '',
    status: '',
    alias: 'Staff'
  };

  axios
    .post(`${API_URL}api/teamchat/newchannel`, newStaffChannel)
    .then((res) => console.log("Staff Channel Created"))
    .catch((err) => console.error(err));

  return staffChannelId;
};

// Directory code will be passed in accountData if it exists
export const createAccountAndAddStaffMembers = async (
  accountData,
) => {

  await axios
    .post(`${API_URL}api/teamchat/newaccount/subscribe`, accountData)
    .then(() => { console.log("Teamchat account created!") })
    .catch((err) => console.log(err));

  // Get all staffmembers
  const {thryv_id, thryv_access_token: access_token, dirCode: directory_code} = accountData;
  const staffmembers = await getAllStaffmembers({thryv_id, access_token, directory_code})

  // Add staffmembers
  await staffmembers.map((staff) => {
    const staffmemberData = {
      thryv_id: staff.business_uid,
      thryv_staff_id: staff.id,
      role: staff.role,
      notifications: 0,
      guest: 0,
      email: staff.email,
      phone: staff.mobile_number,
      timezone: null,
      status: 1,
      avatar_url: staff.photo,
      display_name: staff.display_name
    };

    axios
      .post(`${API_URL}api/teamchat/newuser`, staffmemberData)
      .then(() => {
        console.log('New user created: ', staffmemberData);
      })
      .catch((err) => console.log(err));
  });

};

export const createUser = async (staff) => {
  const userData = {
    thryv_id: staff.business_uid,
    thryv_staff_id: staff.id,
    role: staff.role,
    notifications: 0,
    guest: 0,
    email: staff.email,
    phone: staff.mobile_number,
    timezone: null,
    status: 1,
    avatar_url: staff.photo,
    display_name: staff.display_name
  };
  try {
    const response = await axios.post(
      `${API_URL}api/teamchat/newuser`,
      userData
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getStaffmember = async ({thryv_id, thryv_staff_id, access_token, directory_code}) => {
  try {
    const res = await axios.post(`${API_URL}api/teamchat/singlestaffmember`, {
      thryv_id,
      thryv_staff_id,
      access_token,
      directory_code
    });
    const staffmember = res?.data?.data;
    return staffmember;
  } catch (err) {
    console.log("Failed to fetch staffmember from V: ", err)
    return null;
  }
}

export const getAllStaffmembers = async ({access_token, thryv_id, directory_code}) => {
  try {
    const res = await axios.post(`${API_URL}api/teamchat/staff`, {
      access_token,
      thryv_id,
      dirCode: directory_code
    });
    return res?.data?.data?.staff;
  } catch (err) {
    return [];
  }
};