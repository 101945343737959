import { ParagraphText as Text } from '@thryvlabs/maverick';
import { useHistory } from 'react-router-dom';

export default function SearchPopoverChats({ chats, chatsLoading, chatsError, searchText, setChannelId }) {
  const history = useHistory();

  if (searchText === '') return <Text variant='sm'>Search field empty.</Text>

  if (chatsError) return <Text variant='sm'>Something went wrong.</Text>

  if (chatsLoading) return <Text variant='sm'>Searching for chats...</Text>

  const handleClick = (channelId) => {
    setChannelId(channelId)
    history.push('/home/main/messages');
  };

  return (
    <>
      {chats.length > 0 ? (
        chats.slice(0, 5).map((chat) => (
          <div key={chat.chatId} className='mb-1 search-popover-result' onClick={() => handleClick(chat.channelId)}>
            <p className='font-medium text-h9'>{chat.displayName}</p>
          </div>
        ))
      ) : (
        <Text variant='sm'>No chats match your search.</Text>
      )}
    </>
  );
}
