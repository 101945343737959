import {
  SET_MESSAGE_LIST_ELEMENT_REF,
  SET_PRIMARY_RXN_PICKER_STATUS
} from './types';

export const setMessageListElementRef = (messageListElementRef) => ({
  type: SET_MESSAGE_LIST_ELEMENT_REF,
  payload: messageListElementRef
});

export const setPrimaryRxnPickerStatus = (primaryRxnPickerStatus) => ({
  type: SET_PRIMARY_RXN_PICKER_STATUS,
  payload: primaryRxnPickerStatus
});
