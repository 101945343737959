import { 
  SET_INPUT_MESSAGE_VALUE,
  SET_PLAIN_INPUT_MESSAGE_VALUE,
  SET_BOTH_INPUT_MESSAGE_VALUE
} from './types';

export const setInputMessageValue  = (data) => ({
  type: SET_INPUT_MESSAGE_VALUE,
  payload: { ...data }
});

export const setPlainInputMessageValue  = (data) => ({
  type: SET_PLAIN_INPUT_MESSAGE_VALUE,
  payload: { ...data }
});

export const setBothInputMessageValue  = (data) => ({
  type: SET_BOTH_INPUT_MESSAGE_VALUE,
  payload: { ...data }
});