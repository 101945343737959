import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getStorageItem } from '../../helpers/environment_helpers';
import { httpPost } from '../../helpers/http_helpers';

export default function useMessageSearch() {
  const [messageSearchResults, setMessageSearchResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const userId = getStorageItem('ActiveUserId');
  const { amityAuthToken: authToken } = useSelector((s) => s.AmitySession);

  const search = async (query = {}, sort = {}, from = null, size = 10) => {
    setMessageSearchResults([]);
    setLoading(true);
    httpPost(`search`, {
      userId,
      query,
      authToken,
      sort,
      from,
      size
    })
      .then((res) => {
        setMessageSearchResults(
          res.data.map((object) => ({ channel: object.channel, user: object.user, ...object.message }))
        );
        setLoading(false);
        setError(null);
      })
      .catch((err) => {
        setError(err);
        console.error('Failed to get search results.', err?.message);
      });
  };

  return { search, loading, error, messageSearchResults };
}
