import React from "react";
import './index.css'

export const ProgressBar = ({ visible = false }) => {
    return visible &&
        (
            <div className='team-chat-progress-bar-wrapper'>
                {/*<div className="progress-bar"/>*/}
                <div className="progress-bar-value"/>
            </div>
        )
}