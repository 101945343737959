import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Pill, Avatar, Tooltip } from '@thryvlabs/maverick';
import { getAvatarColorByName, getFirstAndLastName } from '../utils/utils';
import { getStorageItem } from '../../helpers/environment_helpers';
import useChannelUnreadCount from '../../hooks/UnreadCount/useChannelUnreadCount';
import { setDisplayChannelBrowser } from '../../redux/actions/channelBrowser';
import './index.css';
import { useSocketStateContext } from '../../contexts/SocketContext';

export function ConversationItem({
  channelId,
  displayName,
  metadata: { coverImageUrl, convoMembersDetailedList },
  unreadCount,
  active,
  onClick = () => {}
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  let thryv_staff_id = getStorageItem('Thryv_Staff_Id');

  const socketState = useSocketStateContext();

  const { trueUnreadCount } = useChannelUnreadCount(channelId, unreadCount, 'chat');

  const handleConversationClick = () => {
    dispatch(setDisplayChannelBrowser(false));
    onClick(channelId);
    history.push('/home/main/messages');
  };

  // Generate channel name for one-on-one chats and multi-member chats
  let status,
    oneOnOneImageUrl,
    oneOnOneDisplayName,
    onlineUser,
    activeOnline = false;

  const isOneOnOne = convoMembersDetailedList?.length === 2;
  let multiMemberDisplayName;

  if (isOneOnOne) {
    const endMemberDetail = convoMembersDetailedList.find((member) => member?.thryv_staff_id !== thryv_staff_id);
    onlineUser = endMemberDetail?.thryv_staff_id;
    if (socketState.statuses?.data) {
      status = socketState.statuses?.data[onlineUser];
    }
    oneOnOneImageUrl = endMemberDetail?.avatar_url;
    oneOnOneDisplayName = endMemberDetail?.display_name;
    if (onlineUser) {
      activeOnline = socketState.onlineUsers.includes(onlineUser);
    }
  } else if (convoMembersDetailedList?.length > 2) {   
    const chatMembers = convoMembersDetailedList.map((member) => member?.display_name);
    const lastMember = chatMembers.pop();
    multiMemberDisplayName = lastMember ? `${chatMembers.join(', ')} and ${lastMember}` : displayName ?? channelId;
  }

  let displayNameToArray = oneOnOneDisplayName ? oneOnOneDisplayName?.split(', ') : displayName.split(', ');

  const isImageAvatar = (isOneOnOne && oneOnOneImageUrl) || (!isOneOnOne && coverImageUrl);
  const displayText = oneOnOneDisplayName ?? multiMemberDisplayName ?? displayName ?? channelId;

  return (
    <div className={`conversation-item ${active ? 'active' : ''}`} onClick={handleConversationClick}>
      <div className='conversation-item__avatar-and-count'>
        <div className='icon-container'>
          <Avatar
            className='conversation-item-avatar'
            size='default'
            variant={isImageAvatar ? 'image' : 'name'}
            style={
              isImageAvatar
                ? {}
                : { backgroundColor: getAvatarColorByName(displayNameToArray?.length > 0 ? displayNameToArray[0] : '') }
            }
            imageUrl={oneOnOneImageUrl ?? coverImageUrl}
            name={getFirstAndLastName(displayNameToArray?.length > 0 ? displayNameToArray[0] : '')}
          />
          {(convoMembersDetailedList?.length < 3 || !convoMembersDetailedList) && (
            <div
              className={
                activeOnline ||
                (convoMembersDetailedList?.every((item) => item?.thryv_staff_id === thryv_staff_id) &&
                  getStorageItem('myawaystatus') === 0)
                  ? 'logged-in'
                  : 'logged-out'
              }
            />
          )}
        </div>

        {displayNameToArray?.length > 1 && (
          <div className='icon-container'>
            <div className='multi-conversations' style={{ fontSize: '11px' }}>
              {' '}
              +{displayNameToArray.length - 1}
            </div>
          </div>
        )}
      </div>
      <div className='conversation-item-displayName-container'>
        <p className={`w-100 conversation-item-displayName ${active ? '' : 'text-secondary'}`} title={displayText}>
          {displayText}
          <span style={{ fontSize: '15px', marginLeft: '10px' }} />
        </p>
        {isOneOnOne && (
          <div className='d-flex justify-content-center'>
            <div className='customToolTipStyle'>
              <Tooltip
                variant='top'
                title={status?.value || getStorageItem('setStatusValueForSelf')}
                width='150px'
                flipBubbleOrientation
              >
                {(typeof status?.id === 'string' || (convoMembersDetailedList?.every(item => item?.thryv_staff_id === thryv_staff_id) && getStorageItem("setStatusIconForSelf")))}
              </Tooltip>
            </div>
          </div>
        )}
      </div>
      <span>
        <Pill variant='primary' number={trueUnreadCount} />
      </span>
    </div>
  );
}
