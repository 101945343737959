import { Header, Navigation, Icon, Pill } from '@thryvlabs/maverick';
import { useDispatch } from 'react-redux';
import useTotalUnreadCount from '../../hooks/UnreadCount/useTotalUnreadCount';
import { setDisplayChannelBrowser } from '../../redux/actions/channelBrowser';
import { useMediaQuery } from 'react-responsive';
import SearchTabsMobile from './SearchTabsMobile';
import { useFlags } from 'launchdarkly-react-client-sdk';

export default function SearchNavigation({ setActiveTab }) {
  const { totalUnreadCount } = useTotalUnreadCount();
  const dispatch = useDispatch();
  const MobileView = useMediaQuery({ query: '(max-width: 830px)' });
  const { searchFeature } = useFlags();

  let navigationTabs = [
    {
      name: 'All',
      pillVariant: 'secondary',
      alerts: 0
    },
    {
      name: 'Messages',
      pillVariant: 'secondary',
      alerts: 0
    },
    {
      name: 'Channels',
      pillVariant: 'secondary',
      alerts: 0
    },
    {
      name: 'Chats',
      pillVariant: 'secondary',
      alerts: 0
    },
    {
      name: 'Team Members',
      pillVariant: 'secondary',
      alerts: 0
    }
  ];

  if (!searchFeature) navigationTabs.splice(1,1);

  const handleChangeTab = (tabIndex) => {
    const tabName = navigationTabs[tabIndex] !== undefined ? navigationTabs[tabIndex].name : 'All';
    setActiveTab(tabName);
  };

  return (
    <div className='search-navigation'>
      <div className='users-icons-container' onClick={() => dispatch(setDisplayChannelBrowser(true))}>
        <Icon className='icon-users' type='solid' variant='users' />
        <Pill className='icon-pill' variant='primary' number={totalUnreadCount} />
      </div>

      <div className='search-header'>
        <Header fontWeight='semibold' variant='h6' className='mb-0'>
          Search
        </Header>
      </div>
      {!MobileView ? (
        <Navigation
          id='settings-nav'
          variant='tabs'
          linksArray={navigationTabs}
          setSelectedOption={handleChangeTab}
          selected={0}
        />
      ) : (
        <SearchTabsMobile handleTabChange={handleChangeTab} options={navigationTabs} />
      )}
    </div>
  );
}
