import { SET_STATUS_FOR_SIDE_PANEL } from "../actions/types";

const initState = {
  isOpen: false,
  ComponentToRender: null,
  props: {
    selectedClient: null
  }
};

export const ReferencePanelReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case SET_STATUS_FOR_SIDE_PANEL:
      return {
        isOpen: payload.isOpen,
        ComponentToRender: payload.ComponentToRender,
        props: payload.props
      }
    default:
      return state
  }
}