import { Col } from "react-bootstrap";
import { Container } from "../../index.style";

export const ContactAdmin = () => {
  return (
    <Container>
      <Col xs={12}>
        <div className="message-wrapper-activationFlow">
          <div className="message-container">
            <h2 className="message-text">
              Sorry, your TeamChat not activated <br />
              Please contact the admin of your account to activate TeamChat.
            </h2>
          </div>
        </div>
      </Col>
    </Container>
  );
};
