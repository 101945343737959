// Prioritize ccUser if we have it (in CC), otherwise use v_user
const initializeTealium = (v_user, ccUser) => {
  let firstName = v_user.first_name || '';
  let lastName = v_user.last_name || '';
  let name = firstName && lastName ? firstName + ' ' + lastName : v_user?.display_name;
  let email = v_user?.email;
  let staffUID = v_user.id;
  let businessId = v_user.business_uid;
  let country = '';
  let channels = 0;
  let users = 0;
  let cc_id = '';

  if (ccUser) {
    if (ccUser.name?.split(' ').length === 2) {
      [firstName, lastName] = ccUser.name.split(' ');
    } else if (ccUser.name){
      [firstName, lastName] = ccUser.name.split('+')[0].split('.');
    }
    if (firstName && lastName) name = firstName + ' ' + lastName;
    if (ccUser.email) email = ccUser.email;
    if (ccUser.country) country = ccUser.country;
    channels = ccUser.cc_channels?.length || 0;
    users = ccUser.cc_channels?.length || 0;
    if (ccUser.cc_id) cc_id = ccUser.cc_id;
  }

  const visitor = {
    visitor_id: staffUID,
    user_id: businessId,
    email: email,
    first_name: firstName,
    full_name: name,
    last_name: lastName,
    language: 'en',
    role: 'admin',
    has_password: true,
    account_id: businessId
  };
  const account = {
    account_id: businessId,
    business_identities: businessId,
    channels: channels,
    users: users,
    command_center_id: cc_id,
    country: country,
    state: '',
    postal_code: '',
    business_name: null,
    mc_package: null,
    package: null,
    creation_date: null,
    create_date_time: null,
    matter_term: null,
    phone_number: null,
    mobile_number: null,
    tags: null,
    thryvpay_connected: null,
    staffs: null
  };
  let tealium_data = {
    cc_account_prop: account,
    cc_user_prop: visitor
  };
  for (let key in tealium_data) {
    /*global utag_data*/
    utag_data[key] = tealium_data[key];
  }
};

export default initializeTealium;