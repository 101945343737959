
import ASCClient, { ApiEndpoint } from "@amityco/js-sdk";

// Amity sample-app apiKey
const apiKey = process.env.REACT_APP_AMITY_API_KEY;
const amityRegion = ApiEndpoint.US;

export const ASCClientService = (function () {
    let instance;
  
    function createInstance() {
      return ASCClient.create({ apiKey, apiEndpoint: amityRegion });
    }
  
    return {
      getClient: function () {
        if (!instance) {
          instance = createInstance();
        }
        return instance;
      },
    };
  })();