import { Header, ParagraphText as Text } from '@thryvlabs/maverick';
import { useHistory } from 'react-router-dom';

export default function ChannelsResults({ channels, searchText, setChannelId }) {
  const history = useHistory();
  const filteredChannels = channels?.length
    ? channels
        .filter((channel) => channel?.displayName.toLowerCase().trim().includes(searchText.toLowerCase().trim()))
        .slice(0, 10)
    : channels;

  const handleClick = (channelId) => {
    setChannelId(channelId)
    history.push('/home/main/messages');
  };

  return (
    <div className='mb-2'>
      <Header variant='h7' fontWeight='medium'>
        Channels
      </Header>
      <div className='search-results-container'>
        {filteredChannels.length > 0 ? (
          filteredChannels.map((channel) => {
            return (
              <div
                key={channel.channelId}
                className='mb-1 cursor-pointer'
                onClick={() => handleClick(channel.channelId)}
              >
                <p className='font-medium text-h9'>#{channel.displayName}</p>
              </div>
            );
          })
        ) : (
          <Text variant='sm'>No channels matched your search.</Text>
        )}
      </div>
    </div>
  );
}
