import { Icon } from '@thryvlabs/maverick';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toggleOpenFlyout } from '../../redux/actions/Flyout';

export const FlyoutOpenButton = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  // For certain routes, don't allow user to open flyout
  const routesWithoutFlyoutOpenButton = ["/new-conversation"];
  
  if (routesWithoutFlyoutOpenButton.some((route) => location.pathname.includes(route))) {
    return <></>;
  }
  
  return (
    <button className={`absolute right-0 flyout-open-button-container z-100` } onClick={() => dispatch(toggleOpenFlyout(true))}>
      <div className='flyout-open-button'>
        <Icon type='solid' variant='chevronLeft' color='#FFFFFF' height='12' width='12' style={{margin: "6px", display: "inline-block"}} />
      </div>
    </button>
  );
};

export const FlyoutCloseButton = () => {
  const dispatch = useDispatch();

  return (
    <button
      className={`flyout-close-button-container`}
      onClick={() => dispatch(toggleOpenFlyout(false))}
      aria-label='Close Flyout'
    >
      <div>
        <Icon type='regular' variant='x' color='#000000' height='12' width='12' style={{margin: "8px"}}/>
      </div>
    </button>
  );
};