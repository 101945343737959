import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OAuth } from '../../redux/actions/OAuth';
import Loader from 'react-loader-spinner';
import '../ThryvOauth/index.css';
import { setOAuthCredentials } from '../../redux/actions/ActiveUser';
import { useHistory } from 'react-router-dom';

const redirectUrl = process.env.REACT_APP_REDIRECT_URL;
const clientId = process.env.REACT_APP_CLIENT_ID;
const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

const ThryvOauth = () => {
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get('code');
  const dispatch = useDispatch();
  const { oauth, isFetching, error } = useSelector((state) => state.oauth);

useEffect(() => {
  if (!code) {
    history.push('/');
  } else if (!isFetching && !error && !oauth?.response?.access_token) {
    dispatch(
      OAuth({
        code: code,
        redirect_uri: redirectUrl,
        client_id: clientId,
        client_secret: clientSecret,
        grant_type: 'authorization_code'
      })
    );
  }
}, []);

  useEffect(() => {
    if (oauth?.response?.access_token) {
      const oauthCredentials = {
        thryv_id: oauth.usersData.business_id,
        thryv_staff_id: oauth.usersData.sub,
        access_token: oauth.response.access_token,
        directory_code: null
      };
      dispatch(setOAuthCredentials(oauthCredentials));
      localStorage.setItem('OAuthRole', oauth.usersData.role);
      history.push('/uservalidation');
    }
  }, [oauth?.response?.access_token]);

  return (
    <div className='center'>
      <Loader className='loading-dots' type='ThreeDots' color='#FF5000' height={80} width={80} />
    </div>
  );
};

export default ThryvOauth;
