export const getStorageItem = (itemName) => {
  let item = null;
  try {
    item = localStorage.getItem(itemName);
  } catch (e) {
    // console.log('LS error:', itemName);
    // console.log('Error trying to access LocalStorage:', e);
  }
  return item;
};

export const setStorageItem = (itemName, value) => {
  try {
    localStorage.setItem(itemName, value);
  } catch (e) {
    // console.log('Error trying to access LocalStorage:', e);
  }
};

export const removeStorageItem = (itemName) => {
  try {
    localStorage.removeItem(itemName);
  } catch (e) {
    // console.log('Error trying to access LocalStorage:', e);
  }
};

export const getStorageItemObject = (objectName) => {
  let parsed = null;
  try {
    let object = localStorage.getItem(objectName);
    parsed = JSON.parse(object);
  } catch (error) {
    console.log('An error occured: ', error);
  }
  return parsed;
};

export const setStorageItemObject = (objectName, object) => {
  try {
    let stringified = JSON.stringify(object);
    localStorage.setItem(objectName, stringified);
  } catch (error) {
    console.log('An error occured: ', error);
  }
};

export const removeItemFromStoredObject = (objectName, itemName) => {
  let parsed = null;
  let filtered = null;
  try {
    let object = localStorage.getItem(objectName);
    parsed = JSON.parse(object);
  } catch (error) {
    console.log('An error occured: ', error);
  }
  if (!parsed) return;
  try {
    filtered = parsed.filter((item) => item !== itemName);
    setStorageItemObject(objectName, filtered);
  } catch (error) {
    console.log('An error occured: ', error);
  }
  return filtered;
};

export const isLocalStorageAvailable = () => {
  let available = true;
  try {
    localStorage.setItem('testItem', 'testValue');
  } catch (e) {
    // console.log('Error trying to access LocalStorage:', e);
    available = false;
  }
  return available;
};

export const wasLoginRedirectAttempted = () => {
  const loginRedirectAttemptedTime = localStorage.getItem('loginRedirectAttemptedTime');
  if (!loginRedirectAttemptedTime) return false;
  const currentTime = new Date();
  const storedTime = new Date(loginRedirectAttemptedTime);
  const timeDifference = currentTime.getTime() - storedTime.getTime();
  const minutesDifference = Math.floor(timeDifference / (1000 * 60));
  if (loginRedirectAttemptedTime && minutesDifference < 1) {
    return true;
  } else {
    return false;
  }
};