import { useEffect, useState } from 'react';
import { getStorageItem } from '../../helpers/environment_helpers';
import { httpGet } from '../../helpers/http_helpers';

export default function useActiveTeamMembers() {
  const thryv_id = getStorageItem('Thryv_Id');
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    httpGet(`getallusers/${thryv_id}`)
      .then((res) => {
        const users = res.data.getUsers.filter((user) => user?.status === 1);
        setTeamMembers(users);
        setLoading(false);
        setError(null);
      })
      .catch((err) => {
        setError({ message: 'Failed to retrieve team members.', error: err });
        setLoading(false);
      });
  }, [thryv_id]);

  return {
    teamMembers,
    loading,
    error
  };
}
