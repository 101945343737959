/* global pendo */
import React, { useState, useEffect } from 'react';
import { Container } from '../../index.style';
import './index.css';
import { Row } from 'react-bootstrap';
import axios from 'axios';
import { connect, useSelector } from 'react-redux';
import { OAuth } from '../../redux/actions/OAuth';
import { API_URL } from '../../app.config';
import { useHistory } from 'react-router-dom';
import { ActivateScreen } from './activate-screen';
import { ActivationLoader } from './activation-loader';
import { ActivationFinalScreen } from './start-chatting';
import { ContactAdmin } from './contact-admin';

const ActivateTeamchat = (props) => {
  const thryv_id = props?.oauth?.oauth?.usersData?.business_id;
  const access_token = props?.oauth?.oauth?.response?.access_token;
  const OAuthRole = props?.oauth?.oauth?.usersData?.role;
  const ActiveUserId = props?.oauth?.oauth?.usersData?.sub;
  const companyName = props?.oauth?.oauth?.usersData?.business_name;
  let history = useHistory();

  const [testTimeout, setTesttimeout] = useState(false);
  const [successCheck, setsuccessCheck] = useState(false);
  const [switchOff, setswitchOff] = useState(true);
  const [accountExists, setAccountExists] = useState(false);
  const [AllStaff, setAllStaff] = useState('');
  const [clickEvent, setClickEvent] = useState(false);
  const [contactAdmin, setContactAdmin] = useState(false);
  const [activateTeamChat, setActivateTeamChat] = useState(false);
  const [loadData, setLoadData] = useState(false);

  const { directory_code } = useSelector((state) => state.ActiveUser);

  useEffect(() => {
    //Checking if Account Already Present in AccountTable
    axios
      .get(`${API_URL}api/teamchat/getaccount/${thryv_id}`)
      .then((res) => {
        if (Object.keys(res.data).length > 0) {
          setAccountExists(true);
        }
        setLoadData(true);
      })
      .catch((err) => console.log(err));

    const staffmember_info = {
      access_token,
      thryv_id
    };

    if (directory_code) staffmember_info['dirCode'] = directory_code;

    //Calling V endpoint to get all staff associate with ThryvAccount
    axios
      .post(`${API_URL}api/teamchat/staff`, staffmember_info)
      .then((res) => {
        setAllStaff(res.data.data.staff);
      })
      .catch((err) => console.log(err));
  }, []);

  //If Account existed in account table in db, means, it has been activated, if not then display activationScreen!
  useEffect(() => {
    if (accountExists) {
      //If Account is present and subId is active, checking if users present in UserTable
      axios
        .get(`${API_URL}api/teamchat/getuser/${ActiveUserId}`)
        .then((res) => {
          if (res?.data?.getUser?.status === 1) {
            console.log('Pushing to /home');
            history.push('/home/main/messages');
          } else {
            setContactAdmin(true);
          }
        })
        .catch((err) => console.log(err));
    } else if (!accountExists && loadData) {
      // Route to /createaccount
      setActivateTeamChat(true);
    }
  }, [accountExists, activateTeamChat, ActiveUserId, loadData]);

  const Donothing = () => {
    setTesttimeout(false);
  };

  //Activate FreeTrail, disaplying only if account not present in table
  const teamChatActivation = () => {
    setswitchOff(false);
    setTimeout(Donothing, 5000);
    setTesttimeout(true);

    const staffmember_emails = [];
    const staffmember_ids = [];

    AllStaff.map(function (staff) {
      staffmember_emails.push(staff.email);
      staffmember_ids.push(staff.id);
      const newuser_info = {
        thryv_id: staff.business_uid,
        thryv_staff_id: staff.id,
        role: staff.role,
        notifications: 0,
        guest: 0,
        email: staff.email,
        phone: staff.mobile_number,
        timezone: null,
        status: 1,
        avatar_url: staff.photo,
        display_name: staff.display_name
      };

      //Inserting all staff to user table
      axios
        .post(`${API_URL}api/teamchat/newuser`, newuser_info)
        .then((res) => {
          setsuccessCheck(true);
        })
        .catch((err) => console.log(err));
    });
    setClickEvent(true);
  };

  const RedirectToApp = () => {
    console.log('RedirectToApp');
    history.push('/home');
  };

  if (clickEvent) {
    let today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 90);
    let todayFormattedDate =
      new Date().getFullYear() +
      '-' +
      ('0' + (new Date().getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + new Date().getDate()).slice(-2);
    let tomorrowFormattedDate =
      tomorrow.getFullYear() +
      '-' +
      ('0' + (tomorrow.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + tomorrow.getDate()).slice(-2);

    const newaccount_info = {
      thryv_id,
      thryv_access_token: access_token,
      status: 1,
      free_trial_start_date: todayFormattedDate,
      free_trial_end_date: tomorrowFormattedDate,
      company: companyName,
      dirCode: directory_code
    };

    //Adding new account in account table
    axios
      .post(`${API_URL}api/teamchat/newaccount/subscribe`, newaccount_info)
      .then((res) => {})
      .catch((err) => console.log(err));

    setClickEvent(false);
  }

  return (
    <div>
      {activateTeamChat && OAuthRole === 'owner' && (
        <Container>
          <Row>
            {switchOff && (
              <ActivateScreen teamChatActivation={teamChatActivation} />
            )}
            {testTimeout && <ActivationLoader />}
            {!testTimeout && successCheck && (
              <ActivationFinalScreen RedirectToApp={RedirectToApp} />
            )}
          </Row>
        </Container>
      )}

      {activateTeamChat && OAuthRole !== 'owner' && <ContactAdmin />}
      {contactAdmin && <ContactAdmin />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    oauth: state.oauth
  };
};

export default connect(mapStateToProps, { OAuth })(ActivateTeamchat);
