import { Header, Icon, ParagraphText as Text } from '@thryvlabs/maverick';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { getStorageItemObject, removeItemFromStoredObject, setStorageItemObject } from '../../helpers/environment_helpers';

export default function RecentSearches() {
  const [recentSearches, setRecentSearches] = useState([]);

  useEffect(() => {
    const storedSearches = getStorageItemObject('RecentSearches');
    setRecentSearches(storedSearches);
    if (!storedSearches) setStorageItemObject("RecentSearches", []);
  }, []);

  if (!recentSearches || recentSearches.length === 0) return <Text variant='reg'>No recent searches.</Text>;

  const handleClick = (searchText) => {
    let filtered = recentSearches.filter(item => item !== searchText)
    setRecentSearches(filtered);
    removeItemFromStoredObject('RecentSearches', searchText);
  };

  return (
    <>
      <Header variant='h8' fontWeight='bold' className='search-popover-title'>
        Recent Searches
      </Header>
      {recentSearches.map((searchText, i) => {
        return (
          <div className='flex flex-row justify-between align-items-center' key={`${i}-${searchText}`}>
            <Text variant='reg' >{searchText}</Text>
            <Button className='z-10' variant='text' level={1} onClick={() => handleClick(searchText)} data-popover="keep-open">
              <Icon className="pointer-events-none" variant='x' type='regular' color='#A3A5A7' height='8' width='8'/>
            </Button>
          </div>
        );
      })}
    </>
  );
}
