import { ParagraphText as Text } from '@thryvlabs/maverick';
import { useHistory } from 'react-router-dom';

export default function SearchPopoverChannels({ channels, channelsLoading, channelsError, searchText, setChannelId }) {
  const history = useHistory();

  if (searchText === '') return <Text variant='sm'>Search field empty.</Text>

  if (channelsError) return <Text variant='sm'>Something went wrong.</Text>

  if (channelsLoading) return <Text variant='sm'>Searching for Channels...</Text>

  const handleClick = (channelId) => {
    setChannelId(channelId)
    history.push('/home/main/messages');
  };

  return (
    <>
      {channels.length > 0 ? (
        channels.slice(0, 5).map((channel) => (
          <div key={channel.channelId} className='mb-1 search-popover-result' onClick={() => handleClick(channel.channelId)}>
            <p className='font-medium text-h9'>#{channel.displayName}</p>
          </div>
        ))
      ) : (
        <Text variant='sm'>No channels match your search.</Text>
      )}
    </>
  );
}
