import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import * as serviceWorker from './serviceWorker';
import RootReducer from './redux/reducers';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import './maverick-overrides.css';
import { Auth0Provider } from '@auth0/auth0-react';

const store = createStore(RootReducer, applyMiddleware(thunk));

//Sentry global declaration
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0
});

//launchdarkly global provider
(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID
  });

  ReactDOM.render(
    <LDProvider>
      <Provider store={store}>
        <Router>
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            authorizationParams={{
              redirect_uri: window.location.origin,
              audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            }}
          >
            <App />
          </Auth0Provider>
        </Router>
      </Provider>
    </LDProvider>,
    document.getElementById('root')
  );
})();

serviceWorker.register();
