import React, { useState } from 'react';
import './index.css';
import { ClientList } from './ClientList';
import { ClientProfile } from './ClientProfile';
import { Icon } from '@thryvlabs/maverick';
import { useDispatch, useSelector } from 'react-redux';
import { setInputMessageValue } from '../../redux/actions/MessageInput';
import { mapPlainTextIndex } from '../../helpers/react-mentions-helpers';
import { THRYV_URL } from '../../app.config';

export const REFERENCE_ELEMENTS = {
  CLIENT_LIST: 'CLIENT_LIST',
  CLIENT_PROFILE: 'CLIENT_PROFILE'
};

export const REFERENCE_ELEMENT_TYPE = {
  CLIENT: 'CLIENT',
  THRYV_USER: 'THRYV_USER',
  CONVERSATION: 'CONVERSATION',
  ESTIMATE: 'ESTIMATE',
  INVOICE: 'INVOICE',
  BOOKING: 'BOOKING',
  DOCUMENT: 'DOCUMENT'
};

export const getLinkContent = (v, type) => {
  const defaultLink = 'LINK';
  switch (type) {
    case REFERENCE_ELEMENT_TYPE.CLIENT:
      return typeof v === 'string'
        ? `${THRYV_URL}/app/clients/${v}`
        : defaultLink;
    case REFERENCE_ELEMENT_TYPE.CONVERSATION:
      return `${THRYV_URL}/app/clients/${v?.client_id}?tab=conversation`;
    case REFERENCE_ELEMENT_TYPE.INVOICE:
      return `${THRYV_URL}/app/invoices/${v?.id}`;
    case REFERENCE_ELEMENT_TYPE.ESTIMATE:
      return `${THRYV_URL}/app/payments/estimates/${v?.id}`;
    case REFERENCE_ELEMENT_TYPE.BOOKING:
      return `${THRYV_URL}/app/appointments/${v?.id}`;
    case REFERENCE_ELEMENT_TYPE.DOCUMENT:
      return v?.download_link;
    default:
      return defaultLink;
  }
};

export const ReferencePanel = ({ close, title }) => {
  const [activeComponent, setActiveComponent] = useState(
    REFERENCE_ELEMENTS.CLIENT_LIST
  );
  const [selectedClient, setSelectedClient] = useState();

  return (
    <>
      <div
        className={
          activeComponent === REFERENCE_ELEMENTS.CLIENT_LIST
            ? undefined
            : 'hidden'
        }
      >
        <ClientList
          closeModal={close}
          setActiveComponent={setActiveComponent}
          title={title}
          setSelectedClient={setSelectedClient}
        />
      </div>
      {activeComponent === REFERENCE_ELEMENTS.CLIENT_PROFILE && (
        <ClientProfile
          closeModal={close}
          setActiveComponent={setActiveComponent}
          selectedClient={selectedClient}
        />
      )}
    </>
  );
};

export const AddButton = ({
  handleAddClick,
  selectedValue,
  closeModal,
  type = 'TYPE',
  clientDetails
}) => {
  const MENTION_MARKUP = '@[__display__](__id__)';
  const MENTION_REGEX = /@\[(.+?)]\((.+?)\)/;
  const messageInputValue = useSelector(
    (s) => s.MessageInput.messageInputValue
  );
  const dispatch = useDispatch();

  const getTitle = (v) => {
    switch (type) {
      case REFERENCE_ELEMENT_TYPE.CLIENT:
        return `${v?.first_name} ${v?.last_name}`;
      case REFERENCE_ELEMENT_TYPE.CONVERSATION:
        return `${v?.title ?? 'Untitled'} {${clientDetails?.first_name} ${
          clientDetails?.last_name
        }}`;
      default:
        return `${v?.title ?? 'Missing Title'} {${clientDetails?.first_name} ${
          clientDetails?.last_name
        }} `;
    }
  };

  const getIdContent = (v) => {
    switch (type) {
      case REFERENCE_ELEMENT_TYPE.CLIENT:
        return `${v?.id},${v?.business_uid},${v?.first_name},${v?.last_name},${v?.email}`;
      default:
        return v?.id;
    }
  };

  const formatValue = (v) => {
    console.log(v);
    return `@[${getTitle(v)}](${getIdContent(v)}::${type}::${getLinkContent(
      v,
      type
    )})`;
  };

  if (!handleAddClick) {
    handleAddClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const messageInputRef = document.getElementById('message-input-element');
      const start = mapPlainTextIndex(
        messageInputValue,
        [
          {
            regex: MENTION_REGEX,
            markup: MENTION_MARKUP,
            displayTransform: function (id, display) {
              return display;
            }
          }
        ],
        messageInputRef?.selectionStart ?? 0
      );
      const end = mapPlainTextIndex(
        messageInputValue,
        [
          {
            regex: MENTION_REGEX,
            markup: MENTION_MARKUP,
            displayTransform: function (id, display) {
              return display;
            }
          }
        ],
        messageInputRef?.selectionEnd ?? 0
      );
      // dispatch(setInputMessageValue({messageInputValue: `${messageInputValue} ${formatValue(selectedValue)}`}));
      dispatch(
        setInputMessageValue({
          messageInputValue: replaceBetween(
            messageInputValue,
            start,
            end,
            formatValue(selectedValue)
          )
        })
      );
      closeModal();
    };
  }

  // todo: move this method to common place (also used in emoji component)
  const replaceBetween = (origin, startIndex, endIndex, insertion) => {
    return (
      origin.substring(0, startIndex - 1) +
      insertion +
      origin.substring(endIndex)
    );
  };

  return (
    <div onClick={handleAddClick}>
      <Icon
        type='solid'
        variant='circlePlus'
        color='#FF5000'
        height={'25'}
        width={'25'}
      />
    </div>
  );
};
