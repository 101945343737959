import React, { useEffect, useRef, useState } from 'react';
import Picker from 'emoji-picker-react';
import { useDispatch, useSelector } from 'react-redux';
import { setInputMessageValue } from '../../redux/actions/MessageInput';
import { mapPlainTextIndex } from '../../helpers/react-mentions-helpers';
import './index.css';

export const EmojiPicker = ({ children, ...rest }) => {
  const [pickerStatus, setPickerStatus] = useState(false);
  const ref = useRef();
  const MENTION_MARKUP = '@[__display__](__id__)';
  const MENTION_REGEX = /@\[(.+?)]\((.+?)\)/;

  const messageInputValue = useSelector(
    (s) => s.MessageInput.messageInputValue ?? ''
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const listener = (e) => {
      if (!ref?.current?.contains(e?.target)) {
        setPickerStatus(false);
      }
    };
    document.addEventListener('click', listener);
    return () => {
      document.removeEventListener('click', listener);
    };
  }, []);

  const replaceBetween = (origin, startIndex, endIndex, insertion) => {
    return (
      origin.substring(0, startIndex) + insertion + origin.substring(endIndex)
    );
  };

  const onEmojiClick = (e, emojiObject) => {
    e.preventDefault();
    e.stopPropagation();

    const messageInputRef = document.getElementById('message-input-element');
    const start = mapPlainTextIndex(
      messageInputValue,
      [
        {
          regex: MENTION_REGEX,
          markup: MENTION_MARKUP,
          displayTransform: function (id, display) {
            return display;
          }
        }
      ],
      messageInputRef?.selectionStart ?? 0
    );
    const end = mapPlainTextIndex(
      messageInputValue,
      [
        {
          regex: MENTION_REGEX,
          markup: MENTION_MARKUP,
          displayTransform: function (id, display) {
            return display;
          }
        }
      ],
      messageInputRef?.selectionEnd ?? 0
    );
    dispatch(
      setInputMessageValue({
        messageInputValue: replaceBetween(
          messageInputValue,
          start,
          end,
          emojiObject.emoji
        )
      })
    );
    messageInputRef.focus();
    setPickerStatus(false);
  };
  return (
    <span ref={ref} className={'EmojiPicker'}>
      <em
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setPickerStatus(!pickerStatus);
        }}
      >
        {children}
      </em>
      {pickerStatus && (
        <div className={'EmojiPickerContainer'}>
          <Picker onEmojiClick={onEmojiClick} disableAutoFocus={true} />
        </div>
      )}
    </span>
  );
};
