import { useEffect, useState } from 'react';
import axios from 'axios';

export default function useUserActiveChannels(thryvStaffId) {
  const [channels, setChannels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const authHeader = {
      headers: {
        Authorization: 'Bearer ' + process.env.REACT_APP_AMITY_ACCESS_TOKEN,
        accept: 'application/json',
        ContentType: 'application/json'
      }
    };

    axios
      .get(
        `https://api.us.amity.co/api/v3/channels?filter=member&userId=${thryvStaffId}&options[limit]=100`,
        authHeader
      )
      .then((res) => {
        const channels = res.data.channels;
        setChannels(channels);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
      });
  }, []);

  return { channels, loading, error };
}
