import React, { useEffect, useState } from "react";
import { Navigation } from "@thryvlabs/maverick";
import { Select } from "@thryvlabs/maverick";
import { Section, ModalTitle, Button } from "@thryvlabs/maverick";
import { httpGet, httpPut } from "../../helpers/http_helpers";
import { timeZoneList } from "./timeZoneList";
import {
  areNotificationsEnable,
  requestNotificationPermission,
} from "../../helpers/notifications_helpers";
import { useMediaQuery } from "react-responsive";
import MobileTabsDropdown from "./MobileTabsDropdown";
import useToast from "../../hooks/Toasts/useToast";
import { isAndroid } from "react-device-detect";
import SubscriptionSettings from "./SubscriptionSettings";
import useSubscriptionStatus from "../../hooks/Settings/useSubscriptionStatus";
import useIsInFrame from "../../hooks/Layout/useIsInFrame";
import { NOTIFICATION_DB_VALUES, EMAIL_NOTIFICATION_OPTIONS, BROWSER_NOTIFICATION_OPTIONS } from "./constants";
import "./index.css";
import { getStorageItem } from "../../helpers/environment_helpers";
import TeamMemberSettings from "./TeamMemberSettings";
export const TeamChatSettings = (props) => {
  const [staffmembers_array, setStaffMembersArray] = useState([]);
  const [role, setRole] = useState(false);
  const [updatedSubscribed, setUpdatedSubscribed] = useState(false);
  const [desktopNotifications, setDesktopNotifications] = useState(() => BROWSER_NOTIFICATION_OPTIONS[0]);
  const [notificationFrequency, setNotificationFrequency] = useState({
    name: "",
    value: "",
  });


  const { triggerToast } = useToast();
  const { isInFrame } = useIsInFrame();

  let OAuthRole = getStorageItem("OAuthRole");
  const thryv_business_id = getStorageItem("business_id");
  const thryv_staff_id = getStorageItem("ActiveUserId");

  const thryvId = thryv_business_id;
  const thryvStaffId = thryv_staff_id || "qean7b1c0adxied5";
  const MobileView = useMediaQuery({ query: "(max-width: 767px)" });

  useEffect(() => {
    if (OAuthRole !== "owner") {
      setRole(false);
    } else {
      setRole(true);
    }

    httpGet(`getuser/${thryvStaffId}`).then((res) => {
      const timeZoneFromDB = res?.data?.getUser?.timezone;
      const machineTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const userDesktopNotifications =
        res?.data?.getUser?.desktop_notifications;
      const userEmailNotifications = res?.data?.getUser?.notifications;
      setSelectedTimeZone({
        name: timeZoneFromDB
          ? timeZoneFromDB
          : timeZoneList.includes(machineTimezone)
            ? machineTimezone
            : "",
        value: timeZoneFromDB ?? "",
      });

      const currentDesktopNotification =
        BROWSER_NOTIFICATION_OPTIONS.find(option => option.value === userDesktopNotifications);

      setDesktopNotifications(currentDesktopNotification);

      const notificationValue = Object.keys(NOTIFICATION_DB_VALUES).find(
        (key) => NOTIFICATION_DB_VALUES[key] === userEmailNotifications
      );
      const notificationFrequency = EMAIL_NOTIFICATION_OPTIONS.find(
        (f) => f.value === notificationValue
      );
      setNotificationFrequency(notificationFrequency);
    });

    httpGet(`getallusers/${thryvId}`).then((res) => {
      setStaffMembersArray(res.data.getUsers);
    });
  }, [OAuthRole, updatedSubscribed]);

  useEffect(() => {
    (async () => {
      await requestNotificationPermission();
    })();
  });

  const tabs = [
    {
      name: "General",
      pillVariant: "secondary",
      alerts: 0,
    },
    {
      name: "Team Members",
      pillVariant: "secondary",
      alerts: 0,
    },
    {
      name: "Subscription",
      pillVariant: "secondary",
      alerts: 0,
    },
  ];

  const [selectedTimeZone, setSelectedTimeZone] = useState({
    name: window.selectedTimeZone ?? "",
    value: window.selectedTimeZone ?? "",
  });

  const [activeClientTab, setActiveClientTab] = useState("General");

  const handleTabChange = (activeTabIndex) => {
    const tabName =
      tabs[activeTabIndex] !== undefined
        ? tabs[activeTabIndex].name
        : "General";
    setActiveClientTab(tabName);
  };

  const [mustSaveGeneralSettings, setMustSaveGeneralSettings] = useState(false);

  const saveGeneralSettings = async () => {
    const updateUser = {
      thryv_staff_id: thryvStaffId,
      changes: {
        timezone: selectedTimeZone.value,
        desktop_notifications: desktopNotifications.value,
        notifications: NOTIFICATION_DB_VALUES[notificationFrequency?.value],
      },
    };

    httpPut(`/updateuser`, updateUser);
    setMustSaveGeneralSettings(false);
    triggerToast("Changes Saved.", "success");
  };

  const onChangeDesktopNotifications = async (option) => {
    if (!areNotificationsEnable()) {
      await requestNotificationPermission();
    }
    localStorage.setItem("desktop_notifications", option.value);
    setDesktopNotifications(option);
    setMustSaveGeneralSettings(true);
  };

  const rightAlignedStyles = {
    display: "flex",
    justifyContent: "flex-end",
  };

  const GeneralSettings = () => {
    const frequencyTextOnMobileDevice = (
      <div style={{ marginLeft: "150px" }}>Frequency</div>
    );

    return (
      <div className="my-3 row">
        <div className="col">
          <Section subSize="md" title={"General"} titleVariant={"primary"}>
            <ModalTitle size="md" variant="subtitle">
              {MobileView ? (
                <>
                  <div>
                    Email Notifications
                    <div className="float-end">
                      <Select
                        options={EMAIL_NOTIFICATION_OPTIONS}
                        width="md"
                        selectLabel={frequencyTextOnMobileDevice}
                        selectedOption={notificationFrequency}
                        setSelectedOption={(v) => {
                          setNotificationFrequency(v);
                          setMustSaveGeneralSettings(true);
                        }}
                        className="settings-select-dropdown"
                      />
                    </div>
                  </div>
                  <div
                    className=""
                    style={{
                      fontSize: "14px",
                      fontWeight: "normal",
                      marginTop: "15px",
                    }}
                  >
                    Configure the frequency of email notifications
                  </div>
                  {isAndroid && (
                    <>
                      <br />
                      <div>
                        Browser Notifications
                        <div className="float-end">
                          <Select
                            selectLabel="-select-"
                            options={BROWSER_NOTIFICATION_OPTIONS}
                            width="md"
                            selectedOption={desktopNotifications}
                            onChange={onChangeDesktopNotifications}
                            className="settings-select-dropdown"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                    <div
                      className="d-inline-flex justify-content-between center-align"
                      style={{ width: "50%" }}
                    >
                      <div style={{ width: "50%" }}>Desktop Notifications </div>
                      <Select
                        selectLabel="-select-"
                        options={BROWSER_NOTIFICATION_OPTIONS}
                        width="md"
                        selectedOption={desktopNotifications}
                        onChange={onChangeDesktopNotifications}
                        className="settings-select-dropdown"
                      />
                      
                    </div>
                  <br />
                  <br />
                  <div
                    className="d-inline-flex justify-content-between center-align"
                    style={{ width: "50%" }}
                  >
                    <div style={{ width: "50%" }}>Email Notifications</div>
                    <Select
                      options={EMAIL_NOTIFICATION_OPTIONS}
                      width="md"
                      selectLabel="Frequency"
                      selectedOption={notificationFrequency}
                      setSelectedOption={(v) => {
                        setNotificationFrequency(v);
                        setMustSaveGeneralSettings(true);
                      }}
                      className="settings-select-dropdown"
                    />
                  </div>
                  <div>
                    <p
                      style={{
                        fontSize: "12px",
                        fontWeight: "normal",
                        marginTop: "15px",
                      }}
                    >
                      Receive an email whenever you are mentioned.
                    </p>
                  </div>
                </>
              )}
            </ModalTitle>
            {MobileView ? <br /> : ""}
            <div className="w-100 flex-end" style={rightAlignedStyles}>
              <div>
                <Button
                  variant={mustSaveGeneralSettings ? "primary" : "secondary"}
                  disabled={!mustSaveGeneralSettings}
                  onClick={saveGeneralSettings}
                >
                  Save
                </Button>
              </div>
            </div>
          </Section>
        </div>
      </div>
    );
  };

  // todo: change this to nested routes and append relevant dropdown data and actions for Team chat notificatoins ---
  const subscriptionStatus = useSubscriptionStatus(thryvId);

  return (
    <div
      className={`TeamChatSettingsContainer ${
        isInFrame ? "settings-height-in-frame" : "settings-height-standard"
      }`}
      style={{ overflow: "auto" }}
    >
      <div className={"NavBar"}>
        <Navigation
          variant="back"
          onClick={() =>
            props.someProps.props.someProps.props.history.push("/home")
          }
        />
      </div>
      {MobileView && role ? (
        <div className={"p-4 mt-4"}>
          <MobileTabsDropdown handleTabChange={handleTabChange} />
          <ModalTitle variant="primary">Settings</ModalTitle>
          {
            {
              General: <GeneralSettings />,
              "Team Members": (
                <TeamMemberSettings
                  staffMembers={staffmembers_array}
                  updatedSubscribed={updatedSubscribed}
                  setUpdatedSubscribed={setUpdatedSubscribed}
                />
              ),
              Subscription: (
                <SubscriptionSettings subscriptionStatus={subscriptionStatus} />
              ),
            }[activeClientTab]
          }
        </div>
      ) : (
        <div className={"p-4 mt-4"}>
          <ModalTitle variant="primary">Settings</ModalTitle>
          {role ? (
            <Navigation
              id="settings-nav"
              variant="tabs"
              linksArray={tabs}
              setSelectedOption={handleTabChange}
              selected={() =>
                tabs.findIndex((tab) => tab.name === activeClientTab)
              }
            />
          ) : null}
          {
            {
              General: <GeneralSettings />,
              "Team Members": (
                <TeamMemberSettings
                  staffMembers={staffmembers_array}
                  updatedSubscribed={updatedSubscribed}
                  setUpdatedSubscribed={setUpdatedSubscribed}
                />
              ),
              Subscription: (
                <SubscriptionSettings subscriptionStatus={subscriptionStatus} />
              ),
            }[activeClientTab]
          }
        </div>
      )}
    </div>
  );
};