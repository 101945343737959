import './index.css';
import { ChannelMember } from '../ChannelMember';
import InviteChannelMembersModal from '../../InviteChannelMembersModal';
import { Button, Header, Icon, ParagraphText } from '@thryvlabs/maverick';
import { ChannelRepository } from '@amityco/js-sdk';
import useToast from '../../../hooks/Toasts/useToast';
import { useDispatch, useSelector } from 'react-redux';
import { toggleOpenFlyout } from '../../../redux/actions/Flyout';
import { useEffect, useState } from 'react';
import { httpGet } from '../../../helpers/http_helpers';

export const ChannelMembers = ({ channelId, channelType, channelMembers, channelCreatorStaffId, isChannelDeleted }) => {
  const dispatch = useDispatch();
  const { triggerToast } = useToast();
  const [allStaff, setAllStaff] = useState([]);
  const [loading, setLoading] = useState(true);
  const { thryv_id } = useSelector((state) => state.ActiveUser);
  
  useEffect(() => {
      httpGet(`getallusers/${thryv_id}`)
        .then((res) => {
          const users = res.data.getUsers;
          setAllStaff(users);
          setLoading(false);
        }).catch(err => console.log(err))
  }, []);

  const handleRestoreChannel = async () => {
    const isRestored = await ChannelRepository.updateChannel({
      channelId: channelId,
      tags: ['restored']
    });
    if (isRestored) {
      dispatch(toggleOpenFlyout(false));
      triggerToast("Channel successfully restored.", "success");
    } else {
      triggerToast("Channel could not be restored.", "failure");
    }
  }

  if (isChannelDeleted)
    return (
      <div id='channel-is-deleted'>
        <Icon variant='users' type='solid' color='#FF5000' width='45' height='45' className="mb-2"/>
        <Header variant='h6' fontWeight='semibold'>
          No Members
        </Header>
        <ParagraphText variant='sm' color='thryv-steel'>
          This channel has been archived or deleted. Would you like to restore this channel?
        </ParagraphText>
        <Button className="mt-3 !border-none" variant="secondary" onClick={handleRestoreChannel}>RESTORE</Button>
      </div>
    );

  const channelMembersCount = channelMembers.length;

  return (
    <>
      {!loading && allStaff?.length > channelMembers?.length && (channelType === 'community' || channelType === 'live') ? (
        <span className='pt-3'>
          <InviteChannelMembersModal channelId={channelId} buttonText='+ Invite New' />
        </span>
      ) : null}
      <div className='channel-member-list'>
        {channelMembers.map((channelMember, i) => (
          <ChannelMember
            key={`${i}-${channelMember?.userId}`}
            channelId={channelId}
            channelType={channelType}
            channelMembersCount={channelMembersCount}
            member={channelMember}
            channelCreatorStaffId={channelCreatorStaffId}
          />
        ))}
      </div>
    </>
  );
};
