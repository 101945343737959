import {
  SET_OAUTH_CREDENTIALS,
  SET_V_USER,
  SET_DESKTOP_NOTIFICATIONS,
  SET_RECURLY_PLAN,
  SET_IN_COMMAND_CENTER,
  SET_CC_STAFF_SYNC_SUCCESSFUL
} from './types';

export const setOAuthCredentials = ({ thryv_id, thryv_staff_id, access_token, directory_code }) => ({
  type: SET_OAUTH_CREDENTIALS,
  payload: {
    thryv_id,
    thryv_staff_id,
    access_token,
    directory_code
  }
});

export const setVUser = (vUser) => ({
  type: SET_V_USER,
  payload: vUser
});

export const setDesktopNotifications = (desktop_notifications) => ({
  type: SET_DESKTOP_NOTIFICATIONS,
  payload: desktop_notifications
});

export const setRecurlyPlan = (recurlyPlan) => ({
  type: SET_RECURLY_PLAN,
  payload: recurlyPlan
});

export const setInCommandCenter = (inCommandCenter) => ({
  type: SET_IN_COMMAND_CENTER,
  payload: inCommandCenter
});

export const setCCStaffSyncSuccessful = (cc_staff_sync_successful) => ({
  type: SET_CC_STAFF_SYNC_SUCCESSFUL,
  payload: cc_staff_sync_successful
});