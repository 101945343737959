import React, { useEffect, useState } from "react";
import {
  ChannelRepository,
  ChannelType,
  MessageRepository,
} from "@amityco/js-sdk";
import {
  Select,
  ParagraphText as Text,
  Modal,
} from "@thryvlabs/maverick";
import "./index.css";
import { httpGet, httpPut } from "../../helpers/http_helpers";
import Loader from "react-loader-spinner";
import { useMediaQuery } from "react-responsive";
import useToast from "../../hooks/Toasts/useToast";
import useUserActiveChannels from "../../hooks/Channels/useUserActiveChannels";
import axios from 'axios';
import { API_URL } from '../../app.config';

export const PopulatingChannelList = ({
  staffMember,
  accountChannels,
  setSelectedData,
  setPreSelectedChannelOptions,
  updatedSubscribed,
  setSaveButtonToggled,
  previousSelection,
  setPreviousSelection,
  setUpdatedSubscribed,
  updatePreselectedChannelsReady,
  error

}) => {
  const [channelselectedOptions, setchannelSelectedOptions] = useState([]);
  const [selectedOptionsReady, setSelectedOptionsReady] = useState(false);
  const { triggerToast } = useToast();

  const adminStaffId = localStorage.getItem("Thryv_Staff_Id");
  // const adminStaffId= "Test";

  let thryvId = localStorage.getItem("Thryv_Id");
  let userId = staffMember.thryv_staff_id;

  // Mock user info if in not in production TODO: remove this
  //         if(process.env.NODE_ENV === 'local' || !thryvId || !thryvStaffId){
  //             thryvId = 'x1chdpxaxefmzl5a'
  //             thryvStaffId = 'x1chdpxaxefmzl5a'
  //         }

  const MobileView = useMediaQuery({ query: "(max-width: 830px)" });

  const channelOptions = accountChannels.map((channel) => {
    return { name: channel.displayName, value: channel.channelId };
  });

  let {
    channels,
    loading: loadingMemberChannels,
    error: memberChannelsError,
  } = useUserActiveChannels(userId);

  useEffect(() => {
    if (error && error === staffMember.thryv_staff_id) {
      setchannelSelectedOptions(previousSelection[staffMember.thryv_staff_id]);
      //we need to make sure that the previous select
    }
  }, [error])
 
  useEffect(() => {
   setSaveButtonToggled(true);
    setSelectedData((state) => {
      return { ...state, [staffMember.thryv_staff_id]: channelselectedOptions };
    });
  }, [channelselectedOptions]);

  useEffect(() => {
    if (loadingMemberChannels || updatePreselectedChannelsReady) return;
      const preSelectedChannels = [];
      channels.forEach((channel) => {
        accountChannels.forEach((accountChannel)=>{
          if (channel.channelId === accountChannel.channelId) {
            preSelectedChannels.push({name: channel.displayName, value: channel.channelId});
          }
        })
      });   
      setPreviousSelection((state) => {
        return {...state, [staffMember.thryv_staff_id]: preSelectedChannels};
      });
      setchannelSelectedOptions(preSelectedChannels);
      setPreSelectedChannelOptions(preSelectedChannels);
      setSelectedOptionsReady(true);
  }, [loadingMemberChannels, accountChannels]);
  
  const toggleSubscribed = async (
    status,
    thryv_staff_id,
    display_name,
    avatar_url
  ) => {
    const updateState = updatedSubscribed ? false : true;
    const updated_status = status ? 0 : 1;

    const updateUser = {
      thryv_id: thryvId,
      thryv_staff_id: thryv_staff_id,
      changes: {
        status: updated_status,
      },
    };

    httpPut(`updateuser`, updateUser)
      .then(async (res) => {
        const updatedActiveUser = res.data?.updatedSettings;
        if (updated_status === 1) {
          setUpdatedSubscribed(updateState);
          const userIdsForNewConvo = [adminStaffId, thryv_staff_id];
          let adminUserRes = await httpGet(`getuser/${adminStaffId}`);
          let adminUser = adminUserRes.data.getUser;
          const newConversationMetadata = { coverImageUrl: adminUser?.avatarUrl || null, convoMembersDetailedList: [updatedActiveUser, adminUser]}
          const liveChannel = ChannelRepository.createChannel({
            type: ChannelType.Conversation,
            userIds: userIdsForNewConvo,
            displayName: display_name,
            metadata: newConversationMetadata,
          });
          const text = "Welcome to Teamchat!";
          liveChannel.once("dataUpdated", (e) => {
            const channelId = e.channelId;
            MessageRepository.createTextMessage({
              channelId,
              text,
            });
          });
          // Add user to amity & staff channel just in case
          const formattedAmityUserData = {
            thryv_id: thryvId,
            thryv_staff_id,
            display_name
          };
          axios.post(`${API_URL}api/teamchat/amity/newuser`, formattedAmityUserData)
          .then(() => {
          }).catch((err) => console.log(err));
        } else {
          setUpdatedSubscribed(updateState);
        }
        triggerToast(
          updated_status === 1
            ? "Team member added to TeamChat."
            : "Team member removed from TeamChat.",
          "success"
        );
      })
      .catch((err) => {
        console.log(err);
        triggerToast(
          updated_status === 1
            ? "Failed to invite member to TeamChat."
            : "Failed to remove member from TeamChat.",
          "failure"
        );
      });
  };

  return (
    <>
      <div
        key={staffMember.id}
        className={
          MobileView ? "flex flex-wrap py-1 gap-3" : "flex align-items-center"
        }
      >
        {/* Staffmember email address */}
        <div
          style={{
            minWidth: "300px",
            paddingRight: "1rem",
          }}
        >
          <span className="capitalize pointer-events-none font-open-sans text-label-text text-thryv-black-500">
            Email address
          </span>
          <p className="font-normal text-thryv-black-500 font-open-sans text-form-text font-weight-600">
            {staffMember.email}
          </p>
        </div>
        {/* Select Dropdown */}
        <div
          className="addButtonSettingUnique"
          style={{
            minWidth: "300px",
            width: "20%",
            marginRight: MobileView ? "0px" : "15px",
          }}
        >
          {selectedOptionsReady ? (
            <Select
              options={channelOptions}
              selectLabel="Channels"
              multiselect
              selectedOptions={channelselectedOptions}
              setSelectedOptions={setchannelSelectedOptions}
              width="full"
              largeHeight
            />
          ) : memberChannelsError ? (
            ""
          ) : (
            <Loader
              className="loading-dots"
              type="ThreeDots"
              color="#FF5000"
              height={30}
              width={30}
            />
          )}
        </div>

        {/* Add / Remove Buttons */}
        <div
          style={{
            margin: MobileView ? "auto" : "15px",
            textAlign: MobileView ? "center" : "right",
            padding: "1rem",
          }}
        >
          <Modal
            variant="default"
            title={staffMember.status ? "Remove User" : "Add User"}
            btnText={staffMember.status ? "Remove" : "Add"}
            btnType={staffMember.status ? "secondary" : "primary"}
            footer
            action
            btnActionText="Yes"
            btnAction={() =>
              toggleSubscribed(
                staffMember.status,
                staffMember.thryv_staff_id,
                staffMember.display_name,
                staffMember.avatar_url
              )
            }
            primaryFooterVariant="dark"
            footerCancel
            actionClose
          >
            <Text variant="reg">
              Are you sure you want to {staffMember.status ? "remove" : "add"}{" "}
              user?
            </Text>
          </Modal>
        </div>
      </div>
    </>
  );
};