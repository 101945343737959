/* eslint-disable no-restricted-globals */
import { ConnectionStatus } from '@amityco/js-sdk';
import axios from 'axios';
import { API_URL } from './app.config';
import { ASCClientService } from './ascClientService';
import { sessionCreated } from './redux/actions/AmitySession';

//Do not remove the commented code below for now
// let amityRegion
// if (process.env.REACT_APP_AMITY_REGION === "sg") {
//   amityRegion = ApiEndpoint.SG
// } else {
//   amityRegion = ApiEndpoint.US
// }

export const client = ASCClientService.getClient();
export let currentUserId = null;

// promisified client connection
export const connectClient = async (userId, displayName, dispatch) => {

  const amityConnection = await axios
    .get(`${API_URL}api/teamchat/amityAuthentication/${userId}`)
    .then((res) => {
      const authToken = res.data;
      dispatch(sessionCreated(authToken));
      return new Promise((resolve) => {
        client.on('connectionStatusChanged', ({ newValue }) => {
          if (newValue === ConnectionStatus.Connected) {
            currentUserId = userId;
            resolve();
          }
        });
        client.registerSession({ userId, displayName, authToken });
      });
    });

  const errorInAmity = new Promise((resolve, reject) => {
    console.log('Triggering the refresh!');
    let id = setTimeout(() => {
      clearTimeout(id);
      if (currentUserId === null) {
        let reload = confirm(
          'There is an error in the app, do you want to refresh the page?'
        );
        if (reload) {
          location.reload();
        }
        reject();
      }
    }, 10000);
  });

  /** If amityConnection spend more than 10 seconds,
   * the second promise will check if the user is register
   * and will promp a message for refresh the page */
  return Promise.race([amityConnection, errorInAmity]);
};

export const updateAmityUser = async (newuser_info) => {
  const {
    thryv_staff_id: userId,
    display_name: displayName,
    avatar_url: avatarCustomUrl
  } = newuser_info;
  return await client.updateCurrentUser({
    userId,
    displayName,
    avatarCustomUrl,
    metadata: newuser_info
  });
};
