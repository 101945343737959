import React from "react";
import {Button, withModal} from "@thryvlabs/maverick";
import {ImageEditorWrapper} from "../../utils/ImageEditorWrapper";
import './index.css';

const ImageOptionsWrapper = ({ close, file, channelId, messageInputValue, sendMessage, resetState }) => {
    return (
        <>
            <h2 className='text-left'>Image options</h2>
            <p className='image-options-modal-body'>Please choose the next action.</p>
            <div className='d-flex justify-content-end'>
                <Button variant='primary' onClick={sendMessage}>Direct Upload</Button>
                <ImageEditorWrapper file={file}
                                    channelId={channelId}
                                    caption={messageInputValue}
                                    triggerName='Edit and Upload'
                                    closeAction={() => { close(); resetState(); }}/>
            </div>
        </>
    );
};
const ImageOptionsButton = ({ showModal }) => <Button variant='primary' onClick={showModal}>Image options</Button>;
/**
 * Image options modal HOC.
 */
export const ImageOptionsModal = withModal(ImageOptionsButton, ImageOptionsWrapper);