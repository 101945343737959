import React, { useState } from "react";
import { httpPost } from "../../helpers/http_helpers";
import { ChannelRepository } from "@amityco/js-sdk";
import {
  Icon,
  Modal,
  Toggle,
  ModalTitle,
  ParagraphText,
  Input,
} from "@thryvlabs/maverick";
import { currentUserId } from "../../ascClient";
import { allowKey } from "../utils/utils";
import { getStorageItem } from "../../helpers/environment_helpers";
import useToast from "../../hooks/Toasts/useToast";
import { setDisplayChannelBrowser } from "../../redux/actions/channelBrowser";
import "./index.css";
import { useDispatch } from "react-redux";
import { uuid } from "../../helpers/id_helpers";

export default function AddChannelModal({
  setChannelId,
  CustomButtonComponent,
}) {
  const [isPrivate, setIsPrivate] = useState(false);
  const [textInput, setTextInput] = useState("");
  const [error, setError] = useState("");
  const [charsRemaining, setCharsRemaining] = useState(25);
  const { triggerToast } = useToast();
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setTextInput(event.target.value);
    setError("");
  };

  const handleKeyPress = ({
    target: { selectionEnd, value },
    key,
    keyCode,
    metaKey,
  }) => {
    // if all text is deleted at once, reset to 25
    if (selectionEnd === 0) setCharsRemaining(25);
    // cursor is at the start, the input has characters, you press backspace
    if (selectionEnd < 2 && keyCode === 8) {
      setCharsRemaining(25);
      return;
    }
    // pressing delete when cursor is left of the last character
    if (selectionEnd < value.length && keyCode === 46) {
      return setCharsRemaining((prevcharsRemaining) => prevcharsRemaining + 1);
    }
    // pressing delete when cursor is at the end of the last character
    if (selectionEnd >= value.length && keyCode === 46) {
      return;
    }
    // pressing delete when the input is empty
    if (keyCode === 46 && value.length === 0) {
      return;
    }
    if (allowKey(keyCode, metaKey)) {
      if (key !== "Backspace" && value.length <= 24) {
        setCharsRemaining((prevcharsRemaining) => prevcharsRemaining - 1);
      } else if (
        key === "Backspace" &&
        charsRemaining > -1 &&
        charsRemaining !== 25
      ) {
        setCharsRemaining((prevcharsRemaining) => prevcharsRemaining + 1);
      }
    }
  };

  const ChannelHandle = async (textInput) => {
    const channelRepo = new ChannelRepository();

    if (textInput.trim() === "") {
      return;
    }

    try {
      const channel_id = uuid();
      const thryv_id = getStorageItem("Thryv_Id");
      const thryv_staff_id = getStorageItem("Thryv_Staff_Id");

      const channel_info = {
        channel_id: channel_id,
        thryv_id: thryv_id,
        thryv_staff_id: thryv_staff_id,
        notifications: "",
        status: "",
        alias: textInput,
      };

      // FOR LOCAL ONLY
      if (!channel_info["thryv_id"])
        channel_info["thryv_id"] = "tohl6loedb6ws716";
      if (!channel_info["thryv_staff_id"])
        channel_info["thryv_staff_id"] = "gemvej6mq8y8ufsk";

      httpPost("newchannel", channel_info)
        .then((res) => {
          triggerToast("Channel Created.", "success");
          channelRepo.createChannel({
            channelId: channel_id,
            displayName: `${textInput}`,
            type: `${isPrivate ? "live" : "community"}`,
            userIds: [currentUserId],
          });
          setError("");
          //closing modal
          document.getElementsByClassName("bg-none border-none")[0].click();
        })
        .catch((err) => {
          setError(err.message);
          return;
        });

      setChannelId(channel_info.channel_id);
      dispatch(setDisplayChannelBrowser(false));
    } catch (e) {
      console.error(`Error from ChannelHandle Function ${e}`);
      triggerToast("Failed to Create Channel.", "failure");
    }
  };

  const resetForm = () => {
    setIsPrivate(false);
    setTextInput("");
    setError("");
    setCharsRemaining(25);
  };

  const errorHandler = (error) => {
    const alphaRegex = new RegExp(/select \* from `channel_aliases`/);
    if (alphaRegex.test(error)) {
      return "The characters you entered are not supported at this time.";
    }

    return error;
  };

  return (
    <Modal
      variant="default"
      title="Create new channel"
      btnText={
        !!CustomButtonComponent ? (
          CustomButtonComponent
        ) : (
          <Icon height={13} type={"regular"} variant={"circlePlus"} />
        )
      }
      btnType="text"
      btnLevel={1}
      footer
      footerPrimary="Create Channel"
      primaryAction={() => ChannelHandle(textInput)}
      primaryFooterVariant="dark"
      footerCancel
      cancelAction={() => resetForm()}
      onOpen={() => resetForm()}
      modalTestId="add-channel"
    >
      <ParagraphText
        variant="lg"
        color="thryv-neutral"
        className="modal-paragraph-text"
      >
        Organize your channels around a topic or business function—for example
        #sales.
      </ParagraphText>
      <div className={"mt-5 input-container"}>
        <Input
          id=""
          className="w-100 input"
          type="text"
          placeholder="Channel Name"
          name="channelName"
          withLabel
          labelType="floating"
          variant="default"
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          maxLength="25"
        />
        <></>
        <span className={`char-counter ${charsRemaining === 0 ? "red" : ""}`}>
          {charsRemaining}
        </span>
        {error.length > 0 && (
          <span style={{ color: "red" }}> {errorHandler(error)}</span>
        )}
      </div>
      <div className={"mt-5"}></div>
      <ModalTitle size="md" variant="subtitle">
        Make channel private?
        <div className={"float-end"}>
          <div className="flex items-center">
            <Toggle
              toggleSize="normal"
              onChange={setIsPrivate}
              toggled={isPrivate}
            />
          </div>
        </div>
      </ModalTitle>
      <ParagraphText variant="sm" color="thryv-neutral">
        Private channels are hidden from people who aren't invited
      </ParagraphText>
      <div className={"mt-5"}></div>
    </Modal>
  );
}
