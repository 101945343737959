import { SET_CLIENT_LIST } from '../actions/types';

const INITIAL_STATE = {
    clientList: []
};

export const ClientList = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SET_CLIENT_LIST:
            return {
                ...state,
                clientList: payload.clientList
            };
        default:
            return state;
    }
};
