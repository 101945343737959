import "./index.css";
import { Header, ParagraphText } from "@thryvlabs/maverick";
import { BeatPulseIconLarge } from "../../Icons";

export const NoReferences = () => {
  return (
    <div className="no-reference-container">
      <span className="pulse-icon">
        <BeatPulseIconLarge />
      </span>
      <Header fontWeight="semibold" variant="h6">
        No References
      </Header>
      <ParagraphText variant="sm" color="thryv-steel">
        No Thryv references found in this channel
      </ParagraphText>
    </div>
  );
};
