import {
  SET_BOTH_INPUT_MESSAGE_VALUE,
  SET_INPUT_MESSAGE_VALUE,
  SET_PLAIN_INPUT_MESSAGE_VALUE
} from "../actions/types";

const initState = {
  messageInputValue: '',
  plainMessageInputValue: ''
}

export const MessageInput = (state = initState, { type, payload }) => {  
  switch (type) {
    case SET_INPUT_MESSAGE_VALUE:
      return {
        ...state,
        messageInputValue: payload.messageInputValue
      }
    case SET_PLAIN_INPUT_MESSAGE_VALUE:
      return {
        ...state,
        messageInputValue: payload.plainMessageInputValue
      }
    case SET_BOTH_INPUT_MESSAGE_VALUE:
      return {
        ...state,
        messageInputValue: payload.messageInputValue,
        plainMessageInputValue: payload.plainMessageInputValue
      }
    default:
      return state
  }
}