import axios from 'axios';

export const getResource = async (resource, messageId) => {
  const authHeader = {
    headers: {
      Authorization: "Bearer " + process.env.REACT_APP_AMITY_ACCESS_TOKEN,
      accept: "application/json",
      ContentType: "application/x-www-form-urlencoded"
    }
  };
  const baseURL = `https://api.us.amity.co/api/v2`;
  const resources = {
    messageReactions: `/reactions?referenceId=${messageId}&referenceType=message`,
    users: '/users'
  };
  const { data } = await axios.get(baseURL + resources[resource], authHeader);
  return data;
}