import { useEffect, useState } from "react";
import { httpGet } from "../../helpers/http_helpers";
import { useDispatch, useSelector } from "react-redux";
import { setRefreshReferences } from "../../redux/actions/References";

export default function useChannelReferences(channelId) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [references, setReferences] = useState([]);
  const { refreshReferences } = useSelector((state) => state.ReferencesReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    httpGet(`getreferencesbyparentid/${channelId}`)
      .then((res) => {
        const references = res?.data?.getReferencesByParentId;
        if (references) {
          setReferences(references);
        } else {
          setError(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
      });
    return () => {
      dispatch(setRefreshReferences(false));
    };
  }, [channelId, refreshReferences]);

  return { references, loading, error };
}
