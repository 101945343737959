import React from 'react';
import Loader from 'react-loader-spinner';

const AuthLoader = () => (
  <>
    <div className="centerSpinner">
      <Loader
        className="loading-dots"
        type="ThreeDots"
        color="#FF5000"
        height={80}
        width={80}
      />
    </div>
  </>
);

export default AuthLoader;
