import React from "react";
import TextFormatter from "./TextFormater";

export function TextContent({ data: { text } }) {

  return (
      <div className="Text text-content-wrapper">
        <span className="d-inline" style={{whiteSpace: 'pre-wrap'}}>
          <TextFormatter>
            {text}
          </TextFormatter>
        </span>
      </div>
  );
}
