import { httpPost } from "../helpers/http_helpers";
import { formatMentions } from "../helpers/message_helpers";

export const vNotification = (
  thryvStaffId,
  activeUserDisplayName,
  channelDisplayName,
  messageInputValue,
  channelId = "",
  dirCode = null
) => {
  const authPayload = {
    access_token: localStorage.getItem("access_token"),
    thryv_id: localStorage.getItem("business_id"),
    activeUserDisplayName: activeUserDisplayName,
    channelDisplayName: channelDisplayName,
    thryv_staff_id: thryvStaffId,
    messageInputValue: formatMentions(messageInputValue),
    channel_id: channelId,
    dirCode: dirCode,
  };

  httpPost(`/vnotification`, authPayload)
    .then((res) => {})
    .catch((err) => {
      console.log(err);
    });
};
