import { ParagraphText as Text } from '@thryvlabs/maverick';
import { useHistory } from 'react-router-dom';
import { getHighlightedText } from '../../helpers/typography_helpers';

export default function SearchPopoverMessages({
  messageSearchResults,
  messageSearchLoading,
  messageSearchError,
  searchText,
  finishedTyping
}) {
  const history = useHistory();

  if (searchText === '' || searchText.trim() === '') return <Text variant='sm'>Search field empty.</Text>;

  if (messageSearchError) return <Text variant='sm'>Something went wrong.</Text>;

  if (messageSearchLoading || !finishedTyping) return <Text variant='sm'>Searching for messages...</Text>;

  const handleClick = (message) => {
    if (!message) return;
    let newChannelId = message?.channel?.channelId;
    history.push({ pathname: '/home/main/messages', state: { newChannelId } });
  };

  return (
    <>
      {messageSearchResults?.length > 0 ? (
        messageSearchResults.slice(0, 5).map((message) => (
          <div className='mb-2 search-popover-result' key={message.messageId} onClick={() => handleClick(message)}>
            <div className='flex flex-row pr-1 align-items-center'>
              <Text variant='reg'>{getHighlightedText(message.data.text, searchText)}</Text>
            </div>
            <Text variant='sm' className='pb-2'>
              <span className='font-bold'>{message.user.displayName} </span>
              {message?.channel && message?.channel?.type !== 'conversation' ? (
                <span>
                  in <span className='font-bold '>#{message.channel.displayName} </span>
                </span>
              ) : null}
              <span className='search-popover-item-date'>on {new Date(message.createdAt).toDateString()}</span>
            </Text>
          </div>
        ))
      ) : (
        <Text variant='sm'>No messages matched your search.</Text>
      )}
    </>
  );
}
