import { Dropdown } from "react-bootstrap";
import { useState } from "react";

const MobileTabsDropdown = ({handleTabChange}) => {

    const [buttonName, setButtonName] = useState("General");

    const onHandleChangeMobile = (index, name) => {
        handleTabChange(index);
        setButtonName(name); 
    }      


    return (
        <Dropdown>
            <Dropdown.Toggle variant="success" className="float-end" style={{backgroundColor: "transparent", border: "none", color: "black", boxShadow: "none", fontWeight: '500', fontFamily: 'sans-serif'}}id="dropdown-basic">
               {buttonName}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item onClick={() => onHandleChangeMobile(0, 'General')}>General</Dropdown.Item>
                <Dropdown.Item onClick={() => onHandleChangeMobile(1, 'Team Members')}>Team Members</Dropdown.Item>
                <Dropdown.Item onClick={() => onHandleChangeMobile(2, 'Subscription')}>Subscription</Dropdown.Item>
                {/* <Dropdown.Item onClick={() => onHandleChangeMobile(3, 'Messages Layout')}>Messages Layout</Dropdown.Item> */}
            </Dropdown.Menu>
        </Dropdown>
    )
  }

 export default MobileTabsDropdown; 