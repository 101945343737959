import {
  SET_MESSAGE_LIST_ELEMENT_REF,
  SET_PRIMARY_RXN_PICKER_STATUS
} from '../actions/types';

const INITIAL_STATE = {
  leftStacking: false,
  messageListElementRef: null,
  primaryRxnPickerStatus: false
};

export const messagesLayout = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_MESSAGE_LIST_ELEMENT_REF:
      return {
        ...state,
        messageListElementRef: payload
      };
    case SET_PRIMARY_RXN_PICKER_STATUS:
      return {
        ...state,
        primaryRxnPickerStatus: payload
      };
    default:
      return state;
  }
};
