import { FileType } from "@amityco/js-sdk";

import { ImageContent } from "./ImageContent";
import { FileContent } from "./FileContent";

/**
 * FileType constant components.
 */
export const MAPPING = {
    [FileType.Image]: ImageContent,
    [FileType.File]: FileContent,
};