import { Avatar, ParagraphText as Text, Header, withModal } from '@thryvlabs/maverick';
import { TeamMemberModal } from '../../components/TeamMemberModal';
import { getAvatarColorByName, getFirstAndLastName } from '../../components/utils/utils';
import { useSocketStateContext } from '../../contexts/SocketContext';

const TeamMember = ({ member, showModal }) => {
  const socketState = useSocketStateContext();

  return (
    <div className='flex flex-row results-team-member align-items-center' onClick={showModal}>
      <div className="icon-container">
        <Avatar
          className='mr-2 conversation-item-avatar'
          size='default'
          style={member.avatar_url ? {} : { backgroundColor: getAvatarColorByName(member.display_name) }}
          variant={member.avatar_url ? 'image' : 'name'}
          imageUrl={member.avatar_url}
          name={getFirstAndLastName(member.display_name)}
        />
        <div className={socketState.onlineUsers.includes(member['thryv_staff_id']) ? 'logged-in-results' : 'logged-out-results'}></div>
      </div>
        <Text variant='sm'>{member.display_name}</Text>

    </div>
  );
};

const TeamMemberWithModal = withModal(TeamMember, TeamMemberModal);

export default function TeamMembersResults({ teamMembers, searchText }) {

  const filteredTeamMembers =  teamMembers.filter((user) => user?.display_name.toLowerCase().trim().includes(searchText.toLowerCase().trim()))

  return (
    <div>
      <Header variant='h7' fontWeight='medium'>
        Team Members
      </Header>
      <div className='flex flex-row flex-wrap pb-2'>
        {filteredTeamMembers.length > 0 ? (
          filteredTeamMembers
            .map((user) => (
              <TeamMemberWithModal
                key={user?.id}
                modalProps={{ userId: user?.thryv_staff_id }}
                parentProps={{ member: user }}
              />
            ))
        ) : (
          <div className="search-results-container w-100">
            <Text variant='sm'>No team members matched your search.</Text>
          </div>
        )}
      </div>
    </div>
  );
}
