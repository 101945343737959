/* global pendo */
import React, { useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import Home from '../../pages/Home';
import ThryvCookies from '../ThryvCookies.png';
import { useDispatch, useSelector } from 'react-redux';
import { Toasts } from '@thryvlabs/maverick';
import ThryvOauth from '../../pages/ThryvOauth';
import { isLocalStorageAvailable } from '../../helpers/environment_helpers';
import ActivateTeamchat from '../../pages/ActivationFlow/ActivateTeamchat';
import UserValidation from '../../pages/ActivationFlow/UserValidation';

const AuthenticatedApp = () => {
  const { toastQueue } = useSelector((state) => state.Toasts);
  const localStorageAvailable = isLocalStorageAvailable();
  return (
    <div>
      {!localStorageAvailable ? (
        <div className='d-flex align-items-center justify-content-center'>
          <img
            style={{
              marginTop: '100px',
              width: '1000px'
            }}
            src={ThryvCookies}
            alt='thryv-cookies'
          ></img>
        </div>
      ) : (
        <Switch>
          <Route path='/home' component={Home} />
          <Route exact path='/oauth' component={ThryvOauth} />
          <Route exact path='/activate-teamchat' component={ActivateTeamchat} />
          <Route exact path='/uservalidation' component={UserValidation} />
          <Route
            path='/'
            component={() => {
              return (
                <></>
              );
            }}
          />
        </Switch>
      )}
      {toastQueue.length > 0 &&
        toastQueue.map((toast, i) => (
          <Toasts
            key={i}
            toastMessage={toast.toastMessage}
            toastType={toast.toastType}
            className='absolute bottom-0 w-50 z-100'
          />
        ))}
    </div>
  );
};

export default AuthenticatedApp;
