export const getHighlightedText = (text, highlight) => {
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <span>
      {' '}
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === highlight.toLowerCase()
              ? {
                  fontWeight: 'bold',
                  backgroundColor: '#FFEDE5',
                  borderRadius: '5px',
                  padding: '.2rem',
                  display: 'inline-block'
                }
              : {}
          }
        >
          {part}
        </span>
      ))}{' '}
    </span>
  );
};

export const getUrlParam = (url, param) => {
  let startIndex = url.indexOf(`${param}=`) + param.length + 1;
  let remaining = url.substring(startIndex);
  let endIndex = remaining.indexOf('?') > 0 ? remaining.indexOf('?') : remaining.indexOf('%3') > 0 ? remaining.indexOf('%3') : remaining.length;
  return remaining.substring(0, endIndex);
};
