import { Avatar, ParagraphText as Text, withModal } from '@thryvlabs/maverick';
import { TeamMemberModal } from '../TeamMemberModal';
import { getAvatarColorByName, getFirstAndLastName } from '../utils/utils';
import { useSocketStateContext } from "../../contexts/SocketContext";
import './index.css'

const PopoverTeamMember = ({ member, showModal }) => {
  const socketState = useSocketStateContext();

  return (
    <div key={member.id} className='flex flex-row p-1 cursor-pointer align-items-center' onClick={showModal}>
      <div className={'icon-container'}>
        <Avatar
          className='mr-2 search-avatar'
          size='default'
          style={member.avatar_url ? {} : { backgroundColor: getAvatarColorByName(member.display_name) }}
          variant={member.avatar_url ? 'image' : 'name'}
          imageUrl={member.avatar_url}
          name={getFirstAndLastName(member.display_name)}
        />
        <div className={socketState.onlineUsers.includes(member['thryv_staff_id']) ? 'logged-in-search' : 'logged-out-search'}></div>

      </div>
      <p className='font-semibold text-h9'>{member.display_name}</p>
    </div>
  );
};

const PopoverTeamMemberWithModal = withModal(PopoverTeamMember, TeamMemberModal);

export default function SearchPopoverTeamMembers({ teamMembers, teamMembersLoading, teamMembersError, searchText }) {
  if (teamMembersLoading) return <Text variant='sm'>Searching for Team Members...</Text>;

  if (teamMembersError) return <Text variant='sm'>Something went wrong.</Text>;

  return (
    <>
      {teamMembers.length > 0 ? (
        teamMembers
          .slice(0, 5)
          .map((member) => (
            <PopoverTeamMemberWithModal
              key={member?.id}
              parentProps={{ member }}
              modalProps={{ userId: member?.thryv_staff_id }}
            />
          ))
      ) : (
        <Text variant='sm'>No team members match your search.</Text>
      )}
    </>
  );
}
