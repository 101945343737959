import { ThryvTeamChatLogo } from "../../components/Icons";
import { TeamChatFeatures } from "./teamchat-features";
import ThryvTeamChat from "./TeamChat_ActivationPageAssets/ThryvTeamChat.gif";

export const ActivateScreen = ({ teamChatActivation }) => {
  return (
    <div className="activation-flow-parent-container">
      <div className="design-oval">
        <div className="left-container">
          <div className="teamChat-thryv-log">
            <ThryvTeamChatLogo />
            <div className="teamchat-header-text">
              Team Collaboration now in Thryv.
            </div>
            <div className="teamchat-sub-header">
              Conversations don’t have to stop even when your team is on the go
              with <b>TeamChat’s Mobile App</b>
            </div>
            <div className="start-chatting-btn">
              <button onClick={teamChatActivation}>Start Chatting</button>
            </div>
          </div>
        </div>
        <div className="right-gif">
          <img src={ThryvTeamChat} alt="thryv-teamchat-gif" />
        </div>
      </div>
      <div className="teamcht-tagline">
        TeamChat allows you to bring your team together no matter where they
        are, discuss projects and take action.
      </div>
      <div className="teamchat-feature">TEAMCHAT FEATURES AND BENEFITS:</div>
      <TeamChatFeatures />
    </div>
  );
};
