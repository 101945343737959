import { TOGGLE_OPEN_FLYOUT, TOGGLE_FLYOUT_ACTIVE_TAB } from "./types";

export const toggleOpenFlyout = (boolean) => ({
  type: TOGGLE_OPEN_FLYOUT,
  payload: boolean
});

export const toggleFlyoutActiveTab = (tabName) => ({
  type: TOGGLE_FLYOUT_ACTIVE_TAB,
  payload: tabName
})