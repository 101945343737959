export const dateTzFormatter = (date = new Date(), tzString = window.selectedTimeZone) => {
  const setDate = typeof date === "string" ? new Date(date) : date;

  if (date) {
    return new Date((setDate).toLocaleString("en-US", { timeZone: tzString }));
  }
  return null;
}

export const formatAMPM = date => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return `${hours}:${minutes} ${ampm}`;
}

export const getFirstAndLastName = displayName => {
  const displayNameList = displayName?.trim().split(/\s+/);
  const displayObj = {firstName: null, lastName: null};
  if (displayNameList?.length > 0) displayObj.firstName = displayNameList[0]
  if (displayNameList?.length > 1) displayObj.lastName = displayNameList[1]
  return displayObj
}

export const allowKey = (keyCode, metaKey) => {
  let allow = true;
  
  // calculator key or windows/mac key
  if (keyCode === 183 || metaKey) {
    allow = false;
  }
  // allow alphanumeric keys
  if (keyCode >= 46 && keyCode <= 90) {
    allow = true;
  }
  // allow numpad keys
  if (keyCode >= 96 && keyCode <= 105) {
    allow = true;
  } // disallow function keys
  if (keyCode >= 112 && keyCode <= 123) {
    allow = false;
  }  // https://www.cambiaresearch.com/articles/15/javascript-char-codes-key-codes
  if (keyCode >= 9 && keyCode < 46) {
    allow = false;
  } // numlock and scroll lock 
  if (keyCode === 144 || keyCode === 145) {
    allow = false;
  }
    // allow spaces
  if (keyCode === 32) {
    allow = true
  }
  return allow;
}

/** For maintaining static set of colors for initials Avatar background **/
const alphabetColorMap = {}
const backgroundColors = [
  'Orange',
  'Chocolate',
  'Coral',
  'CornFlowerBlue',
  'DodgerBlue',
  'DarkCyan',
  'LightCoral',
  'Grey',
  'YellowGreen',
  'MediumPurple',
];
for(let i = 65, j = 0; i <= 90; i++, j++) {
  if (j >= backgroundColors.length) j = 0
  alphabetColorMap[String.fromCharCode(i)] = backgroundColors[j]
}
export const getAvatarColorByName = name => alphabetColorMap[name?.toUpperCase()?.charAt(0) ?? 'A']

// check if teamchat is running in command center (in frame, not opened in new tab)
export const inCommandCenter = () => {
  return window.document?.location?.href === process.env.REACT_APP_COMMAND_CENTER_URL;
}

export const getEnvironment = () => {
  // Possible REACT_APP_ENV values: "production", "qa", "staging" (dev)
  return process.env?.REACT_APP_ENV === 'production' ? 'production' : process.env?.REACT_APP_ENV === 'qa' ? 'qa' : 'development'
}