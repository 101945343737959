import { Col } from "react-bootstrap";
import { FadeLoader } from "react-spinners";
import TeamChatLogo from "./TeamChat_ActivationPageAssets/TeamChat-Logo.png";

export const ActivationLoader = () => {
    
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  return (
    <>
      <Col xs={12}>
        <img
          className="logo-activation"
          src={TeamChatLogo}
          alt="teamchat-logo"
        />
      </Col>
      <Col xs={12}>
        <h3 className="activation-tag-line">Turn talk into action</h3>
      </Col>
      <Col xs={12}>
        <p className="activation-started">
          TeamChat is being activated right now!
        </p>
      </Col>
      <Col xs={12}>
        <FadeLoader color="red" loading="true" css={override} />
      </Col>
    </>
  );
};
