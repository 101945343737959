import React from 'react';
import reactStringReplace from "react-string-replace";
import { REFERENCE_ELEMENT_TYPE } from "../ReferencePanel";
import { currentUserId } from "../../ascClient";
import { DisplayNameWithModal } from "./index";
import { useDispatch } from 'react-redux';
import { ClientProfile } from "../ReferencePanel/ClientProfile";
import { setStatusForSidePanel } from "../../redux/actions/ReferencePanelSidePanel";
import { uuid } from "../../helpers/id_helpers";

const MENTION_REGEX = /([@|#]\[[^)]+\]\([^)]+\:\:[^)]+\:\:[^)]+\))/gm;
const displayNameRegex = /[@|#]\[([^)]+)\]/gm;
const hyperTextRegex = /(https?:\/\/[^\s]+)/g;
const configRegex = /\(([^)]+)\:\:([^)]+)\:\:([^)]+)\)/gm;
const mentionRegex = /([@|#]\[[^)]+\]\([^)]+\:\:[^)]+\:\:[^)]+\))/gm;
const emojiRegex = /([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2580-\u27BF]|\uD83E[\uDD10-\uDDFF])/g;

export default function TextFormatter({ children: text }) {
    const mentionsArray = text.match(MENTION_REGEX);
    const dispatch = useDispatch()

    const getMentionDetail = v => {
        const displayNameString = v.match(displayNameRegex)[0]
        const configString = v.match(configRegex)[0]
        const configArray = configString.substring(1, configString.length - 1).split('::')
        const details = configArray[0].split(',');
        return {
            displayName: displayNameString.substring(2, displayNameString.length - 1),
            details,
            type: configArray[1],
            link: configArray[2]
        }
    }

    const getDisplayNameContainer = (mentionedEntityDetail, isModal = false) => {
        const { details, type, displayName } = mentionedEntityDetail;
        const userId = details[0];
        if (type === REFERENCE_ELEMENT_TYPE.THRYV_USER) return <DisplayNameWithModal parentProps={{ liveUser: { displayName } }} modalProps={{ userId, user: { displayName } }} />
        else return displayName;
    }

    const handleMentionClick = ({ details, type, link }) => {
        switch (type) {
            case REFERENCE_ELEMENT_TYPE.CLIENT:
                const selectedClient = {
                    id: details[0],
                    business_uid: details[1],
                    first_name: details[2],
                    last_name: details[3],
                    email: details[4],
                }
                dispatch(setStatusForSidePanel({
                    isOpen: true,
                    ComponentToRender: ClientProfile,
                    props: { selectedClient }
                }))
                break
            case REFERENCE_ELEMENT_TYPE.THRYV_USER:
                // todo: Add logic when the mentioned user is clicked
                break
            default:
                window.open(link, '_blank')
                break
        }
    }


    let mergedFormattedText = text;

    if (mentionsArray?.length > 0) {
        mergedFormattedText = reactStringReplace(text, mentionRegex, (m, i) => {
            const mentionedEntityDetail = getMentionDetail(m)
            const id = uuid();
            return (<span key={id} className={currentUserId ? 'mentioned-content-self' : 'mentioned-content-others'}
                onClick={() => handleMentionClick(mentionedEntityDetail)}>
                {getDisplayNameContainer(mentionedEntityDetail)}</span>)
        })
    }

    /** Check for hyperlinks and update **/
    mergedFormattedText = reactStringReplace(mergedFormattedText, hyperTextRegex, (link, i) => {
        const id = uuid();
        return (<span key={id} className={currentUserId ? 'mentioned-content-self' : 'mentioned-content-others'}
            onClick={() => window.open(link, '_blank')}>
            {link}</span>)
    })

    /** Check for emojis and reconfigure **/
    mergedFormattedText = reactStringReplace(mergedFormattedText, emojiRegex,
        (emoji, i) => {
            const id = uuid();
            return <span className="emoji-config" key={id}>{emoji}</span>
        })

    return mergedFormattedText;
}
