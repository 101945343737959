import { SEARCH_SUBMITTED } from '../actions/types';

const initState = {
  teamMembers: [],
  channels: [],
  chats: [],
  messages: [],
  searchText: '',
};

export const Search = (state = initState, { type, payload }) => {
  switch (type) {
    case SEARCH_SUBMITTED:
      return {
        ...state,
        searchText: payload.searchText,
        messages: messagesAreValid(payload.searchText, payload.messageSearchResults) ? payload.messageSearchResults : null,
        teamMembers: payload.teamMembers,
        channels: payload.channels,
        chats: payload.chats
      };
    default:
      return state;
  }
};

const messagesAreValid = (searchText, messages) => {
  if (!messages || searchText === '' || searchText.trim() === '') {
    return false;
  }
  return messages.every(message => message?.data?.text.toLowerCase().includes(searchText.toLowerCase()))
}