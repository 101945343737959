import axios from 'axios';

export const updateUserRoleAmity = async (thryv_staff_id) => {
  console.log('Updating user role to super-moderator');
  if (!thryv_staff_id) return;

  const data = { userId: thryv_staff_id, roles: ['super-moderator'] };

  const config = {
    headers: {
      Authorization: 'Bearer ' + process.env.REACT_APP_AMITY_ACCESS_TOKEN,
      ContentType: 'application/json'
    }
  };

  const updateUserRoleInAmity = await axios.put(
    'https://api.us.amity.co/api/v2/users',
    data,
    config
  );

  return updateUserRoleInAmity.data ?? null;
};

export const updateAmityUser = async (
  userId,
  metadata,
  displayName = null,
  email = null
) => {
  if (!userId || !metadata) return;

  if (!displayName && !email) return;

  const data = { userId: userId, metadata: metadata };

  if (displayName) data['displayName'] = displayName;
  if (email) data.metadata['email'] = email;

  const config = {
    headers: {
      Authorization: 'Bearer ' + process.env.REACT_APP_AMITY_ACCESS_TOKEN,
      ContentType: 'application/json'
    }
  };

  const updatedUserRes = await axios.put(
    'https://api.us.amity.co/api/v2/users',
    data,
    config
  );

  return updatedUserRes.data ?? null;
};
