import { 
  DISPLAY_CHANNEL_BROWSER,
} from '../actions/types';

const INITIAL_STATE = {
  displayChannelBrowser: false
}

export const channelBrowser = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case DISPLAY_CHANNEL_BROWSER:
      return {
        ...state,
        displayChannelBrowser: payload
      };
    default:
      return state
  }
}