import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setDisplayChannelBrowser } from '../../redux/actions/channelBrowser';
import { Resizable } from 're-resizable';
import { ChannelList } from '../ChannelList';
import { ConversationList } from '../ConversationList';
import { Link } from 'react-router-dom';
import { Icon, Tooltip } from '@thryvlabs/maverick';
import { ChatAccordion } from '../utils/Accordion/Accordion';
import './index.css';
import CreateChannelModal from './CreateChannelModal';
import MessageSearch from '../MessageSearch';
import { isMobile } from 'react-responsive';
import useIsInFrame from '../../hooks/Layout/useIsInFrame';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { UserStatus } from '../../pages/AppHeader/UserStatus';
import { toggleOpenFlyout } from '../../redux/actions/Flyout';
import { useSocketStateContext } from '../../contexts/SocketContext';
import axios from 'axios';
import { getStorageItem, setStorageItem } from '../../helpers/environment_helpers';
import { API_URL } from '../../app.config';
import { useMediaQuery } from 'react-responsive'

export function ChannelBrowser({ userId, activeChannelId, setChannelId, onChange = () => {} }) {
  const dispatch = useDispatch();
  const { displayChannelBrowser } = useSelector((state) => state.channelBrowser);
  const [profileStatus, setProfileStatus] = useState('');
  const [statusAway, setStatusAway] = useState(false);
  const socketState = useSocketStateContext();
  const ActiveUserId = getStorageItem('ActiveUserId');
  const [showUserOptions, setShowUserOptions] = useState(false);
  const { in_command_center } = useSelector((state) => state.ActiveUser);

  const { statusFeature } = useFlags();

  let { isInFrame } = useIsInFrame();

  const MobileView = useMediaQuery({ query: '(min-width: 450px)' });

  const maxChar = 20;

  const openInNewTab = (url) => {
    window.open(`${process.env.REACT_APP_TEAM_CHAT_HOST}${in_command_center ? "?env=commandcenter" : ''}`, '_blank', 'noopener,noreferrer');
  };


  useEffect(() => {
    const onBodyClick = (e) => {
      const { classList: targetClassList } = e.target;
      if (displayChannelBrowser) {
        if (targetClassList.contains('ChatRoom') || targetClassList.contains('NewConversation')) {
          dispatch(setDisplayChannelBrowser(false));
        }
      }
    };
    document.body.addEventListener('click', onBodyClick);
    return () => document.body.removeEventListener('click', onBodyClick);
  }, [displayChannelBrowser, setDisplayChannelBrowser]);

  useEffect(() => {
    window.addEventListener('resize', function () {
      if (window.innerWidth > 750) {
        dispatch(setDisplayChannelBrowser(false));
      }
    });
  }, [dispatch, setDisplayChannelBrowser]);

  useEffect(() => {

    axios.get(`${API_URL}api/teamchat/getstatus/${ActiveUserId}`)
      .then((res) => {
         const away_status_db = res?.data?.convertedBufferObject?.away_status;
          if(away_status_db!=='undefined' && away_status_db!=='' && away_status_db!==null && away_status_db!==0){
              setStatusAway(true);
          };
      })
      .catch((error) => console.log("Error form the getting away_status", error))

  }, [])

  const AwayStatusOption = () => {

    const handleActiveStatus = () => {

      socketState.socket.emit("user_online", ActiveUserId);
      setStatusAway(false)
      setStorageItem("myawaystatus", 0);
  
      const statusPayload = {
        thryv_staff_id: ActiveUserId,
        away_status: "0"
      }
  
      axios.post(`${API_URL}api/teamchat/awaystatus`, statusPayload)
        .then(() => {})
        .catch((error) => console.log("Error form the handling active status", error))

    }

    const handleAwayStatus = () => {

      socketState.socket.emit("awayStatus", ActiveUserId);
      setStatusAway(true);
      setStorageItem("myawaystatus", 1);
  
      const statusPayload = {
        thryv_staff_id: ActiveUserId,
        away_status: "1"
      }
  
      axios.post(`${API_URL}api/teamchat/awaystatus`, statusPayload)
        .then(() => {})
        .catch((error) => console.log("Error form the handling away status", error))

    }

    return (
      <ul> 
        <li className='awayStatus'
          onClick={statusAway ? handleActiveStatus : handleAwayStatus}
        > 
        {statusAway ? 
          <>
            <div className='awayStatus' style={{marginLeft: '-20px'}}>
              <div className='loggedOut' />Away</div><div className='awayStatusButton'>Set yourself as 
              <b style={{ marginLeft: '5px' }}>active</b>
            </div>
          </>
          : 
          <>
            <div className='awayStatus' style={{marginLeft: '-20px'}}>
              <div className='loggedIn' />Active</div><div className='awayStatusButton'>Set yourself as 
              <b style={{ marginLeft: '5px' }}>away</b>
            </div>
          </>
        } 
        </li>
    </ul>
    )
  }

  return (
    <Resizable
      defaultSize={{ width: '15rem !important' }}
      className={`channel-browser-container ${displayChannelBrowser ? 'display' : ''}`}
    >
      <div className='ChannelBrowser'>
        {statusFeature && isInFrame && (
          <div className='profile-status-container'>
            <UserStatus
              afterStatusChange={() => setShowUserOptions((state) => !state)}
              profileStatus={profileStatus}
              setProfileStatus={setProfileStatus}
              altBtn={
                <div className='boxInsideThryv'>
                  {' '}
                  {profileStatus !== '' && profileStatus !== null && profileStatus !== 'undefined' ? (
                    profileStatus?.length > maxChar ? (
                      profileStatus?.substring(0, maxChar) + '...'
                    ) : (
                      profileStatus
                    )
                  ) : (
                    <span className='emoji-btn' style={{ fontSize: '15px' }}>
                      {' '}
                      😀 &nbsp; Update your status{' '}
                    </span>
                  )}
                </div>
              }
              awayStatusOptions = { AwayStatusOption() }
            />
          </div>
        )}
        {!isMobile && (
          <div className='search'>
            <MessageSearch setChannelId={setChannelId} />
          </div>
        )}
        <ChatAccordion
          Header={() => (
            <h6 className={'text-secondary font-weight-600 w-100 no-margin'}>
              Channels
              <span className={'float-end'}>
                <CreateChannelModal setChannelId={setChannelId} />
              </span>
            </h6>
          )}
          Content={() => <ChannelList activeChannelId={activeChannelId} onClick={onChange} />}
        />
        <ChatAccordion
          Header={() => (
            <h6 className={'text-secondary font-weight-600 w-100 no-margin'}>
              Chats
              <Link
                onClick={() => {
                  dispatch(setDisplayChannelBrowser(false));
                  dispatch(toggleOpenFlyout(false));
                }}
                to={`/home/main/new-conversation`}
                className='add-chat-link'
              >
                <button className={'float-end'} data-testid="add-chat">
                  <Icon height={13} type={'regular'} variant={'circlePlus'} />
                </button>
              </Link>
            </h6>
          )}
          Content={() => <ConversationList activeChannelId={activeChannelId} onClick={onChange} />}
        />
        <div className='settings-btn-placeholder'>
          <div className='settings-btn-container'>
            <Link to={`/home/teamChatSettings`}>
              <div className='settings-btn-outline'>
                <Icon type='regular' variant='cog' color='#FFF' />
              </div>
            </Link>

            {(isInFrame && MobileView) && (
              <div className='settings-btn-outline-pendo' onClick={openInNewTab}>
                <Tooltip
                  variant='top'
                  title='Open in new tab'
                  description='Use TeamChat in a new tab to recieve real time notifications & messages without ever interrupting your Thryv workflow'
                >
                  <Icon type='regular' variant='externalLink' color='#FFF' />
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </div>
    </Resizable>
  );
}
