import { useState } from 'react';
import './index.css';
import SearchNavigation from './SearchNavigation';
import SearchResults from './SearchResults';

export default function Search({ setChannelId }) {
  const [activeTab, setActiveTab] = useState('All');

  return (
    <div className='search-container'>
      <SearchNavigation setActiveTab={setActiveTab} />
      <SearchResults activeTab={activeTab} setChannelId={setChannelId} />
    </div>
  );
}
