import { SET_USER_LIST, SET_NEW_CONVERSATION_USERS } from '../actions/types';

const INITIAL_STATE = {
    userList: [],
    newConvoUsers: []
};

export const Conversations = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SET_USER_LIST:
            return {
                ...state,
                userList: payload.userList
            };
        case SET_NEW_CONVERSATION_USERS:
            return {
                ...state,
                newConvoUsers: payload.newConvoUsers
            };
        default:
            return state;
    }
};
