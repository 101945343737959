import React, { useState, useEffect, useRef } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { MessageRepository } from "@amityco/js-sdk";
import { Icon } from '@thryvlabs/maverick';
import { ReactionEmojiPicker } from "../ReactionEmojiPicker";
import "./index.css";
import {setPrimaryRxnPickerStatus} from "../../redux/actions/messagesLayout";

export function ReactionPicker({ 
  self, 
  messageId,
  setShowButtons,
  myReactions = [] 
}) {
  const { primaryRxnPickerStatus: open } = useSelector(s => s.messagesLayout);
  const dispatcher = useDispatch();
  const [reactionEmojiPicker, setReactionEmojiPicker] = useState(false);
  const [pickerPositionClassName, setPickerPositionClassName] = useState('above');
  const ref = useRef();
  // leftStacking is a boolean
  const { leftStacking } = useSelector((state) => state.messagesLayout);

  const builtInReactions = ['❤️', '😆', '😮', '😢', '😠', '👍'];

  useEffect(() => {
    const listener = (e) => {
      if (!ref?.current?.contains(e?.target) && open) {
        dispatcher(setPrimaryRxnPickerStatus(false));
        setShowButtons(false);
      }
    };
    document.addEventListener("click", listener);
    return () => {
      document.removeEventListener("click", listener);
    };
  }, [open]);

  const toggleBar = (e) => {
    e.preventDefault();
    e.stopPropagation();
    checkEmojiPickerVisibility();
    dispatcher(setPrimaryRxnPickerStatus(true));
  };

  const  checkVisible = (elm, threshold, mode) => {
    threshold = threshold || 0;
    mode = mode || 'visible';
    const rect = elm.current.getBoundingClientRect();
    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    const below = rect.bottom - threshold < 0;
    const above = rect.top - viewHeight + threshold >= 0;
    return mode === 'above' ? above : (mode === 'below' ? below : !above && !below);
  }

  const checkEmojiPickerVisibility = () => {
    checkVisible(ref, 300, 'above') ? setPickerPositionClassName('above') :
        setPickerPositionClassName('below');
  };

  const toggleReaction = (reactionName) => async (e) => {
    if (!open) return;

    e.preventDefault();
    e.stopPropagation();

    const action = myReactions.includes(reactionName)
      ? "removeReaction"
      : "addReaction";

    await MessageRepository[action]({
      messageId,
      reactionName,
    });
    dispatcher(setPrimaryRxnPickerStatus(false));
    setShowButtons(false);
  };

  return (
    <span ref={ref} className='ReactionPicker'>
      <Icon onClick={toggleBar} height={15} type="regular" variant="reaction" />
      {open && (
          <>
            {reactionEmojiPicker && 
            <div className={`ReactionEmojiPicker ${self && leftStacking ? '' : self} ${pickerPositionClassName}`}>
              <ReactionEmojiPicker toggle={setReactionEmojiPicker} toggleReaction={toggleReaction}/>
            </div>}
            <div className={`ReactionButtons  ${self && leftStacking ? '' : self} ${pickerPositionClassName}`}>
              {builtInReactions.map((icon, i) => (
                  <button
                      key={i}
                      className={`ReactionButton`}
                      onClick={toggleReaction(icon)}
                  >
                    {icon}
                  </button>
              ))}
              <button
                  className={`ReactionButton`}
                  onClick={() => setReactionEmojiPicker(!reactionEmojiPicker)}
              >
                <Icon type="regular" variant="plus" color="#898e9e"/>
              </button>
            </div>
          </>
      )}
    </span>
  );
}
