import React, { useState } from "react";
import { CaretDown } from "@thryvlabs/maverick";
import './index.css'

export const ChatAccordion = ({ Header, Content }) => {
  const [ open, setOpen ] = useState(true);

  return (
    <>
      <div 
        className="custom-accordion p-3 cursor-pointer w-100" 
        onClick={(e) => {
          if (e.target.parentNode.parentNode.nodeName === 'DIV') {
            setOpen(!open);
          }
        }}
      >
        <div>
          <CaretDown
            className={` fill-current m-auto -rotate-90 ${
              open && 'rotate-0'
            } transition-all duration-300 ease-in-out`}
          />
        </div>
        {Header()}
      </div>
      <div hidden={!open}>
        {Content()}
      </div>
    </>
  )
}