import { Pill, Icon } from "@thryvlabs/maverick";
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import useChannelUnreadCount from "../../hooks/UnreadCount/useChannelUnreadCount";
import { setDisplayChannelBrowser } from '../../redux/actions/channelBrowser';
import "./index.css";

export function ChannelItem({
  channelId,
  displayName,
  avatarFileId,
  lastActivity,
  memberCount,
  unreadCount,
  active,
  channelType,
  onClick = () => {}
}) {

  const history = useHistory();
  const dispatch = useDispatch();

  const { trueUnreadCount } = useChannelUnreadCount(channelId, unreadCount, "channel");

  const handleChannelItemClick = () => {
    dispatch(setDisplayChannelBrowser(false));
    onClick(channelId);
    history.push("/home/main/messages");
  };
  
  return (
    <div className={`channel-item ${active ? "active" : ""}`} onClick={handleChannelItemClick}>
      <>
        <p className={`w-100 ${active ? "" : "text-secondary"}`}>
          {channelType === "community" ? (
            "#"
          ) : (
            <>
              <Icon type="regular" variant="lock" style={{ width: "8px" }} />{" "}
            </>
          )}
          {displayName ?? channelId}
        </p>
        <Pill style={{ height: "22px" }} variant="primary" number={trueUnreadCount} />
      </>
    </div>
  );
}
