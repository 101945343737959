import { useEffect, useRef, useState } from 'react';
import { Input } from '@thryvlabs/maverick';
import './index.css';
import useMessageSearch from '../../hooks/Search/useMessageSearch';
import { useHistory } from 'react-router-dom';
import useActiveTeamMembers from '../../hooks/Users/useActiveTeamMembers';
import SearchPopover from './SearchPopover';
import { useDispatch } from 'react-redux';
import { searchSubmitted } from '../../redux/actions/Search';
import useUserChannels from '../../hooks/Channels/useUserChannels';
import useUserChats from '../../hooks/Channels/useUserChats';
import {
  getStorageItemObject,
  setStorageItemObject,
} from '../../helpers/environment_helpers';
import { setDisplayChannelBrowser } from '../../redux/actions/channelBrowser';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { toggleOpenFlyout } from '../../redux/actions/Flyout';

export default function MessageSearch({ setChannelId }) {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [finishedTyping, setFinishedTyping] = useState(false);
  const {
    search,
    loading: messageSearchLoading,
    error: messageSearchError,
    messageSearchResults,
  } = useMessageSearch();
  const {
    teamMembers,
    loading: teamMembersLoading,
    error: teamMembersError,
  } = useActiveTeamMembers();
  const { channels, channelsLoading, channelsError } = useUserChannels();
  const { chats, chatsLoading, chatsError } = useUserChats();
  const [searchText, setSearchText] = useState('');
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [showPopover, setShowPopover] = useState(true);
  const { searchFeature } = useFlags();

  useEffect(() => {
    if (searchText.length > 0 && !showPopover) setShowPopover(true);
    if (
      searchText.length > 0 &&
      finishedTyping === true &&
      searchText.trim() !== ''
    ) {
      if (searchFeature) {
        const query = {
          text: searchText,
        };
        search(query);
      }
    }
  }, [searchText, finishedTyping]);

  useEffect(() => {
    const listener = (e) => {
      // popover: keep-open is a data attribute in RecentSearches that keeps popover
      // open when user manually removes recent search items.
      if (
        !ref?.current?.contains(e?.target) &&
        e?.target?.dataset?.popover !== 'keep-open'
      )
        setIsInputFocused(false);
    };
    document.addEventListener('click', listener);
    return () => {
      document.removeEventListener('click', listener);
    };
  }, []);

  const handleChange = (e) => {
    // Delay message search until typing has been dormant for >=400ms
    clearTimeout(typingTimeout);
    setFinishedTyping(false);
    setSearchText(e.target.value);
    setTypingTimeout(setTimeout(() => setFinishedTyping(true), 400));
  };

  const submitSearch = () => {
    setShowPopover(false);
    clearTimeout(typingTimeout);
    saveRecentSearchesToStorage(searchText);
    dispatch(
      searchSubmitted({
        searchText,
        messageSearchResults,
        teamMembers,
        channels,
        chats,
      })
    );
    dispatch(setDisplayChannelBrowser(false));
    dispatch(toggleOpenFlyout(false));
    history.push('/home/main/search');
  };

  const saveRecentSearchesToStorage = (searchText) => {
    const recentSearches = getStorageItemObject('RecentSearches');
    if (
      searchText.length === 0 ||
      searchText.trim().length === 0 ||
      recentSearches?.includes(searchText)
    )
      return;
    if (!recentSearches) {
      setStorageItemObject('RecentSearches', [searchText]);
    } else {
      let updatedSearches = recentSearches.includes(searchText)
        ? recentSearches
        : [searchText, ...recentSearches];
      if (updatedSearches.length > 5) updatedSearches.pop();
      setStorageItemObject('RecentSearches', updatedSearches);
    }
  };

  const filteredTeamMembers =
    searchText.length > 0
      ? teamMembers.filter(
          (user) =>
            user?.display_name &&
            user?.display_name
              .toLowerCase()
              .trim()
              .includes(searchText.toLowerCase().trim())
        )
      : teamMembers;

  const filteredChannels =
    channels.length > 0
      ? channels.filter(
          (channel) =>
            channel?.displayName &&
            channel?.displayName
              .toLowerCase()
              .trim()
              .includes(searchText.toLowerCase().trim())
        )
      : channels;

  const filteredChats =
    chats.length > 0
      ? chats.filter(
          (chat) =>
            chat?.displayName &&
            chat?.displayName
              .toLowerCase()
              .trim()
              .includes(searchText.toLowerCase().trim()) &&
            chat.memberCount > 2
        )
      : chats;

  return (
    <div ref={ref} className="message-search">
      <Input
        className="search-input"
        value={searchText}
        type="text"
        placeholder="Jump To"
        name="search"
        onChange={handleChange}
        onFocus={() => setIsInputFocused(true)}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            submitSearch(searchText);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Escape') setShowPopover(false);
        }}
        variant="search-isolated"
        positioning="absolute"
      />
      {isInputFocused && showPopover ? (
        <SearchPopover
          searchText={searchText}
          finishedTyping={finishedTyping}
          messageSearchResults={messageSearchResults}
          messageSearchLoading={messageSearchLoading}
          messageSearchError={messageSearchError}
          teamMembers={filteredTeamMembers}
          teamMembersLoading={teamMembersLoading}
          teamMembersError={teamMembersError}
          channels={filteredChannels}
          channelsLoading={channelsLoading}
          channelsError={channelsError}
          chats={filteredChats}
          chatsLoading={chatsLoading}
          chatsError={chatsError}
          setChannelId={setChannelId}
        />
      ) : null}
    </div>
  );
}
