import { Header, ParagraphText as Text } from '@thryvlabs/maverick';
import { useHistory } from 'react-router-dom';

export default function ChatsResults({ chats, searchText, setChannelId }) {
  const history = useHistory();
  const filteredChats = chats?.length
    ? chats
        .filter((chat) =>  chat?.displayName.toLowerCase().trim().includes(searchText.toLowerCase().trim()) && chat.memberCount > 2)
        .slice(0, 10)
    : chats;

    const handleClick = (channelId) => {
      setChannelId(channelId)
      history.push('/home/main/messages');
    };

  return (
    <div className='mb-2'>
      <Header variant='h7' fontWeight='medium'>
        Chats
      </Header>
      <div className='search-results-container'>
        {filteredChats.length > 0 ? (
          filteredChats.map((chat) => {
            return (
              <div
                key={chat.channelId}
                className='mb-1 cursor-pointer'
                onClick={() => handleClick(chat.channelId)}
              >
                <p className='font-medium text-h9'>{chat.displayName}</p>
              </div>
            );
          })
        ) : (
          <Text variant='sm'>No chats matched your search.</Text>
        )}
      </div>
    </div>
  );
}
