import React from "react";
import './index.css'
import Picker from "emoji-picker-react";

export const ReactionEmojiPicker = ({ toggle, toggleReaction}) => {
    const onEmojiClick = (e, emojiObject) => {
        e.preventDefault();
        e.stopPropagation();
        toggleReaction(emojiObject.emoji)(e)
        toggle(false)
    }

    return (
        <div>
            <Picker onEmojiClick={onEmojiClick} disableAutoFocus={true} />
        </div>
    )
}