import MessagesResults from './MessagesResults';
import ChannelsResults from './ChannelsResults';
import ChatsResults from './ChatsResults';
import TeamMembersResults from './TeamMembersResults';
import { useSelector } from 'react-redux';
import useMessageSearch from '../../hooks/Search/useMessageSearch';
import { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

export default function SearchResults({ activeTab, setChannelId }) {
  const { messages, teamMembers, searchText, channels, chats } = useSelector((s) => s.Search);
  const { search, messageSearchResults, loading, error } = useMessageSearch();
  const { searchFeature } = useFlags();

  useEffect(() => {
    if (searchFeature) {
      if (!messages && searchText.trim() !== '') {
        search({ text: searchText });
      }
    }
  }, [messages, searchText]);

  const messagesAreValid = (searchText, messages) => {
    if (!messages || searchText === '' || searchText.trim() === '') {
      return false;
    }
    return messages.every((message) => message?.data?.text.toLowerCase().includes(searchText.toLowerCase()));
  };

  const componentsToDisplay = {
    Messages: (
      <MessagesResults
        key='messages'
        messages={messages ? messages : messagesAreValid(searchText, messageSearchResults) ? messageSearchResults : []}
        searchText={searchText}
        loading={loading}
        error={error}
      />
    ),
    Channels: <ChannelsResults channels={channels} searchText={searchText} setChannelId={setChannelId} key='channels' />,
    Chats: <ChatsResults chats={chats} searchText={searchText} setChannelId={setChannelId} key='chats' />,
    'Team Members': <TeamMembersResults key='team-members' teamMembers={teamMembers} searchText={searchText} />
  };

  const tabOptions = ['Team Members', 'Channels', 'Chats', 'Messages']

  const allTab = searchFeature ? (
    Object.values(componentsToDisplay)
  ) : (
    <>
      <ChannelsResults channels={channels} searchText={searchText} setChannelId={setChannelId} key='channels' />
      <ChatsResults chats={chats} searchText={searchText} setChannelId={setChannelId} key='chats' />
      <TeamMembersResults key='team-members' teamMembers={teamMembers} searchText={searchText} />
    </>
  );

  return (
    <div className='search-results'>
      {tabOptions.includes(activeTab) ? componentsToDisplay[activeTab] : allTab}
    </div>
  );
}
