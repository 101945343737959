import { TOGGLE_FLYOUT_ACTIVE_TAB, TOGGLE_OPEN_FLYOUT } from '../actions/types';

const INITIAL_STATE = {
  isFlyoutOpen: false,
  flyoutActiveTab: 'References'
};

export const Flyout = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TOGGLE_OPEN_FLYOUT:
      return {
        ...state,
        isFlyoutOpen: payload,
        flyoutActiveTab: state.isFlyoutOpen === false ? "Members" : state.flyoutActiveTab
      };
    case TOGGLE_FLYOUT_ACTIVE_TAB:
      return {
        ...state,
        flyoutActiveTab: payload
      };
    default:
      return state;
  }
};
