import React, { useEffect, useRef, useState } from 'react';
import { Avatar } from '@thryvlabs/maverick';
import { getAvatarColorByName, getFirstAndLastName } from '../../components/utils/utils';
import { useHistory } from 'react-router-dom';
import './index.css';
import { setStorageItem, getStorageItem} from '../../helpers/environment_helpers';
import { useSocketStateContext } from '../../contexts/SocketContext';
import { UserStatus } from './UserStatus';
import axios from 'axios';
import { API_URL } from '../../app.config';
import { useFlags } from 'launchdarkly-react-client-sdk';
import thryvLogo from '../ActivationFlow/TeamChat_ActivationPageAssets/TeamChat-Logo.png';


export const AppHeader = ({ currentUserInfo = {} }) => {
  const [showUserOptions, setShowUserOptions] = useState(false);
  const [statusAway, setStatusAway] = useState(false);
  const [profileStatus, setProfileStatus] = useState("");
  const socketState = useSocketStateContext();
  const userId = getStorageItem("ActiveUserId");
  const ActiveUserId = getStorageItem("ActiveUserId");


  const appHeaderRef = useRef(null);
  const history = useHistory();
  

  const maxChar = 20;

  const { statusFeature } = useFlags();


  useEffect(() => {
    const listener = (e) => {
      if (!appHeaderRef?.current?.contains(e?.target)) {
        setShowUserOptions(false);
      }
    };
    document.addEventListener('click', listener);
    return () => {
      document.removeEventListener('click', listener);
    };

  }, []);


  useEffect(() => {

    axios.get(`${API_URL}api/teamchat/getstatus/${ActiveUserId}`)
      .then((res) => {
          const away_status_db = res?.data?.convertedBufferObject?.away_status;
          if(away_status_db!=='undefined' && away_status_db!=='' && away_status_db!==null && away_status_db!==0){
              setStatusAway(true);
          };
      })
      .catch((error) => console.log("Error form the getting away_status", error))

  }, [])

  const handleAwayStatus = () => {
    socketState.socket.emit("awayStatus", userId);
    setStatusAway(true);
    setShowUserOptions(false);
    setStorageItem("myawaystatus", 1);
  

    const statusPayload = {
      thryv_staff_id: ActiveUserId,
      away_status: "1"
    }

    axios.post(`${API_URL}api/teamchat/awaystatus`, statusPayload)
      .then(() => {})
      .catch((error) => console.log("Error form the handling away status", error))

  }

  const handleActiveStatus = () => {
    socketState.socket.emit("user_online", userId);
    setShowUserOptions(false);
    setStatusAway(false)
    setStorageItem("myawaystatus", 0);


    const statusPayload = {
      thryv_staff_id: ActiveUserId,
      away_status: "0"
    }

    axios.post(`${API_URL}api/teamchat/awaystatus`, statusPayload)
      .then(() => {})
      .catch((error) => console.log("Error form the handling active status", error))
  }



  return (
    <div ref={appHeaderRef} className='app-header'>
      <div className='title'>
        <img src={thryvLogo} style={{height: '5rem'}} alt="thryv-logo"/>
      </div>
      <div className={`user-profile`}>
        <div className='avatar' onClick={() => setShowUserOptions(!showUserOptions)}>
         <div className="icon-container">
          <Avatar
            className={'ml-[10px]'}
            variant={currentUserInfo.photo ? 'image' : 'name'}
            size='small'
            style={currentUserInfo.photo ? {} : { backgroundColor: getAvatarColorByName(currentUserInfo.display_name) }}
            imageUrl={currentUserInfo?.photo}
            name={getFirstAndLastName(currentUserInfo.display_name)}
          /> 
          {getStorageItem("myawaystatus") == 0 ? <div className='online' /> : <div className='offline' />}
        </div>
        </div>
        <span className={'display-name'}>{currentUserInfo.display_name}</span>
      </div>
      {showUserOptions && (
        <div className='user-options'>
          <div className={'text-center mb-2'}>
            <div className={'d-flex flex-column justify-content-center align-items-center my-3'}>
              <div>
              <div className="icon-container">
                <Avatar
                  variant={currentUserInfo.photo ? 'image' : 'name'}
                  size='default'
                  style={
                    currentUserInfo.photo ? {} : { backgroundColor: getAvatarColorByName(currentUserInfo.display_name) }
                  }
                  imageUrl={currentUserInfo?.photo}
                  name={getFirstAndLastName(currentUserInfo.display_name)}
                />
                {getStorageItem("myawaystatus") == 0 ? <div className='UserPresenceOnline' /> : <div className='UserPresenceOffline' />} 
              </div>
              </div>
              <span className={'display-name mt-2'}>{currentUserInfo.display_name}</span>
              <div className={'client-email'}>
                {currentUserInfo.email && currentUserInfo.email !== 'null'
                  ? currentUserInfo.email
                  : 'No email available'}
              </div>
              {statusFeature && ( 
                <div style={{ marginTop: "10px" }}>
                  <ul>
                    <UserStatus
                      afterStatusChange={() => setShowUserOptions(state => !state)}
                      profileStatus={profileStatus} 
                      setProfileStatus={setProfileStatus}
                      altBtn= {
                        <div className='box'> {((profileStatus !== "" && profileStatus !== null && profileStatus !== "undefined") ? 
                          (((profileStatus?.length) > maxChar ? (profileStatus?.substring(0, maxChar) + '...') : (profileStatus))) 
                          : (<span className='emoji-btn' style={{ fontSize: "15px" }}> 😀 &nbsp; Update your status </span>))}
                          </div>
                        }
                    />
                  </ul>
                </div>
              )}
            </div>
          </div>
          <ul>
            <li
              onClick={statusAway ? handleActiveStatus : handleAwayStatus}
            >
              {statusAway ? <div>Set yourself as <b>active</b></div> : <div>Set yourself as <b>away</b></div>}

            </li>
          </ul>
          <ul>
            <li
              onClick={() => {
                history.push('/home/teamChatSettings');
                setShowUserOptions(false);
              }}
            >
              Settings
            </li>
          </ul>
        </div>
      )}

    </div>
  );
};
