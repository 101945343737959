export const NOTIFICATION_DB_VALUES = {
  off: "off",
  every: "every",
  daily: "daily",
};

export const BROWSER_NOTIFICATIONS = {
  off: "off",
  mention: "mention",
  all: "all",
};

export const EMAIL_NOTIFICATION_OPTIONS = [
  {
    name: "no email",
    value: "off",
  },
  {
    name: "daily email",
    value: "daily",
  },
  {
    name: "every Notification",
    value: "every",
  },
];

export const BROWSER_NOTIFICATION_OPTIONS = [
  {
    name: "None",
    value: "off",
  },
  {
    name: "Mention only",
    value: "mention",
  },
  {
    name: "All",
    value: "all",
  },
];

