import React, { useEffect, useState, useRef } from "react";
import { Mention, MentionsInput } from "react-mentions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { CardTitle, Avatar, Pill, Icon } from "@thryvlabs/maverick";
import {ChannelRepository, ChannelType, ChannelFilter} from "@amityco/js-sdk";
import { setDisplayChannelBrowser } from '../../redux/actions/channelBrowser';
import {getAvatarColorByName, getFirstAndLastName} from "../utils/utils";
import { httpGet } from "../../helpers/http_helpers";
import { getStorageItem } from "../../helpers/environment_helpers";
import useTotalUnreadCount from "../../hooks/UnreadCount/useTotalUnreadCount";
import { useSocketStateContext } from "../../contexts/SocketContext";
import './index.css';
import { setNewConvoUsers } from "../../redux/actions/Conversations";

export function NewConversation({ channelId, changeChannelId }) {
  const location = useLocation();
  const startingUser = location?.state?.user && location.state.user;
  const userList = useSelector(s => s.Conversations.userList);
  const [teamMemberMessageInput, setTeamMemberMessageInput] = useState('');
  const {totalUnreadCount} = useTotalUnreadCount();
  const ref = useRef();
  const dispatch = useDispatch();
  const { displayChannelBrowser } = useSelector(state => state.channelBrowser);

  const ActiveUser = getStorageItem("Thryv_Staff_Id");

  const socketState = useSocketStateContext();

  useEffect(() => {
    onTeamMemberMessageInputChangeHandler(null, startingUser ? `@[${startingUser?.display_name}](${startingUser?.thryv_staff_id}) ` : "")
  }, [startingUser])

  const cleanMentionText = (text) => {
    const arry = new Set(text.split("@").map(v => v?.trim()));
    let newValue = '';
    for (var a of arry) {
      const mention = a.slice(a.indexOf('['), a.indexOf(')')+1);
      newValue += mention ? '@'+mention : '';
    }
    return newValue;
  }

  const onTeamMemberMessageInputChangeHandler = (e, v) => {
    const arry = v.substring(1).split("@");
    let returnArr = [];
    for (var a of arry) {
      let obj = {};
      const value = a.substring(a.lastIndexOf("[") + 1, a.lastIndexOf("]"));
      const key = a.substring(a.lastIndexOf("(") + 1, a.lastIndexOf(")"));
      obj[`display_name`] = value;
      obj[`id`] = key;
      if (!returnArr.map((v) => v.id).includes(key)) returnArr.unshift(obj);
    }

    let newValue = '';
    if (v.includes('@') && v.includes('[')) {
      newValue = cleanMentionText(v);
    }

    const diff1 = v.replace(newValue, '');

    if (newValue && (diff1.includes('[') || (!v.startsWith('@') && v.includes('[')))) {
      setTeamMemberMessageInput(newValue);
    } else {
      setTeamMemberMessageInput(v);
    }
    if (v === null || v.trim() === '') {
      changeChannelId(null);
      return
    }

    dispatch(setNewConvoUsers(
        {newConvoUsers: getUniqueListById(returnArr, "id")}
    ));
    fetchAndSetChannelIfAvailable(getUniqueListById(returnArr, "id"));
  };

  const fetchAndSetChannelIfAvailable = (selectedUsers) => {
    if (userList.length > 0) {
      const thryv_staff_id = getStorageItem("Thryv_Staff_Id");
      const currentUser = {
        display_name: userList.find((v) => v.thryv_staff_id === thryv_staff_id)?.display_name,
        id: thryv_staff_id
      };
      const newConvoUsersList = getUniqueListById([currentUser, ...selectedUsers], "id");
      let userIdsForNewConvo = [];
      let displayNamesForNewConvo = [];

      for (let i = 0; i < newConvoUsersList.length; i++) {
        userIdsForNewConvo.push(newConvoUsersList[i]?.id);
        if (thryv_staff_id === newConvoUsersList[i]?.id && newConvoUsersList.length === 2) continue;
        displayNamesForNewConvo.unshift(newConvoUsersList[i]?.display_name);
      }

      // **TODO: Query channel based on metadata or display name keyword (Querying all channels for now)
      const liveChannel = ChannelRepository.queryChannels({
        types: [ChannelType.Conversation],
        // keyword: displayNamesForNewConvo[0],
        filter: ChannelFilter.Member,
        excludeTags: ["deleted"]
      });

      const resultList = liveChannel?.models?.filter(e => {
        return e?.metadata?.convoMembersDetailedList?.length === userIdsForNewConvo.length
            && e?.metadata?.convoMembersDetailedList?.map(v => v.thryv_staff_id)?.every(u => userIdsForNewConvo.includes(u));
      });
      console.log(resultList, resultList.length, ' -- Resulting after logical filtering which should only have 1 element');

      if (resultList.length === 1) changeChannelId(resultList[0].channelId);
      else changeChannelId(null);
    }
  }

  const conversationMembersList = userList
      .filter(i => i?.thryv_staff_id !== ActiveUser)
      .map(d => {
        return {
          id: d.thryv_staff_id,
          display: d.display_name,
          avatar_url: d.avatar_url
        };
      });

  const handleKeyUp = (e) => {
    const selectionStart = e.target.selectionStart;
    const lastCharacter = String.fromCharCode(e.target.value?.charCodeAt(selectionStart - 1));
    if (lastCharacter === "#") {
      // showModal()
    }
  };
  return (
    <div className={`NewConversation ${displayChannelBrowser ? 'overlay' : ''}`}>
      <div className="pb-2 users-icon-and-heading">
        <div 
          className="users-icon-container" 
          onClick={() => dispatch(setDisplayChannelBrowser(true))}
        >
          <Icon 
            className='icon-users' 
            type="solid" 
            variant="users" 
          />
          <Pill 
            className='icon-pill' 
            variant="primary" 
            number={totalUnreadCount} 
          />
        </div>
        <CardTitle variant="primary">Send a message</CardTitle>
      </div>
      <div className="chat-input-container-new-convo">
        <div className="row">
          <div className="py-2 col-10 border-top border-bottom">
            <MentionsInput
              className={"mentionWrapper bg-transparent"}
              autoComplete={"off"}
              singleLine={true}
              type={"text"}
              inputRef={ref}
              placeholder="To: team_member"
              value={teamMemberMessageInput}
              onKeyUp={handleKeyUp}
              onChange={onTeamMemberMessageInputChangeHandler}
            >
              <Mention
                trigger=""
                data={conversationMembersList}
                displayTransform={(id, display) => `  @${display}`}
                appendSpaceOnAdd={true}
                onAdd={() => {
                  ref.current.blur();
                }}
                renderSuggestion={(entry, search, highlightedDisplay, index, focused) => {
                  return (
                    <div className={`convo-members-suggestion-item`} key={entry?.id}>
                      <div className="icon-container">
                      <div className="avatar">
                        <Avatar
                          className={"ml-[10px]"}
                          variant={entry.avatar_url ? "image" : "name"}
                          size="small"
                          style={entry.avatar_url ? {} : {backgroundColor: getAvatarColorByName(entry.display)}}
                          imageUrl={entry.avatar_url}
                          name={getFirstAndLastName(entry.display)}
                        />
                        <div className={socketState.onlineUsers.includes(entry?.id) ? 'logged-in' : 'logged-out'}/>
                        </div>        
                      </div>
                      <span className={"display-name RefPanelLabelText"}>{entry.display}</span>
                    </div>
                  );
                }}
              />
            </MentionsInput>
          </div>
          <div className="py-2 col-2 Uploaders border-top border-bottom"/>
        </div>
      </div>
    </div>
  );
}

/** Utility functions **/
const isEmpty = (value) => !value || value.trim() === "";

export function getUniqueListById(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()].filter(
    (v) => !(isEmpty(v.id) || isEmpty(v.display_name))
  );
}
