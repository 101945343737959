import { Switch, Route, useHistory } from 'react-router-dom';
import LoginRedirectApp from './LoginRedirectApp';
import AuthRedirectHandler from './AuthRedirectHandler';
import Home from '../../pages/Home';
import { useEffect } from 'react';
import ThryvOauth from '../../pages/ThryvOauth';
import ActivateTeamchat from '../../pages/ActivationFlow/ActivateTeamchat';
import UserValidation from '../../pages/ActivationFlow/UserValidation';
import { useSelector } from 'react-redux';
import { isLocalStorageAvailable } from '../../helpers/environment_helpers';
import { Toasts } from '@thryvlabs/maverick';
import ThryvCookies from '../ThryvCookies.png';
import useInCommandCenter from '../../hooks/Environment/useInCommandCenter';
import { wasLoginRedirectAttempted } from '../../helpers/environment_helpers';
import LoginPopup from './LoginPopup';

const UnauthenticatedApp = () => {
  const history = useHistory();
  const vOauthUrl = `${process.env.REACT_APP_THRYV_URL}app/oauth/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}&state=some_state_data&scope=openid`;
  const { toastQueue } = useSelector((state) => state.Toasts);
  const localStorageAvailable = isLocalStorageAvailable();
  const { thryv_id, thryv_staff_id } = useSelector((state) => state.ActiveUser);
  const { inCommandCenter } = useInCommandCenter();
  const redirectAttempted = wasLoginRedirectAttempted();

  useEffect(() => {
    if (inCommandCenter) {
      if (!redirectAttempted){
        history.push('/login');
      } else {
        history.push('/loginpopup');
      }
    } 
     else if (!window.location.href.includes('code') && !thryv_id && !thryv_staff_id) {
      window.location.href = vOauthUrl;
    }
  }, [inCommandCenter]);

  return (
    <div>
      {!localStorageAvailable ? (
        <div className='d-flex align-items-center justify-content-center'>
          <img
            style={{
              marginTop: '100px',
              width: '1000px'
            }}
            src={ThryvCookies}
            alt='thryv-cookies'
          ></img>
        </div>
      ) : (
        <Switch>
          <Route path='/auth' component={AuthRedirectHandler} />
          <Route
            path='/login'
            component={() => <LoginRedirectApp />}
          />
          <Route path='/loginpopup' component={()=><LoginPopup/>} />
          <Route exact path='/oauth' component={ThryvOauth} />
          <Route exact path='/activate-teamchat' component={ActivateTeamchat} />
          <Route exact path='/uservalidation' component={UserValidation} />
          <Route path='/home' component={Home} />
        </Switch>
      )}
      {toastQueue.length > 0 &&
        toastQueue.map((toast, i) => (
          <Toasts
            key={i}
            toastMessage={toast.toastMessage}
            toastType={toast.toastType}
            className='absolute bottom-0 w-50 z-100'
          />
        ))}
    </div>
  );
};

export default UnauthenticatedApp;
