import { useSelector } from 'react-redux';
import { getStorageItem } from '../../helpers/environment_helpers';

export default function useInCommandCenter() {

  const reduxInCommandCenter = useSelector((state) => state.ActiveUser.in_command_center);
  const localStorageInCommandCenter = getStorageItem('inCommandCenter');
  const currentInCommandCenter = window.location?.href?.includes('env=commandcenter');

  const inCommandCenter = reduxInCommandCenter || localStorageInCommandCenter || currentInCommandCenter || false;

  return { inCommandCenter };
}
