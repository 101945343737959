import React, {useEffect, useRef, useState} from "react";
import './index.css';
import {Icon, Modal, Input} from "@thryvlabs/maverick";
import {FileRepository, MessageRepository} from "@amityco/js-sdk";
// import {Editor as AnnotateEditor} from "../ImageEditor";
import 'tui-image-editor/dist/tui-image-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import ImageEditor from '@toast-ui/react-image-editor';
import {useMediaQuery} from "react-responsive";
import {useSelector} from "react-redux";
import Loader from "react-loader-spinner";

export const ImageEditorWrapper = ({file, channelId, caption = 'Caption not available.', triggerName, closeAction}) => {
    const imageEditorWrapperRef = useRef();
    const [loader, setLoader] = useState(false);
    const [imageCaption, setImageCaption] = useState('');
    const { messageListElementRef } = useSelector(s => s.messagesLayout);

    function dataURLtoFile(dataUrl, filename) {
        try {
            let arr = dataUrl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = window.atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type:mime});
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    const sendAnnotatedImage = () => {
        try {
            setLoader(true);
            const encodedImageString = imageEditorWrapperRef.current.getInstance().toDataURL();
            const annotatedImageFile = dataURLtoFile(encodedImageString, 'annotatedImage.png')
            if (annotatedImageFile) {
                const newFIle = FileRepository.createFile({
                    file: annotatedImageFile,
                });
                const liveObject = MessageRepository.createImageMessage({
                    channelId,
                    imageId: newFIle.model.fileId,
                    caption: imageCaption,
                });
                liveObject.on('dataUpdated', () => {
                    messageListElementRef.current.scrollTop = messageListElementRef.current.scrollHeight
                    if (closeAction) closeAction();
                })
            }
        } catch(e) {
            console.error(e)
            setLoader(false);
        }
    }

    const computeModalProps = () => triggerName ?
        { btnText: triggerName, btnType: 'primary'} :
        {altBtn: <Icon className="message-btn" type="regular" height={12} variant="edit"/>}

    const whiteTheme = {
        "common.bisize.width": "0",
        "common.bisize.height": "0",
        "common.backgroundColor": "#fff",

        // header
        "header.backgroundImage": "none",
        "header.backgroundColor": "transparent",
        "header.border": "0px",

        // load button
        "loadButton.backgroundColor": "#fff",
        "loadButton.border": "1px solid #ddd",
        "loadButton.color": "#222",
        "loadButton.fontFamily": "'Noto Sans', sans-serif",
        "loadButton.fontSize": "12px",
        "loadButton.display": "none",

        // download button
        "downloadButton.backgroundColor": "#ff5000",
        "downloadButton.border": "1px solid #fdba3b",
        "downloadButton.color": "#fff",
        "downloadButton.fontFamily": "'Noto Sans', sans-serif",
        "downloadButton.display": "none",
        "downloadButton.fontSize": "12px",

        "menu.normalIcon.color": "#8a8a8a",
        "menu.activeIcon.color": "#555555",
        "menu.disabledIcon.color": "#434343",
        "menu.hoverIcon.color": "#e9e9e9",
        "menu.iconSize.width": "24px",
        "menu.iconSize.height": "24px",
        // submenu icons
        "submenu.normalIcon.color": "#8a8a8a",
        "submenu.activeIcon.color": "#555555",
        "submenu.iconSize.width": "32px",
        "submenu.iconSize.height": "32px",
        // submenu primary color
        "submenu.backgroundColor": "transparent",
        "submenu.partition.color": "#e5e5e5",
        // submenu labels
        "submenu.normalLabel.color": "#858585",
        "submenu.normalLabel.fontWeight": "normal",
        "submenu.activeLabel.color": "#000",
        "submenu.activeLabel.fontWeight": "normal",
        // checkbox style
        "checkbox.border": "1px solid #ccc",
        "checkbox.backgroundColor": "#fff",
        // rango style
        "range.pointer.color": "#333",
        "range.bar.color": "#ccc",
        "range.subbar.color": "#606060",
        "range.disabledPointer.color": "#d3d3d3",
        "range.disabledBar.color": "rgba(85,85,85,0.06)",
        "range.disabledSubbar.color": "rgba(51,51,51,0.2)",
        "range.value.color": "#000",
        "range.value.fontWeight": "normal",
        "range.value.fontSize": "11px",
        "range.value.border": "0",
        "range.value.backgroundColor": "#f5f5f5",
        "range.title.color": "#000",
        "range.title.fontWeight": "lighter",
        // colorpicker style
        "colorpicker.button.border": "0px",
        "colorpicker.title.color": "#000",
    };

    return (
      <Modal
        className="annotate-image-modal"
        variant='lg'
        title='Annotate Image'
        disableBackdropClick
        primaryDisabled
        {...computeModalProps()}
        footer
        action
        btnActionText='Send Image'
        footerCancel
        btnAction={sendAnnotatedImage}
        actionClose
      >
        <div>
          {loader ? (
            <Loader
              className='loading-dots'
              type='ThreeDots'
              color='#FF5000'
              height={80}
              width={80}
            />
          ) : (
            <>
              <div className='d-flex justify-content-center w-100'>
                <Input
                  value={imageCaption}
                  onChange={(e) => setImageCaption(e.target.value)}
                  className='image-caption-input'
                  type='text'
                  placeholder='Enter Image caption'
                  name='budget'
                  variant='input-isolated'
                />
              </div>
              <ImageEditorCmp
                imageEditorWrapperRef={imageEditorWrapperRef}
                theme={whiteTheme}
                file={file}
              />
            </>
          )}
        </div>
      </Modal>
    );
}

const ImageEditorCmp = ({imageEditorWrapperRef, file, theme}) => {
    const [width, setWidth] = useState(500);
    const editorContainer = useRef();
    const MobileView = useMediaQuery({ query: '(max-width: 700px)' });

    useEffect(() => {
        setWidth(editorContainer.current.offsetWidth);

        // Redirecting focus to input instead of the modal when selecting text option in image editor.
        const editorInstance = imageEditorWrapperRef.current.getInstance()
        const graphics = editorInstance._graphics;
        const canvas = graphics._canvas;
        const { onObjectAdded } = graphics._handler;
        canvas.off('object:added', onObjectAdded);
        canvas.on('object:added', (event) => {
            const { target } = event;
            if (target.type === 'i-text') {
                target.hiddenTextareaContainer = editorContainer.current;
            }
            onObjectAdded(event);
        });
    }, [])

    return (
        <div ref={editorContainer}>
            <ImageEditor
                ref={imageEditorWrapperRef}
                includeUI={{
                    loadImage: {
                        path: `${file.fileUrl}?size=medium`,
                        name: 'SampleImage',
                    },
                    menu: [
                        "crop",
                        "flip",
                        "rotate",
                        "draw",
                        "shape",
                        "icon",
                        "text"
                    ],
                    initMenu: 'draw',
                    uiSize: {
                        width: '100%',
                        height: MobileView ? '500px' : '720px',
                    },
                    theme: theme,
                    menuBarPosition: 'bottom',
                }}
                cssMaxHeight={500}
                cssMaxWidth={MobileView ? 250 : 500}
                selectionStyle={{
                    cornerSize: 20,
                    rotatingPointOffset: 70,
                }}
                usageStatistics={false}
            />
        </div>
    )
}