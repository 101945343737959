import "./index.css";
import React, { useEffect, useState, useRef } from "react";
import { Header, withModal } from "@thryvlabs/maverick";
import { ReferencePanel } from "../ReferencePanel";
import { useDispatch, useSelector } from "react-redux";
import { ChannelMembers } from "./ChannelMembers";
import { Navigation } from "@thryvlabs/maverick";
import { ReferenceList } from "./ReferenceList";
import { NoReferences } from "./NoReferences";
import { ChannelRepository, MemberFilter } from "@amityco/js-sdk";
import styled from "styled-components";
import useChannelReferences from "../../hooks/References/useChannelReferences";
import { toggleFlyoutActiveTab } from '../../redux/actions/Flyout';
import { httpGet } from '../../helpers/http_helpers';

export const Reference = ({ channelId, showModal }) => {
  const [channelMembers, setChannelMembers] = useState([]);
  const [channelType, setChannelType] = useState("Channel");
  const [channelCreatorStaffId, setChannelCreatorStaffId] = useState(0);
  const [isChannelDeleted, setIsChannelDeleted] = useState(false)
  const { references, loading: referencesLoading, error: referencesError } = useChannelReferences(channelId);
  const { flyoutActiveTab } = useSelector(state => state.Flyout)
  const dispatch = useDispatch();
  const collection = useRef();

  const tabs = [
    {
      name: `References (${references.length})`,
      alerts: 0
    },
    {
      name: `${channelType !== "conversation" ? "Team " : ""}Members (${isChannelDeleted ? 0 : channelMembers.length})`,
      alerts: 0
    }
  ];

  const channelTypeMap = {
    live: "Channel",
    community: "Channel",
    conversation: "Chat"
  };

  const handleTabChange = (activeTabIndex) => {
    activeTabIndex === 0 ? dispatch(toggleFlyoutActiveTab("References")) : dispatch(toggleFlyoutActiveTab("Members"));
  };

  useEffect(() => {
    httpGet(`getchannel/${channelId}`).then((res) => {
      if (res.data.getChannel?.length) {
        setChannelCreatorStaffId(res.data.getChannel[0]?.thryv_staff_id);
      }
    });
  }, [channelId]);

  useEffect(() => {
    const liveChannel = ChannelRepository.getChannel(channelId);
    if (liveChannel?.model) {
      setChannelType(liveChannel?.model?.type);
      setIsChannelDeleted(liveChannel?.model?.tags.includes('deleted'))
    }
  }, [channelId, channelType]);

  useEffect(() => {
    collection.current = ChannelRepository.queryMembers({
      channelId,
      memberships: [MemberFilter.Member]
    });
    collection.current.on("dataUpdated", (newModels) => {
      setChannelMembers(newModels.filter((member) => member?.userId !== "_admin_admin_teamchat" && member?.userId !== "_admin_teamchat prod-1649169462274d3cba9c-admin"));
    });

    if (collection.current?.models) {
      setChannelMembers(collection.current.models.filter((member) => member?.userId !== "_admin_admin_teamchat" && member?.userId !== "_admin_teamchat prod-1649169462274d3cba9c-admin"));
    }
    return () => collection.current.dispose();
  }, [channelId]);

  return (
    <>
      <div className={"reference-flyout"}>
        <Header fontWeight="semibold" variant="h6">
          {channelTypeMap[channelType]} Details
        </Header>
        <Navigation
          variant="tabs"
          linksArray={tabs}
          setSelectedOption={handleTabChange}
          selected={flyoutActiveTab === "References" ? 0 : 1}
          collapsible={false}
        />
        {flyoutActiveTab === "References" && (
          <>
            {(!references.length > 0 || referencesError) && <NoReferences />}
            {!referencesLoading && <ReferenceList referenceList={references} />}
          </>
        )}
        {flyoutActiveTab === "Members" && (
          <ChannelMembers channelType={channelType} channelId={channelId} channelMembers={channelMembers} channelCreatorStaffId={channelCreatorStaffId} isChannelDeleted={isChannelDeleted} />
        )}
      </div>
    </>
  );
};

const RefContainer = withModal(Reference, ReferencePanel);
export const ReferenceContainer = styled(RefContainer)`
  padding: 0;
`;
