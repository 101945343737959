import React from 'react';
import { ReactionPicker } from "../ReactionPicker";
import { Icon, Modal, ParagraphText } from '@thryvlabs/maverick';
import './index.css';
import {ImageEditorWrapper} from "../utils/ImageEditorWrapper";

const EditDeleteReact = ({ 
  self,
  type,
  editMessage, 
  deleteMessage, 
  messageId,
  myReactions,
  reactionsCount,
  channelId,
  setShowButtons,
  file,
}) => {
  const display = !self ? 'none' : '';
  const adjustYPosition = reactionsCount > 0 ? 'adjustYPosition' : '';

  function downloadResource(url, filename) {
    if (!filename) filename = url.split("\\").pop().split("/").pop();

    fetch(url, { mode: "cors" })
      .then((response) => response.blob())
      .then((blob) => {
        var a = document.createElement("a");
        a.download = filename;
        a.href =  window.URL.createObjectURL(blob);
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((e) => console.error(e));
  }

  return (
    <div className={`edit-delete-react ${adjustYPosition} z-index-2`}>
      <Modal
        variant="sm"
        title="Delete message"
        btnText={
          <Icon
            className="message-btn"
            style={{ display }}
            type="regular"
            height={12}
            variant="altTrash"
          />
        }
        btnType="text"
        btnLevel={1}
        footer
        action
        btnActionText="Delete"
        btnAction={deleteMessage}
        primaryClose={true}
        footerCancel
      >
        <ParagraphText variant="reg">Confirm to delete.</ParagraphText>
      </Modal>
      {type === "text" && (
        <Icon
          id="edit-btn"
          className="message-btn"
          style={{ display }}
          type="regular"
          height={12}
          variant="edit"
          onClick={editMessage}
        />
      )}
      {(type === "file" || type === "image") && (
          <>
              <Icon
                  id="edit-btn"
                  className="message-btn"
                  type="regular"
                  height={12}
                  variant="cloudDownload"
                  onClick={() => downloadResource(`${file.fileUrl}?size=full`, file.fileId)}
              />
              <ImageEditorWrapper file={file} channelId={channelId} />
          </>
      )}
      <ReactionPicker
        className="message-btn"
        self={self}
        messageId={messageId}
        myReactions={myReactions}
        reactionsCount={reactionsCount}
        setShowButtons={setShowButtons}
      />
    </div>
  );
}
export default EditDeleteReact;