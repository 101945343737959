import './index.css'
import React, {useEffect, useState} from 'react'
import { Button, Avatar, Header } from '@thryvlabs/maverick'
import { Link } from 'react-router-dom'
import {getAvatarColorByName, getFirstAndLastName} from '../utils/utils'
import { useSocketStateContext } from "../../contexts/SocketContext";
import axios from "axios";

export function TeamMemberModal(props) {
    const [userProps, setUserProps] = useState({});
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}api/teamchat/getuser/${props?.userId}`).then(res => setUserProps(res?.data?.getUser))
    }, [])
    const handleClick = () => {
        props.close();
    }
    const socketState = useSocketStateContext();
    return (
        <div className="TeamMemberModalContainer">
            <div className = "icon-container">
                <Avatar variant={userProps?.avatar_url ? 'image' : 'name'}
                        style={userProps?.avatar_url ? {} : {backgroundColor: getAvatarColorByName(userProps?.display_name)}}
                        imageUrl={userProps?.avatar_url}
                        name={getFirstAndLastName(userProps?.display_name)}
                        size="large" />
                <div className={socketState.onlineUsers.includes(props?.userId) ? 'logged-in-modal' : 'logged-out-modal'}></div>
            </div>
            <Header variant="h4" fontWeight="semibold" className="DisplayName"c>
                {userProps?.display_name}
            </Header>
            <Link to={{pathname: `/home/main/new-conversation`, state: {user: userProps}}}>
                <Button onClick={handleClick} variant="secondary">SEND MESSAGE</Button>
            </Link>
        </div>
    )
}