import { Avatar } from "@thryvlabs/maverick";
import {getAvatarColorByName, getFirstAndLastName} from "../../utils/utils";
import { TextContent } from "../../MessageItem/TextContent";
import { getStorageItem } from "../../../helpers/environment_helpers";
import { useSocketStateContext } from "../../../contexts/SocketContext";
import "./index.css";

export const ReferenceList = ({ referenceList }) => {
  const activeUser = getStorageItem("ActiveUserId");
  const socketState = useSocketStateContext();
  return (
    <div className={"reference-list-container no-padding"}>
      {referenceList.slice(0, 10).map((ref) => (
        <div className={`reference-item no-padding`} key={ref.references.id}>
          <div className="avatar">
            <div className='icon-container'>
              <Avatar
                className={"ml-[10px] RefClientListAvatar"}
                imageUrl={ref.users.avatar_url}
                variant={ref.users?.avatar_url ? "image" : "name"}
                style={(ref.users?.avatar_url 
                  ? {} 
                  : {backgroundColor: getAvatarColorByName(ref.users?.display_name)})}
                name={getFirstAndLastName(ref.users?.display_name)}
                size="default"
              />
              <div className={socketState.onlineUsers.includes(ref.users?.thryv_staff_id) ? 'logged-in-reference' : 'logged-out-reference'}></div>
            </div>
          </div>
          <span className={"display-name RefClientListLabelText"}>
            {activeUser === ref.users?.thryv_staff_id ? "You" : `${ref.users?.display_name}`}
          </span>
          <div className="client-email status text-utility-link-info">
            <TextContent data={{ text: ref.references.object }} />
          </div>
        </div>
      ))}
    </div>
  );
};
