import './index.css';
import { Modal, ParagraphText, Select, CardTitle } from '@thryvlabs/maverick';
import { useEffect, useState, useRef } from 'react';
import { ChannelRepository, MemberFilter } from '@amityco/js-sdk';
import { httpGet } from '../../helpers/http_helpers';
import { getStorageItem } from '../../helpers/environment_helpers';
import useToast from '../../hooks/Toasts/useToast';
import useInCommandCenter from '../../hooks/Environment/useInCommandCenter'

export default function InviteChannelMembersModal({ channelId, buttonText }) {
  const [channelName, setChannelName] = useState(channelId);
  const [userOptions, setUserOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [channelMembers, setChannelMembers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const { triggerToast } = useToast();

  const collection = useRef();
  const thryv_id = getStorageItem('Thryv_Id');
  const { inCommandCenter } = useInCommandCenter();

  useEffect(() => {
    const liveChannel = ChannelRepository.getChannel(channelId);
    if (liveChannel?.model?.displayName)
      setChannelName(liveChannel?.model?.displayName);
    return () => liveChannel.dispose();
  }, [channelId, channelName]);

  useEffect(() => {
    collection.current = ChannelRepository.queryMembers({
      channelId,
      memberships: [MemberFilter.Member],
    });
    collection.current.on('dataUpdated', (newModels) => {
      setChannelMembers(newModels);
    });

    if (collection.current?.models) {
      setChannelMembers(collection.current.models);
    }
    return () => collection.current.dispose();
  }, [channelId]);

  useEffect(() => {
    if (isOpen) {
      httpGet(`getallusers/${thryv_id}`).then((res) => {
        const users = res.data.getUsers
          .filter((user) => user?.status === 1 || inCommandCenter)
          .map((user) => {
            return { name: user.display_name, value: user.thryv_staff_id };
          });
        // If we know the channel members, list only the users that don't already belong to the channel
        let validOptions =
          channelMembers.length > 0
            ? users.filter(
                (option) =>
                  !channelMembers
                    .map((member) => member.userId)
                    .includes(option.value)
              )
            : users;
        setUserOptions(validOptions);
      });
    }
  }, [isOpen]);

  const addMember = async () => {
    const userIds = selectedOptions.map((user) => user.value);
    try {
      await ChannelRepository.addMembers({
        channelId: channelId,
        userIds: [...userIds],
      });
      // Attempt to assign roles
      try {
        await ChannelRepository.addRole({
          channelId: channelId,
          userIds: [...userIds],
          role: 'channel-moderator',
        });
      } catch (roleError) {
        console.error('Role assignment error:', roleError);
        triggerToast(
          'Members added, but assigning moderator role failed.',
          'warning'
        );
        // Optionally return here if you want to avoid setting selectedOptions to [] and success toast
        return;
      }
      setSelectedOptions([]);
      triggerToast('Team Member Added.', 'success');
    } catch (err) {
      console.error('Error adding members:', err);
      triggerToast('Adding Member Failed.', 'failure');
    }
  };

  return (
    <Modal
      variant="default"
      btnText={buttonText}
      btnType="text"
      btnLevel={1}
      footer
      footerPrimary="Add to Channel"
      primaryAction={() => addMember()}
      primaryClose={true}
      onOpen={() => setIsOpen(true)}
      onClose={() => {
        setSelectedOptions([]);
        setIsOpen(false);
      }}
      primaryFooterVariant="dark"
      footerCancel
      cancelAction={() => console.log('Canceled team member invite.')}
      primaryDisabled={selectedOptions.length === 0}
    >
      <div style={{ marginTop: '-36px' }}>
        <CardTitle variant="primary">Invite People to #{channelName}</CardTitle>
        <ParagraphText
          variant="lg"
          color="thryv-neutral"
          className="modal-paragraph-text"
        >
          Invite Team Members to Join #{channelName}
        </ParagraphText>
        <div className="invite-members-container">
          <Select
            options={
              userOptions.length > 0
                ? userOptions
                : [{ name: 'No Available Members', value: '' }]
            }
            selectLabel="Invite Members"
            width="full"
            multiselect
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            disabled={userOptions.length === 0} // Optionally disable the select if no options are available
          />
        </div>
      </div>
    </Modal>
  );
}
