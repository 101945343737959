import { Popover, ParagraphText as Text, Header } from '@thryvlabs/maverick';
import SearchPopoverTeamMembers from './SearchPopoverTeamMembers';
import SearchPopoverMessages from './SearchPopoverMessages';
import SearchPopoverChannels from './SearchPopoverChannels';
import SearchPopoverChats from './SearchPopoverChats';
import RecentSearches from './RecentSearches';
import { useFlags } from 'launchdarkly-react-client-sdk';

export default function SearchPopover({
  searchText,
  finishedTyping,
  messageSearchResults,
  messageSearchLoading,
  messageSearchError,
  teamMembers,
  teamMembersLoading,
  teamMembersError,
  channels,
  channelsLoading,
  channelsError,
  chats,
  chatsLoading,
  chatsError,
  setChannelId,
}) {
  const { searchFeature } = useFlags();

  if (searchText === '') {
    return (
      <Popover className="p-3 search-results-popover">
        <RecentSearches />
      </Popover>
    );
  }


  return (
    <Popover className="p-3 search-results-popover">
      <Text variant="sm" className="fst-italic">
        Press enter to view results.
      </Text>
      {searchFeature && (
        <>
          <Header
            variant="h8"
            fontWeight="bold"
            className="mt-3 search-popover-title"
          >
            Messages
          </Header>
          <SearchPopoverMessages
            messageSearchResults={messageSearchResults}
            messageSearchLoading={messageSearchLoading}
            messageSearchError={messageSearchError}
            searchText={searchText}
            finishedTyping={finishedTyping}
          />
        </>
      )}

      <Header
        variant="h8"
        fontWeight="bold"
        className="mt-3 search-popover-title"
      >
        Channels
      </Header>
      <SearchPopoverChannels
        channels={channels}
        channelsLoading={channelsLoading}
        channelsError={channelsError}
        searchText={searchText}
        setChannelId={setChannelId}
      />
      <Header
        variant="h8"
        fontWeight="bold"
        className="mt-3 search-popover-title"
      >
        Chats
      </Header>
      <SearchPopoverChats
        chats={chats}
        chatsLoading={chatsLoading}
        chatsError={chatsError}
        searchText={searchText}
        setChannelId={setChannelId}
      />
      <Header
        variant="h8"
        fontWeight="bold"
        className="mt-3 search-popover-title"
      >
        Team Members
      </Header>
      <SearchPopoverTeamMembers
        teamMembers={teamMembers}
        teamMembersLoading={teamMembersLoading}
        teamMembersError={teamMembersError}
        searchText={searchText}
      />
    </Popover>
  );
}
