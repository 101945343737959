import './index.css'
import {SideSheet} from "@thryvlabs/maverick";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setStatusForSidePanel} from "../../../redux/actions/ReferencePanelSidePanel";

export const ReferencePanelSideSheet = () => {
    const {isOpen, ComponentToRender, props} = useSelector(s => s.ReferencePanelReducer)
    const dispatch = useDispatch()
    return(
        <>
            <SideSheet className="ref-side-sheet-container" size="large" isOpen={isOpen} onClose={() => dispatch(setStatusForSidePanel({isOpen: false}))}>
                <div className="flex flex-col vh-100 gap-3">
                    {/*<CardTitle variant="primary" className={'display-name'}>Reference Panel</CardTitle>*/}
                    { ComponentToRender ? <ComponentToRender {...props}/> : null }
                </div>
            </SideSheet>
        </>
    )
}