import React from 'react';
import './index.css';
import { Flyout } from '@thryvlabs/maverick';
import { useSelector } from 'react-redux';
import { FlyoutCloseButton } from './ToggleFlyoutButtons';

export const ChatFlyout = ({ ChildComponent, parentProps }) => {
  const { isFlyoutOpen } = useSelector((state) => state.Flyout);
  return (
    <Flyout control open={isFlyoutOpen}>
      <FlyoutCloseButton />
      <ChildComponent parentProps={parentProps} />
    </Flyout>
  );
};
