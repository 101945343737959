import React, { useState, useRef, useEffect } from "react";
import { useSelector } from 'react-redux';
import { MessageType } from "@amityco/js-sdk";
import { currentUserId } from "../../ascClient";
import { Deleted } from "./Deleted";
import { NotSupported } from "./NotSupported";
import { TextContent } from "./TextContent";
import { ImageContent } from "./ImageContent";
import { FileContent } from "./FileContent";
import { ReactionBar } from "../ReactionBar";
import {dateTzFormatter, formatAMPM, getAvatarColorByName, getFirstAndLastName} from "../utils/utils";
import { MessageEditorRepository } from '@amityco/js-sdk';
import EditDeleteReact from './EditDeleteReact';
import { Avatar, withModal } from "@thryvlabs/maverick";
import { TeamMemberModal } from "../TeamMemberModal";
import { getResource } from "../../api/amityAPI";
import { useSocketStateContext } from "../../contexts/SocketContext";
import "./index.css";

const MAPPING = {
  [MessageType.Text]: TextContent,
  [MessageType.Image]: ImageContent,
  [MessageType.File]: FileContent,
};

const DisplayName = ({ showModal, liveUser, userId }) => {
  if (userId === currentUserId) {
    return '';
  }
 
  return (
    <span className={userId ? 'UserName' : ''} onClick={showModal}>
      {`${liveUser?.displayName} `}
    </span>
  );
}


export const DisplayNameWithModal = withModal(DisplayName, TeamMemberModal)

export function MessageItem({
  messageId,
  userId,
  type,
  data,
  metadata,
  fileId,
  isDeleted,
  createdAt,
  myReactions,
  reactionsCount,
  reactions,
  initialMessageOfTheDay,
  user: liveUser,
  editMessageInfo,
  file,
  channelId,
  isActiveChannelDeleted
}) {
  const [onDesktop, setOnDesktop] = useState(true);
  const [showButtons, setShowButtons] = useState(false);
  const [reactionsUsers, setReactionsUsers] = useState([]);
  const { primaryRxnPickerStatus } = useSelector(s => s.messagesLayout);
  const $contentDiv = useRef();
  const $messageWrapper = useRef();
  // leftStacking is a boolean
  const { leftStacking } = useSelector((state) => state.messagesLayout);
  const socketState = useSocketStateContext();
  
  useEffect(() => {
    if (reactionsCount < 1) return;
    (async () => {
      const { results } = await getResource('messageReactions', messageId);
      if (results.reactions.length) {
        setReactionsUsers(() => 
          results.reactions[0].reactors.map(reactor => {
            const { displayName } = results.users.find(user => user.userId === reactor.userId);
            return {
              ...reactor,
              displayName
            };
          })
        );
      }
    })();
  }, [reactionsCount]);

  useEffect(() => {
    if (window.innerWidth <= 425) {
      setOnDesktop(true);
    }

    const onResize = () => {
      if (window.innerWidth <= 425) {
        setOnDesktop(false);
      } else {
        setOnDesktop(true);
      }
    }
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [window.innerWidth, setOnDesktop]);

  useEffect(() => {
    const onBodyClick = (e) => {
      if (!onDesktop) {
        if (!$contentDiv?.current?.contains(e.target) || type === 'file') {
          setShowButtons(false);
        } else {
          setShowButtons(true)
        }
      }
    }
    document.body.addEventListener('click', onBodyClick);
    return () => document.body.removeEventListener('click', onBodyClick);
  }, [$contentDiv, type, onDesktop]);

  const MessageContent = isDeleted ? Deleted : MAPPING[type] ?? NotSupported;

  const deleteMessage = () => {
    const editor = new MessageEditorRepository(messageId);

    editor.delete().catch(error => {
      console.log('there was an error: ', error)
    });
  }
  const editMessage = () => {
    editMessageInfo({ data: data, messageId: messageId });
  }
  const DisplayName = ({ showModal }) => {
    if (userId === currentUserId) {
      return '';
    }
    return (
      <span className="UserName" onClick={showModal}>
        {`${liveUser.displayName}, `}
      </span>
    );
  }

  const DisplayNameWithModal = withModal(DisplayName, TeamMemberModal)
  // self is a CSS class used for styling messages on the right
  const self = userId === currentUserId ? 'self' : '';
  return (
    <>
      {initialMessageOfTheDay &&
        <div className={'DayMarker'}>
          <span>{initialMessageOfTheDay}</span>
        </div>}
      <div
        ref={$messageWrapper}
        className={`message-wrapper ${self && leftStacking ? '' : self} ${showButtons ? 'z-index-2': 'z-index-1'}`}
        onMouseEnter={() => {
          if (onDesktop && !primaryRxnPickerStatus) {
            setShowButtons(true);
          }
        }}
        onMouseLeave={() => {
          if (onDesktop && !primaryRxnPickerStatus) {
            setShowButtons(false);
          }
        }}
      >
        {(showButtons && !isActiveChannelDeleted)  &&
        <EditDeleteReact
          onDesktop={onDesktop}
          self={self}
          editMessage={editMessage}
          deleteMessage={deleteMessage}
          file={file}
          type={type}
          messageId={messageId}
          myReactions={myReactions}
          reactionsCount={reactionsCount}
          channelId={channelId}
          setShowButtons={setShowButtons}
        />}
        <div className={`MessageItem ${type} ${self} z-index-1`}>
          <div className="align-middle DisplayName">
            <span>
              <DisplayNameWithModal modalProps={{userId}} parentProps={{liveUser, userId}}/>
              <span title={createdAt.toLocaleString()}>{formatAMPM(dateTzFormatter(createdAt))}</span>
              <span className="text-secondary">{metadata?.isEdited ? ' (edited)' : ''}</span>
            </span>
          </div>
          <div className="content-metadata-box">
            <div 
              ref={$contentDiv} 
              className='Content'
              style={{ borderRadius: (self && leftStacking) && '15px 15px 15px 0' }}
            > 
              <div className="Avatar">
                <div className="icon-container">
                  <Avatar 
                    className="conversation-item-avatar" 
                    size="default"
                    style={liveUser.avatarCustomUrl ? {} : {backgroundColor: getAvatarColorByName(liveUser.displayName)}}
                    variant={liveUser.avatarCustomUrl ? 'image' : 'name'}
                    imageUrl={liveUser.avatarCustomUrl}
                    name={getFirstAndLastName(liveUser.displayName)} 
                  />
                  <div className={socketState.onlineUsers.includes(userId) ? 'logged-in-message' : 'logged-out-message'}></div>

                </div>
              </div>
              <MessageContent data={data} metadata={metadata} fileId={fileId} channelId={channelId}/>
            </div>
            <div className="Metadata">
              {reactionsCount > 0 && (
              <div className='reactions-container'>
                <ReactionBar
                  reactionsCount={reactionsCount} 
                  reactions={reactions}
                />
                <div className="reactions-senders-container">
                  {reactionsUsers.map(({ reactionName, displayName }, i) => 
                    <div key={i} className="reaction-sender">
                      <span className="reaction">{reactionName}</span>
                      <span className="sender">{displayName}</span>
                    </div>
                  )}
                </div>
              </div>)}
            </div>
          </div>
        </div>
        
      </div> 
    </>
  );
}
