import { useEffect, useState } from "react";

import { FileRepository } from "@amityco/js-sdk";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css';
import TextFormatter from "./TextFormater";

export function ImageContent({ data: { caption } = {}, fileId, channelId, data, metadata }) {
  const [fileUrl, setFileUrl] = useState();

  useEffect(() => {
    const file = fileId ? FileRepository.getFile(fileId) : null;
    if (file) {
      if (file.model) {
        setFileUrl(file.model.fileUrl);
      } else {
        file.once("dataUpdated", (model) => {
          setFileUrl(model.fileUrl);
        });
      }
    }
  }, [fileId]);

  if (!fileUrl) return null;

  const sizedUrl = fileUrl.startsWith("blob:")
    ? fileUrl
    : `${fileUrl}?size=medium`;

  return (
    <>
      <div className="Image overflow-hidden">
        <Zoom><img src={sizedUrl} alt={caption} /></Zoom>
      </div>
      {caption && (
        <div className="Text">
          <em>
            <TextFormatter>
              {caption}
            </TextFormatter>
          </em>
        </div>
      )}
    </>
  );
}
