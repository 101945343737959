import { combineReducers } from 'redux';
import { OAuth } from './OAuth';
import { MessageInput } from './MessageInput';
import { ReferencePanelReducer } from './ReferencePanelSidePanel';
import { unreadCount } from './unreadCount';
import { ReferencesReducer } from './References';
import { Toasts } from './Toasts';
import { channelBrowser } from './channelBrowser';
import { messagesLayout } from './messagesLayout';
import { AmitySession } from './AmitySession';
import { Search } from './Search';
import { Flyout } from './Flyout';
import { Conversations } from './Conversations';
import { ActiveUser } from './ActiveUser';
import { ClientList } from './ClientList';


const RootReducer = combineReducers({
  MessageInput,
  Conversations,
  ReferencePanelReducer,
  oauth: OAuth,
  unreadCount,
  ReferencesReducer,
  Toasts,
  messagesLayout,
  AmitySession,
  Search,
  channelBrowser,
  Flyout,
  ActiveUser,
  ClientList
});

export default RootReducer;
