import { setStorageItem } from '../../helpers/environment_helpers';
import axios from 'axios';
export const STARTFETCHING = 'FETCHING';
export const FETCHEDOAUTH = 'FETCHEDOAUTH';
export const FAILDFETCH = 'FAILDFETCH';

const startFetching = () => ({
  type: STARTFETCHING
});

export const fetchedOAuth = (data) => ({
  type: FETCHEDOAUTH,
  payload: {
    ...data
  }
});

const faildFetch = (error) => ({
  type: FAILDFETCH,
  payload: {
    error
  }
});

export const OAuth = (oauthData) => (dispatch) => {
  dispatch(startFetching());
  axios
    .post('https://api.thryv.com/oauth/token', oauthData)
    .then((response) => {
      const idTokenResponse = response.data.id_token;
      let idToken = idTokenResponse.split('.');
      // remove index of 0 and 2 within idToken array to isolate the string we want
      idToken.splice(0, 1);
      idToken.splice(1, 1);
      // decode ID token with base64
      const base64 = window.atob(idToken);
      // parse the decoded token to get business_id and email
      const decodedData = JSON.parse(base64);

      const responseAndDecodedData = {
        response: response.data,
        usersData: decodedData
      };

      setStorageItem('business_id', decodedData.business_id);
      setStorageItem('access_token', response.data.access_token);
      setStorageItem('OAuthRole', decodedData.role);
      setStorageItem('ActiveUserId', decodedData.sub);
      setStorageItem('business_name', decodedData.business_name);
      setStorageItem('UserEnv', decodedData.brand_host);

      // store response.data along with the decoded data to access business_id, and email in Redux Store
      dispatch(fetchedOAuth(responseAndDecodedData));
    })
    .catch((error) => {
      dispatch(faildFetch(error));
    });
};
