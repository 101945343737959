import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useInCommandCenter from '../Environment/useInCommandCenter';

export default function useTotalUnreadCount(props) {
  const [totalUnreadCount, setTotalUnreadCount] = useState(0);
  const { channelUnreadMap, chatUnreadMap } = useSelector((state) => state.unreadCount);
  const targetOrigin = process.env.REACT_APP_COMMAND_CENTER_URL;
  const { inCommandCenter } = useInCommandCenter();

  useEffect(() => {
    let channelUnreadCount = 0;
    let chatUnreadCount = 0;
    if (Object.values(channelUnreadMap)?.length > 0) {
      channelUnreadCount = Object.values(channelUnreadMap).reduce((prev, curr) => prev + curr);
    }
    if (Object.values(chatUnreadMap)?.length > 0) {
      chatUnreadCount = Object.values(chatUnreadMap).reduce((prev, curr) => prev + curr);
    }
    let total = channelUnreadCount + chatUnreadCount;

    // Only update teamchat count if value is new
    if (total !== totalUnreadCount) {
      setTotalUnreadCount(total);
    }

    // Only send postMessage if in frame
    if (inCommandCenter) {
      window.parent.postMessage({ unreadCount: total }, targetOrigin);
    }
  }, [channelUnreadMap, chatUnreadMap]);

  return { totalUnreadCount };
}