// UTILS PULLED FROM REACT-MENTIONS (NOT EXPOSED BY THE LIBRARY AFTER 3.0.2)
import invariant from 'invariant';

var emptyFn = function emptyFn() {}; 

export const mapPlainTextIndex = function mapPlainTextIndex(value, config, indexInPlainText) {
    var inMarkupCorrection = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'START';
  
    if (typeof indexInPlainText !== 'number') {
      return indexInPlainText;
    }
  
    var result;
  
    var textIteratee = function textIteratee(substr, index, substrPlainTextIndex) {
      if (result !== undefined) return;
  
      if (substrPlainTextIndex + substr.length >= indexInPlainText) {
        // found the corresponding position in the current plain text range
        result = index + indexInPlainText - substrPlainTextIndex;
      }
    };
  
    var markupIteratee = function markupIteratee(markup, index, mentionPlainTextIndex, id, display, childIndex, lastMentionEndIndex) {
      if (result !== undefined) return;
  
      if (mentionPlainTextIndex + display.length > indexInPlainText) {
        // found the corresponding position inside current match,
        // return the index of the first or after the last char of the matching markup
        // depending on whether the `inMarkupCorrection`
        if (inMarkupCorrection === 'NULL') {
          result = null;
        } else {
          result = index + (inMarkupCorrection === 'END' ? markup.length : 0);
        }
      }
    };
  
    iterateMentionsMarkup(value, config, markupIteratee, textIteratee); // when a mention is at the end of the value and we want to get the caret position
    // at the end of the string, result is undefined
  
    return result === undefined ? value.length : result;
  };

  var iterateMentionsMarkup = function iterateMentionsMarkup(value, config, markupIteratee) {
    var textIteratee = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : emptyFn;
    var regex = combineRegExps(config.map(function (c) {
      return c.regex;
    }));
    var accOffset = 2; // first is whole match, second is the for the capturing group of first regexp component
  
    var captureGroupOffsets = config.map(function (_ref) {
      var markup = _ref.markup;
      var result = accOffset; // + 1 is for the capturing group we add around each regexp component in combineRegExps
  
      accOffset += countPlaceholders(markup) + 1;
      return result;
    });
    var match;
    var start = 0;
    var currentPlainTextIndex = 0; // detect all mention markup occurrences in the value and iterate the matches
  
    while ((match = regex.exec(value)) !== null) {
      var offset = captureGroupOffsets.find(function (o) {
        return !!match[o];
      }); // eslint-disable-line no-loop-func
  
      var mentionChildIndex = captureGroupOffsets.indexOf(offset);
      var _config$mentionChildI = config[mentionChildIndex],
          markup = _config$mentionChildI.markup,
          displayTransform = _config$mentionChildI.displayTransform;
      var idPos = offset + findPositionOfCapturingGroup(markup, 'id');
      var displayPos = offset + findPositionOfCapturingGroup(markup, 'display');
      var id = match[idPos];
      var display = displayTransform(id, match[displayPos]);
      var substr = value.substring(start, match.index);
      textIteratee(substr, start, currentPlainTextIndex);
      currentPlainTextIndex += substr.length;
      markupIteratee(match[0], match.index, currentPlainTextIndex, id, display, mentionChildIndex, start);
      currentPlainTextIndex += display.length;
      start = regex.lastIndex;
    }
  
    if (start < value.length) {
      textIteratee(value.substring(start), start, currentPlainTextIndex);
    }
  };

  var combineRegExps = function combineRegExps(regExps) {
    var serializedRegexParser = /^\/(.+)\/(\w+)?$/;
    return new RegExp(regExps.map(function (regex) {
      var _serializedRegexParse = serializedRegexParser.exec(regex.toString()),
          _serializedRegexParse2 = _slicedToArray(_serializedRegexParse, 3),
          regexString = _serializedRegexParse2[1],
          regexFlags = _serializedRegexParse2[2];
  
      invariant(!regexFlags, "RegExp flags are not supported. Change /".concat(regexString, "/").concat(regexFlags, " into /").concat(regexString, "/"));
      return "(".concat(regexString, ")");
    }).join('|'), 'g');
  };
  
  var countPlaceholders = function countPlaceholders(markup) {
    var count = 0;
    if (markup.indexOf('__id__') >= 0) count++;
    if (markup.indexOf('__display__') >= 0) count++;
    return count;
  };

function iterableToArrayLimit(arr, i) {
    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = undefined;
  
    try {
      for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
        _arr.push(_s.value);
  
        if (i && _arr.length === i) break;
      }
    } catch (err) {
      _d = true;
      _e = err;
    } finally {
      try {
        if (!_n && _i["return"] != null) _i["return"]();
      } finally {
        if (_d) throw _e;
      }
    }
  
    return _arr;
  }

  function nonIterableRest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance");
  }

   function arrayWithHoles(arr) {
    if (Array.isArray(arr)) return arr;
  }
  function _slicedToArray(arr, i) {
    return arrayWithHoles(arr) || iterableToArrayLimit(arr, i) || nonIterableRest();
  }
  
  var countPlaceholders = function countPlaceholders(markup) {
    var count = 0;
    if (markup.indexOf('__id__') >= 0) count++;
    if (markup.indexOf('__display__') >= 0) count++;
    return count;
  };

  var PLACEHOLDERS = {
    id: '__id__',
    display: '__display__'
  };

  var findPositionOfCapturingGroup = function findPositionOfCapturingGroup(markup, parameterName) {
    invariant(parameterName === 'id' || parameterName === 'display', "Second arg must be either \"id\" or \"display\", got: \"".concat(parameterName, "\"")); // find positions of placeholders in the markup
  
    var indexDisplay = markup.indexOf(PLACEHOLDERS.display);
    var indexId = markup.indexOf(PLACEHOLDERS.id); // set indices to null if not found
  
    if (indexDisplay < 0) indexDisplay = null;
    if (indexId < 0) indexId = null; // markup must contain one of the mandatory placeholders
  
    invariant(indexDisplay !== null || indexId !== null, "The markup '".concat(markup, "' does not contain either of the placeholders '__id__' or '__display__'"));
  
    if (indexDisplay !== null && indexId !== null) {
      // both placeholders are used, return 0 or 1 depending on the position of the requested parameter
      return parameterName === 'id' && indexId <= indexDisplay || parameterName === 'display' && indexDisplay <= indexId ? 0 : 1;
    } // just one placeholder is being used, we'll use the captured string for both parameters
  
  
    return 0;
  };