import React, { useState, useRef, useEffect } from 'react'
import { Modal, ParagraphText } from '@thryvlabs/maverick';
import { Input } from '@thryvlabs/maverick';
import { SmilyIcon } from "../../components/Icons"
import Picker from "emoji-picker-react";
import { useSocketStateContext } from '../../contexts/SocketContext';
import { getStorageItem, setStorageItem } from '../../helpers/environment_helpers';
import "./index.css";
import axios from 'axios';
import { API_URL } from '../../app.config';
import useIsInFrame from '../../hooks/Layout/useIsInFrame';
import { updateUserStatus } from './status_helper';
	
const CUSTOM_STATUS_ICON = "💬";
const EMPTY_PROFILE_STATUS = { id: '',  value: ''}
const STATUS_OPTIONS = [
  {
    id: '🗓️',
    value: '🗓️ In a meeting'
  },
  {
    id: '🍔',
    value: '🍔 Lunch'
  },
  {
    id: '🤒',
    value: '🤒 Sick'
  },
  {
    id: '🌴',
    value: '🌴 Vacationing'
  }
];
const STATUS_OPTION_KEYS = STATUS_OPTIONS.map((option, i) => i);

export const UserStatus = ({ afterStatusChange, profileStatus, setProfileStatus, altBtn, awayStatusOptions }) => {
    const inputRef = useRef(null);
    const [newChangedStatus, setNewChangedStatus] = useState(EMPTY_PROFILE_STATUS);
    const [statusCleared, setStatusCleared] = useState(false);
    const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
    const [showPicker, setShowPicker] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    let { isInFrame } = useIsInFrame();
    const socketState = useSocketStateContext();
    const ActiveUserId = getStorageItem("ActiveUserId");
    const businessId = getStorageItem("business_id");
    const emojiRef = useRef(null);

    useEffect(() => {
      if (statusCleared) { onSaveUserStatus() }
    }, [statusCleared])

    useEffect(() => {
      let modal = document.getElementById('modal');
      if (modal) {
        const listener = (e) => {
          const child = e.target;
          if (typeof child.className === 'string') {
            const isPicker = child.className.includes('emoji') || child.className.includes('icn');
            !isPicker ? setShowPicker(false) : setShowPicker(true);
          }
        };
        modal.addEventListener("click", listener);
        return () => {
          modal.removeEventListener("click", listener);
        };
      }
    });

    useEffect(() => {
      axios
        .get(`${API_URL}api/teamchat/getstatus/${ActiveUserId}`)
        .then((res) => {
          const statusValue = res?.data?.convertedBufferObject?.status_value || EMPTY_PROFILE_STATUS.value;
          const statusIcon = res?.data?.convertedBufferObject?.status_icon || EMPTY_PROFILE_STATUS.icon;
          setSelectedOption(res?.data?.convertedBufferObject?.selected_option);
          setProfileStatus(statusValue);
          setNewChangedStatus((prev) => ({
            id: statusIcon,
            value: statusValue,
          }));
        })
        .catch((err) =>
          console.log("Error from setting getting the users status", err)
        );
    }, []);

    const renderStatusOption = (key, id, value) => {
      const parsedSelectedOption = parseInt(selectedOption) ?? selectedOption
      return (
        <ul key={key}>
          <li
            className={STATUS_OPTION_KEYS.includes(parsedSelectedOption) && parsedSelectedOption === key ? 'selected statusOptions' : 'statusOptions'}
            key={key}
            onClick={() => {
              setNewChangedStatus({ id, value });
              setSelectedOption(key);
              setSaveButtonEnabled(true);
            }}
          >
            {value}
          </li>
        </ul>
      );
    };

    const onSaveUserStatus = async () => {
      setSaveButtonEnabled(false);
      if (newChangedStatus.value?.trim() !== "") {
        const icon =
          newChangedStatus.id && newChangedStatus.id.trim() === ""
            ? CUSTOM_STATUS_ICON
            : newChangedStatus.id;
        setStorageItem("setStatusIconForSelf", icon);
        setStorageItem("setStatusValueForSelf", newChangedStatus.value);

        socketState.socket.emit("statusChanged", {
          userId: ActiveUserId,
          id: icon,
          value: newChangedStatus.value,
        });

        let newStatusValue = newChangedStatus.value;
        if (
          icon === CUSTOM_STATUS_ICON &&
          !newStatusValue.includes(CUSTOM_STATUS_ICON)
        ) {
          newStatusValue = `${newStatusValue}`;
        }

        let updatedSelectedOption = Object.values(STATUS_OPTIONS).map(option => option.value).includes(newStatusValue) ? selectedOption : -1;

        updateUserStatus(businessId, ActiveUserId, icon, newStatusValue, updatedSelectedOption);
        setNewChangedStatus({ value: newStatusValue, id: icon });
        setProfileStatus(newStatusValue);
      } else {
        socketState.socket.emit("statusChanged", {
          userId: ActiveUserId,
          ...EMPTY_PROFILE_STATUS,
        });

        axios
          .put(`${API_URL}api/teamchat/updatestatus`, {
            thryv_staff_id: ActiveUserId,
            changes: { status_icon: "", status_value: "", selected_option: -1 },
          })
          .then(() => {})
          .catch((err) => console.log("Error from clearning the status", err));
      }

      //Temp using the old-fashioned way!
      document.getElementsByClassName("bg-none border-none")[0].click();
      // closing the modal
      afterStatusChange();
    };

    const onEmojiClick = (e, emojiObject) => {
      setSaveButtonEnabled(true);
      const input = document.getElementsByClassName('status-input')[0].childNodes[1];
      const start = input.selectionStart;
      const end = input.selectionEnd;

     const newValue = replaceBetween(newChangedStatus.value, start, end, emojiObject.emoji)

      setNewChangedStatus((prevStatus) => ({
        id: CUSTOM_STATUS_ICON,
        value: newValue
      }));
      setShowPicker(false);
      input.focus();
    };

    const replaceBetween = (origin, startIndex, endIndex, insertion) => {
      return origin.substring(0, startIndex) + insertion + origin.substring(endIndex);
    }

    const emojiIconPicker = (e) => (
      <button onClick={() => setShowPicker((val) => !val)}>
      <SmilyIcon className="icon-emojis" />
      </button>
    );

    const handleOnChange = (event) => {
      setSaveButtonEnabled(true);
      if (isInFrame) { setProfileStatus(event.target.value) }
      
      setNewChangedStatus((prev) => ({
        ...prev,
        value: event.target.value,
        id: CUSTOM_STATUS_ICON
      }))
    }

    const onClearUserStatus = () => {
      setSelectedOption(-1);
      setNewChangedStatus(EMPTY_PROFILE_STATUS);
      setStorageItem("setStatusIconForSelf", "");
      setStorageItem("setStatusValueForSelf", "");
      setProfileStatus('');
      setStatusCleared(true);
      document.getElementsByClassName("bg-none border-none")[0].click();
    };

    return (
      <Modal
        id="statusModal"  
        variant="default"
        title="Set A Status"
        altBtn={altBtn}
        primaryClose={true}
        onClose={() => {setShowPicker(false)}}
      >
        <div>
          {showPicker && (
            <div
              className="emojiPicker"
              ref={emojiRef}
            >
              <Picker onEmojiClick={onEmojiClick} />
            </div>
          )}
          <Input
            ref={inputRef}
            className={"w-[1000px] status-input"}
            style={{ height: "30px", fontSize: "18px", marginLeft: "10px" }}
            value={newChangedStatus.value}
            placeholder="What's your status ? "
            withLabel
            labelType="static"
            variant="input-isolated"
            symbol={emojiIconPicker()}
            onChange={handleOnChange}
          />
            <div style={{ marginTop: "30px", marginLeft: "-25px" }}>
                {STATUS_OPTIONS.map((option, i) => {
                  return renderStatusOption(i, option.id, option.value);
                }
              )}
            </div>
            {isInFrame ? <hr /> : ""}
            {awayStatusOptions}
            <div className="footerButtonStyle">
              <button
                style={{
                  textDecoration: "underline",
                  color: "#808080",
                }}
                onClick={onClearUserStatus}
              >
                CLEAR
              </button>
              {saveButtonEnabled && (
                <button
                  style={{
                    color: "#3D5199",
                    fontFamily: "sans-serif",
                    fontWeight: "inherit",
                  }}
                  onClick={onSaveUserStatus}
                >
                  SAVE
                </button>

              )}
            </div>
        </div>
      </Modal>
    );
}


