import React, { useCallback, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import AuthLoader from './AuthLoader';
import { setStorageItem } from '../../helpers/environment_helpers';

const LoginRedirectApp = () => {
  const { loginWithRedirect } = useAuth0();
  const login = useCallback(async () => {
    await loginWithRedirect({
      authorizationParams: {
        prompt: 'none'
      }
    });
  }, [loginWithRedirect]);

  useEffect(() => {
    const loginRedirectAttemptedTime = new Date();
    setStorageItem('loginRedirectAttemptedTime', loginRedirectAttemptedTime);
    login();
  }, [login]);

  return <AuthLoader />;
};

export default LoginRedirectApp;
