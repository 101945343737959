import {
  ActivationActivityIcon,
  ActivationChannelIcon,
  ActivationDirectMessageIcon,
  ActivationEmojiIcon,
  ActivationFileIcon,
  ActivationGroupMessenging,
  ActivationMentionIcon,
  ActivationPhotosAndAnimationIcon,
} from "../../components/Icons";

export const TeamChatFeatures = () => {
  return (
    <div className="teamchat-features-icon">
      <div className="teamchat-features-icon-oval">
        <ActivationChannelIcon />
        <div className="feature-name">CHANNELS </div>
      </div>
      <div className="teamchat-features-icon-oval">
        <ActivationFileIcon />
        <div className="feature-name">FILE ATTACHMENTS </div>
      </div>
      <div className="teamchat-features-icon-oval">
        <ActivationDirectMessageIcon />
        <div className="feature-name">DIRECT MESSAGING </div>
      </div>
      <div className="teamchat-features-icon-oval">
        <ActivationGroupMessenging />
        <div className="feature-name">GROUP MESSAGING </div>
      </div>
      <div className="teamchat-features-icon-oval">
        <ActivationPhotosAndAnimationIcon />
        <div className="feature-name">PHOTOS & ANNOTATION </div>
      </div>
      <div className="teamchat-features-icon-oval">
        <ActivationActivityIcon />
        <div className="feature-name">ACTIVITY TAGS </div>
      </div>
      <div className="teamchat-features-icon-oval">
        <ActivationMentionIcon />
        <div className="feature-name">MENTIONS </div>
      </div>
      <div className="teamchat-features-icon-oval">
        <ActivationEmojiIcon />
        <div className="feature-name">EMOJIS </div>
      </div>
    </div>
  );
};
