import * as serviceWorker from '../serviceWorker';
import Thryv_Guy from '../components/thryv.png';
import { isMobile, isIOS } from "react-device-detect";
import { getStorageItem } from './environment_helpers';
import { sortByRecent, formatMentions, getMessageMentions } from './message_helpers';
import { BROWSER_NOTIFICATIONS } from '../components/TeamChatSettings/constants';

/**
 * Show user notifications in the browser
 * @param {text} userName
 * @param {text} notificationMessage
 * @param {text} channelName
 */
export const showBrowserNotification = async (message) => {
  const {data, file} = message
  const channel = message.channel.displayName || message.channelId;

  const sw = await serviceWorker.getRegistrator();
  let notificationText = formatMentions(data.text);
  
  if (file && file.fileUrl !== '') {
    notificationText = `Send ${file.type.charAt(0) === 'f'? 'a': 'an'} ${file.type}` 
  }

  if (sw) {
    sw.showNotification(channel, {
      body: `@${message.user.displayName}: ${notificationText}`,
      icon: Thryv_Guy,
    });
  }
};

export const displayBrowserNotification = async (newMessages) => {
  if (!areNotificationsEnable() || !isDeviceCompatible()) {
    console.log("notifications are disabled in this browser!");
    return;
  }

  const browserConfig = getStorageItem("desktop_notifications")
  const currentUserId = getStorageItem("userId") || getStorageItem("ActiveUserId");

  if (browserConfig === BROWSER_NOTIFICATIONS.off) {
    return;
  }

  if (newMessages.length === 0) {
    return;
  }

  const recentMessage = sortByRecent(newMessages);

  if (recentMessage && recentMessage.userId !== currentUserId) {
    if (browserConfig === BROWSER_NOTIFICATIONS.all) {
      return await showBrowserNotification(recentMessage);
    }

    const mentionIds = getMessageMentions(recentMessage);
    if (browserConfig === BROWSER_NOTIFICATIONS.mention &&
      mentionIds.includes(currentUserId)
    ) {
      return await showBrowserNotification(recentMessage);
    }
  }
}; 

/**
 * Check if browser notifications are enabled
 * @return {boolean}
 */
export const areNotificationsEnable = () => {
  if(!isDeviceCompatible()) return;
  return window?.Notification?.permission === "granted"
}

/**
 * Request permitions to the user for showing notifications
 */
 export const requestNotificationPermission = async () => {
  if(!isDeviceCompatible()) return;
     if ("Notification" in window) {
       await window.Notification.requestPermission();
     }
 };

export function  isDeviceCompatible () {
  if(isMobile && isIOS) return false;
  return true
}