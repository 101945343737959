import {
    SET_USER_LIST,
    SET_NEW_CONVERSATION_USERS
} from './types';

export const setUserList  = (data) => ({
    type: SET_USER_LIST,
    payload: { ...data }
});

export const setNewConvoUsers  = (data) => ({
    type: SET_NEW_CONVERSATION_USERS,
    payload: { ...data }
});