import { SET_REFRESH_REFERENCES } from "../actions/types";

const initState = {
  refreshReferences: false
};

export const ReferencesReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case SET_REFRESH_REFERENCES:
      return {
        ...state,
        refreshReferences: payload
      };
    default:
      return state;
  }
};
