import { SHOW_TOAST, HIDE_TOAST } from "../actions/types";

const initState = {
  toastMessage: "Changes Saved.",
  toastType: "success",
  toastQueue: []
};

export const Toasts = (state = initState, { type, payload }) => {
  switch (type) {
    case SHOW_TOAST:
      const newToast = { ...payload };
      return {
        ...state,
        toastMessage: payload.toastMessage,
        toastType: payload.toastType,
        toastQueue: [...state.toastQueue, newToast]
      };
    case HIDE_TOAST:
      return { ...state, toastQueue: state.toastQueue.slice(1) };
    default:
      return state;
  }
};
