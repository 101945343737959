import {
  SET_OAUTH_CREDENTIALS,
  SET_V_USER,
  SET_DESKTOP_NOTIFICATIONS,
  SET_RECURLY_PLAN,
  SET_IN_COMMAND_CENTER,
  SET_CC_STAFF_SYNC_SUCCESSFUL
} from '../actions/types';

const INITIAL_STATE = {
  thryv_id: undefined,
  thryv_staff_id: undefined,
  access_token: undefined,
  directory_code: null,
  desktop_notifications: 'off',
  recurly_plan: null,
  in_command_center: false,
  cc_staff_sync_successful: false,
  v_user: {
    business_uid: undefined,
    id: undefined,
    first_name: undefined,
    last_name: undefined,
    display_name: undefined,
    email: undefined,
    professional_title: undefined,
    active: undefined,
    deleted: undefined,
    invite_sent: undefined,
    created_at: undefined,
    updated_at: undefined,
    mobile_number: undefined,
    photo: undefined,
    role: undefined
  }
};

export const ActiveUser = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_OAUTH_CREDENTIALS:
      return {
        ...state,
        thryv_id: payload.thryv_id,
        thryv_staff_id: payload.thryv_staff_id,
        access_token: payload.access_token,
        directory_code: payload.directory_code || null
      };
    case SET_V_USER:
      return {
        ...state,
        v_user: {
          business_uid: payload.business_uid,
          id: payload.id,
          first_name: payload.first_name,
          last_name: payload.last_name,
          display_name: payload.display_name,
          email: payload.email,
          professional_title: payload.professional_title,
          active: payload.active,
          deleted: payload.deleted,
          invite_sent: payload.invite_sent,
          created_at: payload.created_at,
          updated_at: payload.updated_at,
          mobile_number: payload.mobile_number,
          photo: payload.photo,
          role: payload.role
        }
      };
    case SET_DESKTOP_NOTIFICATIONS:
      return {
        ...state,
        desktop_notifications: payload
      };
    case SET_RECURLY_PLAN:
      return {
        ...state,
        recurly_plan: payload
      };
    case SET_IN_COMMAND_CENTER:
      return {
        ...state,
        in_command_center: payload
      };
    case SET_CC_STAFF_SYNC_SUCCESSFUL:
      return {
        ...state,
        cc_staff_sync_successful: payload
      }
    default:
      return state;
  }
};
