import { useEffect, useState } from "react";
import useAccountActiveChannels from "../../../hooks/Channels/useAccountActiveChannels";
import { ChannelRepository } from "@amityco/js-sdk";
import useToast from "../../../hooks/Toasts/useToast";
import {
  Section,
  Modal,
  Button,
  ParagraphText as Text,
} from "@thryvlabs/maverick";
import { PopulatingChannelList } from "../PopulatingChannelList";
import { getStorageItem } from "../../../helpers/environment_helpers";
import { httpPost } from "../../../helpers/http_helpers"

export default function TeamMemberSettings({
  staffMembers,
  updatedSubscribed,
  setUpdatedSubscribed,
}) {
  const [selectedData, setSelectedData] = useState({});
  const [saveButtonToggled, setSaveButtonToggled] = useState(false);
  const [previousSelection, setPreviousSelection] = useState({});
  const [preSelectedChannelOptions, setPreSelectedChannelOptions] = useState([]);
  const [updatePreselectedChannelsReady, setUpdatePreselectedChannelsReady] = useState(false)
  const [uninvitedStaffMembers, setUninvitedStaffMembers] = useState([]);
  const { channels: accountChannels } = useAccountActiveChannels();
  const { triggerToast } = useToast();
  const thryvId = getStorageItem("Thryv_Id");
  const thryvAccessToken = getStorageItem("access_token");

 
  //Get the ID of the account owner
  const OAuthRole =localStorage.getItem("OAuthRole");
  let ownerId;
  if (OAuthRole === 'owner') {
    ownerId = getStorageItem('Thryv_Staff_Id');
  };

  useEffect(() => {
    const staff_call_body = {
      thryv_id: thryvId,
      access_token: thryvAccessToken,
    };
    httpPost("staff", staff_call_body)
      .then((res) => {
        const idsToDelete = staffMembers.map(function (elt) {
          return elt.email;
        });
        const univitedIds = res.data.data.staff.filter(function (elt) {
          return idsToDelete.indexOf(elt.email) === -1;
        });
        setUninvitedStaffMembers(univitedIds);
      })
      .catch((err) => console.log(err));
  }, []);

  const saveTeamMembers = () => {
    let selectedHasChannels, unselectedHasChannels;    
    
    for (const userId in selectedData) {
      const channelList = selectedData[userId];

      findSelectedChannels(channelList, userId).forEach(async (channel) => {
        if (channel) { 
          selectedHasChannels = true 
          try {
            ChannelRepository.addMembers({
                channelId: channel.value,
                userIds: [userId]
            });
          } catch(err) {
            console.log('Error Adding Member:', err)
          }
        }
      })

      findUnselectedChannels(channelList, userId).forEach(async (channel) => {
        if (channel) { 
          unselectedHasChannels = true 
          try {
           ChannelRepository.removeMembers({
            channelId: channel.value,
            userIds: [userId]
          })
          } catch(err) {
            console.log('Error Removing Member:', err)
          }
        }
      })
    }    
    if (selectedHasChannels && unselectedHasChannels) {
      triggerToast("Team Members Updated.", "success");
    } else if (selectedHasChannels) {
      triggerToast("Team Members Added.", "success");
    } else {
      triggerToast("Team Members Removed.", "success");
    }
    setSaveButtonToggled(false);
    setUpdatePreselectedChannelsReady(true);
    setPreviousSelection(selectedData);
  };

  const findSelectedChannels = (channelList, userId) => {
      if (Object.keys(previousSelection).includes(userId)) {
        return channelList.filter(channel => {
          return !previousSelection[userId].some( prev => channel.value === prev.value )
          })

      }
      return [];
    };

  const findUnselectedChannels = (channelList, userId) => {
    if (Object.keys(previousSelection).includes(userId)) {
      return previousSelection[userId].filter(prev => {
        return !channelList.some( channel => prev.value === channel.value)
      })
    }
    return [];
  };

  const invite = (staff_id) => {
    const updateState = updatedSubscribed ? false : true;
    var filtered = uninvitedStaffMembers.filter(function (el) {
      return el.id === staff_id;
    });
    const newuser_info = {
      thryv_id: filtered[0].business_uid,
      thryv_staff_id: filtered[0].id,
      role: filtered[0].role,
      notifications: 0,
      guest: 0,
      email: filtered[0].email,
      phone: filtered[0].mobile_number,
      timezone: null,
      status: 1,
      avatar_url: filtered[0].photo,
      display_name: filtered[0].display_name,
    };
    httpPost(`newuser`, newuser_info)
      .then((res) => {
        setUpdatedSubscribed(updateState);
        triggerToast("Team member added to TeamChat.", "success");
      })
      .catch((err) => {
        triggerToast("Failed to invite team member to TeamChat.", "failure");
        console.log(err);
      });
  };

  return (
    <div className="my-3 row">
      <div className="col">
        <Section subSize="md" title="Manage Members" titleVariant={"primary"}>
          {sortStaffMembers(staffMembers).map((staffMember) => {
            if (staffMember.thryv_staff_id !== ownerId) {
              return (
                <PopulatingChannelList
                  key={staffMember?.id}
                  staffMember={staffMember}
                  accountChannels={accountChannels}
                  setSelectedData={setSelectedData}
                  setSaveButtonToggled={setSaveButtonToggled}
                  setPreviousSelection={setPreviousSelection}
                  setPreSelectedChannelOptions={setPreSelectedChannelOptions}
                  updatePreselectedChannelsReady={updatePreselectedChannelsReady}
                  updatedSubscribed={updatedSubscribed}
                  setUpdatedSubscribed={setUpdatedSubscribed}
                />
              );
            }
          })}
          {uninvitedStaffMembers.map((v) => {
            return (
              <div
                key={v.id}
                className="d-inline-flex w-100 justify-content-start"
              >
                <div
                  style={{
                    minWidth: "200px",
                    width: "22%",
                  }}
                >
                  <span className="capitalize pointer-events-none font-open-sans text-label-text text-thryv-black-500">
                    Email address
                  </span>
                  <p className="font-normal text-thryv-black-500 font-open-sans text-form-text font-weight-600">
                    {v.email}
                  </p>
                </div>
                <div
                  style={{
                    margin: "15px",
                  }}
                >
                  <Modal
                    variant="default"
                    title={"Add User"}
                    btnText={"Add"}
                    btnType={"primary"}
                    footer
                    action
                    btnActionText="Yes"
                    btnAction={() => invite(v.id)}
                    primaryFooterVariant="dark"
                    footerCancel
                  >
                    <Text variant="reg">
                      Are you sure you want to add user?
                    </Text>
                  </Modal>
                </div>
              </div>
            );
          })}
          <div
            className="w-100 flex-end"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div>
              <Button
                variant={saveButtonToggled ? "primary" : "secondary"}
                disabled={!saveButtonToggled}
                onClick={saveTeamMembers}
              >
                Save
              </Button>
            </div>
          </div>
        </Section>
      </div>
    </div>
  );
}

const sortStaffMembers = (staffMembers) => {
  return staffMembers.sort((a, b) => {
    if (a.status === 1 && b.status === 1) {
      return new Date(a.updated_date) - new Date(b.updated_date);
    } else {
      return b.status - a.status;
    }
  });
};