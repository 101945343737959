import React, {useEffect, useState, useRef} from "react";
import './index.css';
import {Avatar, Input, Icon} from "@thryvlabs/maverick";
import Loader from "react-loader-spinner";
import {AddButton, REFERENCE_ELEMENT_TYPE, REFERENCE_ELEMENTS} from "../index";
import axios from "axios";
import {API_URL} from "../../../app.config";
import {BeatPulseIcon} from "../../Icons";
import {getAvatarColorByName} from "../../utils/utils";
import { useSelector } from 'react-redux';

export const ClientList = ({ closeModal, title, setActiveComponent, setSelectedClient}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [isTyping, setIsTyping] = useState(false)
    const typingTimeoutRef = useRef(null);
    const [resultClientList, setResultClientList] = useState([]);
    const [showRecents, setShowRecents] = useState(true);
    const [recents, setRecents] = useState([]);
    const {thryv_id, access_token, directory_code} = useSelector((state) => state.ActiveUser);
    const { clientList } = useSelector((state) => state.ClientList);

    useEffect(() => {
        const recentClients = JSON.parse(localStorage.getItem('recentClients'));
        setRecents(recentClients || []);
    }, [])

    useEffect(() => {
        if (searchTerm === null || searchTerm === '' || clientList.length === 0) {
            setResultClientList(clientList);
            setShowRecents(true);
            return
        }

        if (!isTyping && searchTerm.length > 1) {
            const requestPayload = {
                thryv_id: thryv_id,
                access_token: access_token,
                keyword: searchTerm,
                dirCode: directory_code,
                per_page: 100
            }

            let searchResults = []
            axios.post(`${API_URL}api/teamchat/clients/search_keyword`, requestPayload)
            .then(res => {
                searchResults = res?.data?.data?.clients;
            })

            const matches = clientList.filter(contact =>
                (contact.first_name && contact.last_name && `${contact?.first_name} ${contact?.last_name}`.toLowerCase().includes(searchTerm)) ||
                (contact.first_name && contact.first_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (contact.mobile_phone && contact.mobile_phone.includes(searchTerm)) ||
                (contact.normalized_phone && contact.normalized_phone.includes(searchTerm)) ||
                (contact.email && contact.email.toLowerCase().includes(searchTerm))
            );
    
            const finalRes = [...new Map([...searchResults, ...matches].map(obj => [obj.id, obj])).values()];
            setResultClientList(finalRes);
            setShowRecents(false);
        }
    }, [isTyping, searchTerm, clientList])

    const handleClientSelect= v => {
        setSelectedClient(v);
        setActiveComponent(REFERENCE_ELEMENTS.CLIENT_PROFILE);
        const recentClient = recents.find(e => e.id === v.id);
        if (recentClient) {
            recents.splice(recents.indexOf(recentClient), 1);
        }
        recents.unshift(v);
        if (recents.length > 3) {
            recents.pop();
        }
        setRecents(recents);
        localStorage.setItem('recentClients', JSON.stringify(recents))
    }
    const handleSearchInput = (e) => {
        setIsTyping(true);
        setSearchTerm(e.target.value?.trim()?.toLowerCase());

        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        typingTimeoutRef.current = setTimeout(() => {
            setIsTyping(false);
        }, 200);
    }

    return (
        <>
            <div className={'RefPanelTitleBar d-flex'}>
                <span className="title-text my-auto ml-2">
                    <span className={'px-2'}><BeatPulseIcon/></span>
                    {"Tag Client Activity"}
                    <span className={'px-2'}><BeatPulseIcon/></span>
                </span>
            </div>
            <div className={'reference-panel-container'}>
                <LabelText text={'Search Client by Name, Email or Phone'}/>
                <Input type="text" className={'w-100 mb-3'} onChange={handleSearchInput} placeholder="Search" name="search" variant="search-isolated"/>

                {showRecents &&
                    <>
                        <LabelText text={'Recents'}/>
                        <div className={'d-flex justify-content-start'}>
                            {recents?.map(v => {
                                return (
                                    <div className={`recent-item`} key={v.id} onClick={() => handleClientSelect(v)}>
                                        <div className="avatar">
                                            <Avatar variant="name" size="small"
                                                    style={{backgroundColor: getAvatarColorByName(v?.first_name)}}
                                                    name={{firstName: v?.first_name, lastName: v?.last_name}}/>
                                        </div>
                                        <span className={'display-name RefPanelLabelText'}>{`${v?.first_name ?? ''} ${v?.last_name ?? ''}`}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                }

                <LabelText text={'Results'}/>
                <div className={'list'}>
                    {resultClientList && !resultClientList.length ? <Loader className="loading-dots" type="ThreeDots" color="#FF5000" height={80} width={80}/> : resultClientList.map(v => {
                        return (
                            <div className={`result-item`} key={v.id} onClick={() => handleClientSelect(v)}>
                                <div className="avatar">
                                    <Avatar className={'RefClientListAvatar'} variant="name" size="default"
                                            style={{backgroundColor: getAvatarColorByName(v?.first_name)}}
                                            name={{firstName: v?.first_name, lastName: v?.last_name}}/>
                                </div>
                                <span className={'display-name RefClientListLabelText'}>{`${v?.first_name ?? ''} ${v?.last_name ?? ''}`}</span>
                                <div className="client-email status">{v?.email ?? 'No email'}</div>
                                <div className={'chevronRight'}>
                                    <div className={'d-inline-flex gap-3'}>
                                        <span className={'client-list-hover-add-button'}>
                                            <AddButton selectedValue={v} closeModal={closeModal} type={REFERENCE_ELEMENT_TYPE.CLIENT}/>
                                        </span>
                                        <span>
                                            <Icon type="regular" height={15} width={15} variant="chevronRight"/>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {/*<Button variant="primary" onClick={close}>*/}
                {/*    Close*/}
                {/*</Button>*/}
            </div>
        </>
    )
}

const LabelText = ({text}) => {
    return (
        <div className={'d-flex justify-content-start mt-2'}>
            <span className='RefPanelLabelText RefLabelColor'>{text}</span>
        </div>
    )
}