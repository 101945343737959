import React, { useRef, useState, useEffect } from 'react';
import {
  ChannelRepository,
  ChannelFilter,
  ChannelType,
  ChannelSortingMethod,
} from '@amityco/js-sdk';
import { ChannelItem } from '../ChannelItem';
import './index.css';
import { Button } from '@thryvlabs/maverick';

export function ChannelList({ activeChannelId, onClick = () => {} }) {
  const [channels, setChannels] = useState([]);
  const [showViewMore, setShowViewMore] = useState(false);
  const [top, setTop] = useState(true);
  const collection = useRef();

  useEffect(() => {
    collection.current = ChannelRepository.queryChannels({
      types: [ChannelType.Standard, ChannelType.Community, ChannelType.Live],
      filter: ChannelFilter.Member,
      excludeTags: ['deleted'],
      sortBy: ChannelSortingMethod.FirstCreated,
    });

    collection.current.on('loadingStatusChanged', () => {
      setShowViewMore(collection.current.hasMore);
    });

    collection.current.on('dataUpdated', (models) => {
      setChannels(models);
    });

    setChannels(collection.current.models);

    return () => collection.current.dispose();
  }, []);

  const onScroll = (e) => {
    setTop(e.target.scrollTop === 0);
    if (!collection.current.hasMore) return;
    const visibleHeight = e.target.scrollHeight - e.target.clientHeight;

    if (e.target.scrollTop >= visibleHeight - 1) {
      collection.current?.nextPage();
    }
  };

  const handleViewMoreChannels = () => {
    if (collection.current.nextPage) {
      collection.current.nextPage();
    }
  };

  return (
    <div className={`ChannelList ${top ? 'top' : ''}`} onScroll={onScroll}>
      {channels.map((channel) => (
        <ChannelItem
          key={channel.channelId}
          {...channel}
          active={channel.channelId === activeChannelId}
          onClick={onClick}
          channelType={channel.type}
        />
      ))}
      {showViewMore ? (
        <Button
          className="view-more-button"
          variant="text"
          level={1}
          textVariant="light"
          onClick={() => handleViewMoreChannels()}
        >
          View more...
        </Button>
      ) : null}
    </div>
  );
}
