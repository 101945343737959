import { useState, useEffect } from 'react';
import { httpGet } from '../../helpers/http_helpers';
import { getStorageItem } from '../../helpers/environment_helpers';
import axios from 'axios';
import {
  ChannelRepository,
  ChannelType,
  ChannelFilter,
  ChannelSortingMethod
} from '@amityco/js-sdk';

export default function useAccountActiveChannels() {
  const [channelIds, setChannelIds] = useState([]);
  const [channels, setChannels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const thryvId = getStorageItem('Thryv_Id');

  const thryvIdRegex = /_.{16}/;
  const uuidRegex = /.{8}-.{4}-.{4}-.{4}-.{12}/;

  // Ensure all channels fetched from db have a thryv_staff_id and are in the correct format
  const channelFilter = (channel) => {
    return (
      channel.thryv_staff_id.length > 0 &&
      (thryvIdRegex.test(channel.channel_id) ||
        uuidRegex.test(channel.channel_id))
    );
  };

  useEffect(() => {
    httpGet(`getallchannels/${thryvId}`)
      .then((res) => {
        if (res.data.getChannels.length > 0) {
          setChannelIds(
            res.data.getChannels
              .filter(channelFilter)
              .map((channel) => channel.channel_id)
              .slice(0, 100)
          );
        }
      })
      .catch((err) => {
        console.log(
          'Something went wrong while fetching account channels.',
          err
        );
        setError(err);
      });
  }, []);

  useEffect(() => {
    if (channelIds?.length > 0) {
      const channelIdsAsQueryParams = channelIds
        .map((id) => `channelIds=${id}`)
        .join('&');

      const authHeader = {
        headers: {
          Authorization: 'Bearer ' + process.env.REACT_APP_AMITY_ACCESS_TOKEN,
          ContentType: 'application/x-www-form-urlencoded'
        }
      };

      axios
        .get(
          `https://api.us.amity.co/api/v3/channels/list?${channelIdsAsQueryParams}`,
          authHeader
        )
        .then((res) => {
          // Remove channels that are tagged 'deleted' or 'archived'
          const channels = res.data.channels.filter(
            (channel) =>
              !channel.tags.includes('deleted') &&
              !channel.tags.includes('archived')
          );
          setChannels(channels);
          setLoading(false);
        })
        .catch((err) => {
          setError(err);
          console.info(err);
          // This call fails often in testing, so clear console to avoid the unneccessary axios log
          if (err?.response?.data?.message === 'Some channel not found')
            console.clear();
        });
    }
  }, [channelIds]);

  // If error, fetch admin's channels and use those instead of all possible channels
  // If any of the channelIds from our DB do not exist in amity, the entire call fails, so we need a fallback
  useEffect(() => {
    if (error) {
      const liveCollection = ChannelRepository.queryChannels({
        types: [ChannelType.Standard, ChannelType.Community, ChannelType.Live],
        filter: ChannelFilter.Member,
        excludeTags: ['deleted'],
        sortBy: ChannelSortingMethod.FirstCreated
      });

      liveCollection.on('dataUpdated', (models) => {
        setChannels(models);
        setLoading(false);
      });

      liveCollection.on('loadingStatusChanged', (models) => {
        if (
          liveCollection &&
          liveCollection.loadingStatus === 'loaded' &&
          liveCollection.hasMore
        ) {
          liveCollection.nextPage();
        }
      });

      liveCollection.on('dataError', (err) => {
        setError({ code: err?.code, message: err?.message });
      });

      return () => liveCollection.dispose();
    }
  }, [error]);

  return { channels, loading, error };
}
