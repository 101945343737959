// OAuth
export const STARTFETCHING = 'FETCHING';
export const FETCHEDOAUTH = 'FETCHEDOAUTH';
export const FAILDFETCH = 'FAILDFETCH';

// Active User
export const SET_OAUTH_CREDENTIALS = 'SET_OAUTH_CREDENTIALS';
export const SET_V_USER = 'SET_V_USER';
export const SET_DESKTOP_NOTIFICATIONS = 'SET_DESKTOP_NOTIFICATIONS';
export const SET_RECURLY_PLAN = 'SET_RECURLY_PLAN';
export const SET_IN_COMMAND_CENTER = 'SET_IN_COMMAND_CENTER';
export const SET_CC_STAFF_SYNC_SUCCESSFUL = 'SET_CC_STAFF_SYNC_SUCCESSFUL';

// Message Input
export const SET_INPUT_MESSAGE_VALUE = 'SET_INPUT_MESSAGE_VALUE';
export const SET_PLAIN_INPUT_MESSAGE_VALUE = 'SET_PLAIN_INPUT_MESSAGE_VALUE';
export const SET_BOTH_INPUT_MESSAGE_VALUE = 'SET_BOTH_INPUT_MESSAGE_VALUE';

// Conversations
export const SET_USER_LIST = 'SET_USER_LIST';
export const SET_NEW_CONVERSATION_USERS = 'SET_NEW_CONVERSATION_USERS';

// Amity Session
export const SESSION_CREATED = 'SESSION_CREATED';

// Flyout / Modal / Sidesheet
export const SET_STATUS_FOR_SIDE_PANEL = 'SET_STATUS_FOR_SIDE_PANEL';
export const DISPLAY_CHANNEL_BROWSER = 'DISPLAY_CHANNEL_BROWSER';
export const TOGGLE_OPEN_FLYOUT = 'TOGGLE_OPEN_FLYOUT';
export const TOGGLE_FLYOUT_ACTIVE_TAB = 'TOGGLE_FLYOUT_ACTIVE_TAB';

// Unread Count
export const UPDATE_CHANNEL_UNREAD_MAP = 'UPDATE_CHANNEL_UNREAD_MAP';
export const UPDATE_CHAT_UNREAD_MAP = 'UPDATE_CHAT_UNREAD_MAP';

// References
export const SET_REFRESH_REFERENCES = 'SET_REFRESH_REFERENCES';

// Toasts
export const SHOW_TOAST = 'SHOW_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';

// Message List
export const SET_MESSAGE_LIST_ELEMENT_REF = 'SET_MESSAGE_LIST_ELEMENT_REF';
export const SET_PRIMARY_RXN_PICKER_STATUS = 'SET_PRIMARY_RXN_PICKER_STATUS';

// Search
export const SEARCH_SUBMITTED = 'SEARCH_SUBMITTED';

// Client List
export const SET_CLIENT_LIST = 'SET_CLIENT_LIST'

