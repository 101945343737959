import { Header, ParagraphText as Text } from '@thryvlabs/maverick';
import { useHistory } from 'react-router-dom';
import { getHighlightedText } from '../../helpers/typography_helpers';

export default function MessagesResults({ messages, searchText, loading, error }) {
  const history = useHistory();

  const handleClick = (message) => {
    if (!message) return;
    let newChannelId = message?.channel?.channelId;
    history.push({ pathname: '/home/main/messages', state: { newChannelId } });
  };

  if (error)
    return (
      <div className='mb-2'>
        <Header variant='h7' fontWeight='medium'>
          Messages
        </Header>
        <Text variant='sm'>Something went wrong.</Text>
      </div>
    );

  if (loading)
    return (
      <div className='mb-2'>
        <Header variant='h7' fontWeight='medium'>
          Messages
        </Header>
        <Text variant='sm'>Searching for messages...</Text>
      </div>
    );

  if (searchText.trim() === '') {
    return (
      <div className='mb-2'>
        <Header variant='h7' fontWeight='medium'>
          Messages
        </Header>
        <Text variant='sm'>No search provided.</Text>
      </div>
    );
  }

  return (
    <div className='mb-2'>
      <Header variant='h7' fontWeight='medium'>
        Messages
      </Header>
      {messages.length > 0 ? (
        <div className='mb-2 search-results-container'>
          {messages.slice(0, 10).map((message) => (
            <div className='cursor-pointer' key={message.messageId} onClick={() => handleClick(message)}>
              <div className='flex flex-row pr-1 align-items-center'>
                <Text className='text-wrap' variant='reg'>
                  {getHighlightedText(message.data.text, searchText)}
                </Text>
              </div>
              <Text variant='sm' className='pb-2'>
                <span className='font-bold'>{message.user.displayName} </span>
                {message?.channel && message?.channel?.type !== 'conversation' ? (
                  <span>
                    in <span className='font-bold '>#{message.channel.displayName} </span>
                  </span>
                ) : null}
                <span className='search-popover-item-date'>on {new Date(message.createdAt).toDateString()}</span>
              </Text>
            </div>
          ))}
        </div>
      ) : (
        <Text variant='sm'>No messages matched your search.</Text>
      )}
    </div>
  );
}
