import { useState, useEffect } from "react";
import { httpGet, httpPut } from "../../helpers/http_helpers";
import { useHistory } from "react-router-dom";

export default function useSubscriptionStatus(thryvId) {
  const [subscription, setSubscription] = useState(0);
  const [trialEndDate, setTrialEndDate] = useState(null);
  const [trialCallToAction, setTrialCallToAction] = useState(null);
  const [subscriptionError, setSubscriptionError] = useState("");
  const [loadingSubscriptionStatus, setLoadingSubscriptionStatus] = useState(true);
  const [status, setStatus] = useState("inactive");
  const history = useHistory();

  useEffect(() => {
    httpGet(`getaccount/${thryvId}`).then((res) => {
      setSubscription(res.data.getAccount?.status);
      setTrialEndDate(res.data.getAccount?.free_trial_end_date);
      setTrialCallToAction(res.data.getAccount?.cta);
      setLoadingSubscriptionStatus(false);
    });
  }, [thryvId]);

  useEffect(() => {
    let newStatus = getSubscriptionStatus(subscription, trialEndDate, subscriptionError);
    setStatus(newStatus);
  }, [subscription, trialEndDate, status, subscriptionError]);

  const trialActive = trialEndDate && new Date(trialEndDate).getTime() > Date.now();

  const changeSubscriptionStatus = async () => {
    // Invert subscription state unless trial is ongoing
    const newStatus = subscription === 1 ? 0 : subscription === 0 && trialActive ? 0 : 1;
    setLoadingSubscriptionStatus(true);
    try {
      await httpPut(`deactivatesub`, {
        thryv_id: thryvId,
      }).then((res) => {
        history.push("/uservalidation");
      });
      setSubscription(newStatus);
      // Trial date is no longer valid when subscription status changes
      setTrialEndDate(null);
      setSubscriptionError("");
      //   await httpPut(`updateaccount-subscription-status/${thryvId}`, {
      //     status: newStatus
      //   }).then((res) => {
      //     history.push("/activate-teamchat-home");
      //   });
      //   setSubscription(newStatus);
      //   // Trial date is no longer valid when subscription status changes
      //   setTrialEndDate(null);
      //   setSubscriptionError("");
    } catch (error) {
      setSubscriptionError(error.message);
    }
    setLoadingSubscriptionStatus(false);
  };

  const subscriptionStatus = {
    status,
    trialEndDate,
    trialCallToAction,
    subscriptionError,
    loadingSubscriptionStatus,
    changeSubscriptionStatus
  };

  return subscriptionStatus;
}

export const getSubscriptionStatus = (subscription, trialEndDate, subscriptionError) => {
  let subscriptionStatus = "inactive";
  if (subscription === 1) {
    subscriptionStatus = "subscribed";
  } else if (trialEndDate) {
    let trialEndDateInMs = new Date(trialEndDate).getTime();
    if (trialEndDateInMs > Date.now()) {
      subscriptionStatus = "trial_active";
    } else if (trialEndDateInMs < Date.now() || subscriptionError === "Subscription expired") {
      subscriptionStatus = "trial_expired";
    }
  }
  return subscriptionStatus;
};
