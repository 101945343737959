/* global pendo */
import React, { useState, useEffect } from 'react';
import './index.css';
import { Col, Container } from 'react-bootstrap';
import { setDesktopNotifications, setVUser, setRecurlyPlan } from '../../redux/actions/ActiveUser';
import { API_URL } from '../../app.config';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { createAccountAndAddStaffMembers, createUser, getStaffmember } from '../../helpers/activation_helpers';
import { useDispatch, useSelector } from 'react-redux';
import { getEnvironment } from '../../components/utils/utils';
import { httpPut } from '../../helpers/http_helpers';

const UserValidation = (props) => {
  const { identify } = useLDClient();
  const [accessDenied, setAccessDenied] = useState(undefined);
  const [vUserExists, setVUserExists] = useState(false);
  const [teamchatAccountExists, setTeamchatAccountExists] = useState(undefined);
  const [teamchatUserExists, setTeamchatUserExists] = useState(undefined);
  const { thryv_id, thryv_staff_id, access_token, directory_code, in_command_center } = useSelector((state) => state.ActiveUser);
  const vUser = useSelector((state) => state.ActiveUser.v_user);
  const history = useHistory();
  const dispatch = useDispatch();

  //Logrocket for recording the users session for activationFlow
  useEffect(() => {
    if (thryv_id && thryv_staff_id) {
      LogRocket.init('irjikg/teamchat-activationflow');
      setupLogRocketReact(
        LogRocket.identify('Thryv Id', {
          name: thryv_id,
          email: thryv_staff_id
        })
      );
    }
  }, [thryv_id, thryv_staff_id]);

  // RECURLY - if sync plan, deny access
  useEffect(() => {
    if (thryv_id && access_token) {
      if (in_command_center) {
        setAccessDenied(false);
        return;
      }
      const member_info = {
        access_token: access_token,
        thryv_id: thryv_id,
        dirCode: directory_code
      };
      axios
        .post(`${API_URL}api/teamchat/getrecurlyid`, member_info)
        .then((res) => {
          const planName = res?.data?.data?.business?.meta?.plan?.plan_name;
          dispatch(setRecurlyPlan(planName));
          if (planName && planName !== 'Sync') {
            setAccessDenied(false);
          } else {
            setAccessDenied(true);
          }
        })
        .catch((err) => {
          setAccessDenied(true);
          console.log(err);
        });
    }
  }, []);

  // STAFFMEMBER - check user exists in V
  useEffect(() => {
    const fetchStaffmember = async () => {
      const staffmember = await getStaffmember({access_token, thryv_id, thryv_staff_id, directory_code})
      if (staffmember) {
        dispatch(setVUser(staffmember));
        setVUserExists(true);
      } else {
        setAccessDenied(true);
      }
    };
    fetchStaffmember();
  }, []);

  // Identify user with LaunchDarkly
  useEffect(() => {
    console.log("LaunchDarkly initialization")
    if (thryv_id && thryv_staff_id && vUserExists) {
      console.log("Identifying user")
      identify({
        kind: "user",
        key: thryv_staff_id,
        name: vUser.display_name,
        environment: getEnvironment(),
        business_id: thryv_id,
        thryv_staff_id: thryv_staff_id,
        first_name: vUser.first_name,
        last_name: vUser.last_name,
        email: vUser.email,
        display_name: vUser.display_name,
        role: vUser.role,
        mobile_number: vUser.mobile_number,
        photo: vUser.photo,
      });
    }  
  }, [thryv_staff_id, thryv_id, vUserExists]);

  // TEAMCHAT ACCOUNT - if doesn't exist, need to create it and add staffmembers
  useEffect(() => {
    axios
      .get(`${API_URL}api/teamchat/getaccount/${thryv_id}`)
      .then((res) => {
        // Store account directory_code if not already set
        if (Object.keys(res.data).length > 0) {
          setTeamchatAccountExists(true);
          if (directory_code && directory_code !== "authorized" && res.data?.getAccount?.directory_code !== directory_code) {
            httpPut(`updateaccount`, {
              thryv_id: thryv_id,
              changes: {
                directory_code: directory_code
              }
            })
              .then((res) => {
                console.log('Directory code set', res.data);
              })
              .catch((err) => console.log(err));
          }
        } else {
          setTeamchatAccountExists(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setTeamchatAccountExists(false);
      });
  }, []);

  // TEAMCHAT USER - if doesn't exist, need to create user
  useEffect(() => {
    console.log("checking TC for user")
    axios
      .get(`${API_URL}api/teamchat/getuser/${thryv_staff_id}`)
      .then((res) => {
        if (res?.data?.getUser?.status === 1) {
          setTeamchatUserExists(true);
          dispatch(setDesktopNotifications(res.data.getUser.desktop_notifications || 'off'));
        } else {
          setTeamchatUserExists(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setTeamchatUserExists(false);
      });
  }, []);

  useEffect(() => {
    const navigateToHome = () => {
      console.log("validation complete, navigating to /home")
      history.push('/home/main/messages')
    };

    const createMissingUser = async () => {
      await createUser(vUser);
      setTeamchatUserExists(true);
      console.log("Missing user successfully created.")
    };

    const createMissingAccount = async () => {
      const accountData = {
        thryv_id,
        thryv_access_token: access_token,
        status: 1,
        free_trial_start_date: '0000-00-00',
        free_trial_end_date: '0000-00-00',
        company: ''
      };

      if (directory_code) accountData['dirCode'] = directory_code;

      await createAccountAndAddStaffMembers(accountData);
      setTeamchatAccountExists(true);
      console.log("Account successfully created.")
    };

    if (
      !accessDenied &&
      vUserExists &&
      teamchatUserExists !== undefined &&
      teamchatAccountExists !== undefined
    ) {
      if (teamchatAccountExists && teamchatUserExists) {
        navigateToHome();
      } else if (teamchatAccountExists && !teamchatUserExists) {
        createMissingUser();
      } else if (!teamchatAccountExists) {
        createMissingAccount();
      }
    }
  }, [accessDenied, teamchatAccountExists, teamchatUserExists, vUserExists]);

  return (
    <div>
      {accessDenied && (
        <Container
          fluid
          className='d-flex align-items-center justify-content-center'
          style={{
            height: '85vh',
            textAlign: 'center'
          }}
        >
          <Col xs={12}>
            <div className='message-wrapper-activationFlow'>
              <div className='message-container'>
                <h2 className='message-text'>
                  TeamChat is not available at the moment. Please contact your Thryv representative
                  to learn more.
                </h2>
              </div>
            </div>
          </Col>
        </Container>
      )}
    </div>
  );
};

export default UserValidation;