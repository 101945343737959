import { 
    SESSION_CREATED
  } from '../actions/types';
  
  const INITIAL_STATE = {
    amityAuthToken: ''
  }
  
  export const AmitySession = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
      case SESSION_CREATED:
        return {
          ...state,
          amityAuthToken: payload
        };
      default:
        return state
    }
  }