import {
  UPDATE_CHANNEL_UNREAD_MAP,
  UPDATE_CHAT_UNREAD_MAP
} from "../actions/types";

const INITIAL_STATE = {
  channelUnreadMap: {},
  chatUnreadMap: {}
};

export const unreadCount = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_CHANNEL_UNREAD_MAP:
      return {
        ...state,
        channelUnreadMap: { ...state.channelUnreadMap, [payload.channelId]: payload.unreadCount }
      };
    case UPDATE_CHAT_UNREAD_MAP:
      return {
        ...state,
        chatUnreadMap: { ...state.chatUnreadMap, [payload.chatId]: payload.unreadCount }
      };
    default:
      return state;
  }
};
